<template>
  <router-view></router-view>
</template>

<script>
import { loadCldr } from "@syncfusion/ej2-base";
import { pt } from "./pages/Dashboard/pt.json";
import { L10n,  setCulture } from "@syncfusion/ej2-base";

// loadCldr(
//   require("cldr-data/supplemental/numberingSystems.json"),
//   require("cldr-data/main/pt-CH/ca-gregorian.json"),
//   require("cldr-data/main/pt-CH/numbers.json"),
//   require("cldr-data/main/pt-CH/timeZoneNames.json")
// );

// L10n.load({ pt: pt })

// setCulture('pt'); 
// setCurrencyCode('BRL'); 

export default {};
</script>
