<template>
  <transition name="modal">
    <!-- <loading
      :active.sync="load"
      :can-cancel="true"
      :is-full-page="fullpage"
    ></loading> -->
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header class="md-tabs-navigation md-card-header-warning">
                  <h4 class="title" style="color:white">Cadastro de Cidades  <strong>{{ item.id_cidade > 0 ? ' - Código: ' + item.id_cidade : ''}}</strong></h4>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Nome da Cidade</label>
                        <md-input
                        v-model="item.cidade"
                        @input="item.cidade=item.cidade.toUpperCase()"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-25">
                      <md-field class="md-size-10">
                        <label>UF</label>
                        <md-input
                          maxlength="2"
                          v-model="item.uf"
                          @input="item.uf=item.uf.toUpperCase()"
                          md-counter="false"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-75">
                      <md-field class="md-size-10">
                        <label>Cód. IBGE</label>
                        <md-input maxlength="10" md-counter="false" v-model="item.cod_ibge"></md-input>
                      </md-field>
                    </div>
                  </div>
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button class="md-success md-sm md-round" @click="salvaRegistro">Salvar</md-button>&nbsp;
                  <md-button class="md-danger md-sm md-round" @click="closeModal(0, 0)">Cancelar</md-button>
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Swal from "sweetalert2";
import api from "@/routes/api"

export default {
  name: "modal",
  methods: {
    closeModal: function(pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    salvaRegistro() {
      if (this.item.cidade.length < 3) {
        this.$notify({
          timeout: 2500,
          message: 'Informe o nome da cidade!!!',
          icon: "add_alert",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: "danger"
        });
        return;
      }

      if (this.item.uf.length != 2) {
        this.$notify({
          timeout: 2500,
          message: 'UF inválida!!!',
          icon: "add_alert",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: "danger"
        });
        return;
      }

      let liberacors = "https://cors-anywhere.herokuapp.com/";

      //SE FOR INCLUSÃO
      if (this.item.id_cidade == 0) {
        this.$emit("load", true);
        let urlApi = "cidade_insert";
        api
          .post(urlApi, this.item, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.$emit("load", false);
            this.closeModal(1, 1);
          })
          .catch(error => {
            this.$emit("load", false);
            alert(error);
          });
      } else {
        this.$emit("load", true);
        let urlApi = "cidade_update";
        api
          .post(urlApi, this.item, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.$emit("load", false);
            this.closeModal(0);
          })
          .catch(error => {
            //alert(error);
            this.$emit("load", false);
          });
      }
    }
  },
  props: ["item", "pagina", "termo", "perPage", "load"]
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 10;
}

.modal-container {
  max-width: 800px;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
