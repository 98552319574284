<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          

              <md-card>
                <md-card-header
                  class="md-tabs-navigation md-card-header-warning"
                >
                  <h4 class="title" style="color:white">
                    Cadastro de Profissionais
                    <strong>{{
                      item.id_funcionario > 0
                        ? " - Código: " + item.id_funcionario
                        : ""
                    }}</strong>
                  </h4> 
                </md-card-header>

<br>

  <simple-wizard-cad>
    <wizard-tab-cad>
      <template slot="label">
        Dados do Profissional
      </template>
      
    <div class="md-layout">                                                                    
      <div class="md-layout-item md-size-70">
        <md-field>
          <label>Nome</label>
          <md-input
            v-model="item.nome"
            @input="item.nome = item.nome.toUpperCase()"
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-30">
        <md-field>
          <label>CPF</label>
          <md-input
            v-model="item.cpf"
            v-mask="['###.###.###-##']"
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-15">
        <md-field>
          <label for="tppessoa">Sexo</label>
          <md-select v-model="item.sexo" name="sexo" id="sexo">
            <md-option value="1">Masculino</md-option>
            <md-option value="0">Feminino</md-option>
          </md-select>
        </md-field>
      </div>
      <div class="md-layout-item md-size-55">
        <md-field>
          <label>E-Mail</label>
          <md-input
            v-model="item.email"
            @input="item.email = item.email.toLowerCase()"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-size-30">
        <md-field>
          <label>Senha de Acesso</label>
          <md-input
            v-model="item.senha"
            type="password"
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-25">
        <md-field>
          <label>Celular</label>
          <md-input
            v-model="item.celular"
            v-mask="['(##)####-####', '(##)#####-####']"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-size-25">
        <md-field>
          <label>Telefone</label>
          <md-input
            v-mask="['(##)####-####', '(##)#####-####']"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-size-25">
        
          <ejs-datepicker 
            id="dtIniServico"
            v-bind:value='item.nascimento != null && item.nascimento != "" ? moment(item.nascimento).format("DD/MM/YYYY") : null'
            :placeholder="'Data de Nascimento'"
            floatLabelType="Auto" 
            :format='maskData'   
            :enableMask='true'                                 
            :change='alteraDataNascimento'
          ></ejs-datepicker>
        
      </div>
      <div class="md-layout-item md-size-25">
        <md-field>
          <label for="estadocivil">Estado Civil</label>
          <md-select
            v-model="item.estado_civil"
            name="estadocivil"
            id="estadocivil"
          >
            <md-option value="0">Casado(a)</md-option>
            <md-option value="1">Solteiro(a)</md-option>
          </md-select>
        </md-field>
      </div>


      <div class="md-layout-item md-size-45">
        <md-field>
          <label>Cargo</label>
          <md-input
            v-model="item.cargo"
            @input="item.cargo = item.cargo.toUpperCase()"
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-30">
        <md-field>
          <label>Contrato</label>
          <md-input
            v-model="item.contrato"
            type="text"
            pattern="\d*"
            maxlength="20"
            md-counter="false"
          >
          </md-input>
        </md-field>
      </div>

      <div
        class="md-layout-item md-size-25 md-has-value md-focused"
      >
        <ejs-datepicker 
            id="dtIniServico"
            :placeholder="'Data da Contratação'"
            floatLabelType="Auto" 
            :format='maskData'   
            :enableMask='true'  
            v-bind:value='item.nascimento != null && item.data_contrato != "" ? moment(item.data_contrato).format("DD/MM/YYYY") : null' 
            :change='alteraDataContrato'                         
          ></ejs-datepicker>
      </div>

      <div class="md-layout-item md-size-50">
        <md-field class="md-size-10">
          <label>Logradouro</label>
          <md-input
            @input="
              item.logradouro = item.logradouro.toUpperCase()
            "
            maxlength="100"
            md-counter="false"
            v-model="item.logradouro"
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-10">
        <md-field class="md-size-15">
          <label>Número</label>
          <md-input
            type="text"
            pattern="\d*"
            maxlength="5"
            md-counter="false"
            v-model="item.numero"
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-40">
        <md-field class="md-size-10">
          <label>Complemento</label>
          <md-input
            @input="
              item.complemento = item.complemento.toUpperCase()
            "
            maxlength="100"
            md-counter="false"
            v-model="item.complemento"
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-40">
        <md-field class="md-size-10 my-input-upper">
          <label>Bairro</label>
          <md-input
            maxlength="100"
            md-counter="false"
            v-model="item.bairro"
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-10">
        <md-field class="md-size-10 my-input-upper">
          <label>UF</label>
          <md-input
            @input="item.uf = item.uf.toUpperCase()"
            maxlength="2"
            md-counter="false"
            v-model="item.uf"
            @change="
              item.id_cidade = 0;
              carregaComboMunicipios(item.uf);
            "
          ></md-input>
        </md-field>
      </div>

      <div class="md-layout-item md-size-50">
        <md-field class="md-size-10">
          <label>Cidade</label>
          <md-select
            v-model="item.id_cidade"
            name="idcidade"
            id="idcidade"
          >
            <md-option
              v-for="(itemMuni, indexmun) in lstMunicipios"
              v-bind:key="indexmun"
              :value="itemMuni.id_cidade"
              >{{ itemMuni.cidade }}</md-option
            >
          </md-select>
        </md-field>
      </div>
    </div>

    <div class="footer mx-auto text-center">
      <md-button
        class="md-success md-sm md-round"
        @click="salvaRegistro"
        >Salvar</md-button
      >&nbsp;
      <md-button
        class="md-danger md-sm md-round"
        @click="closeModal(1, 1)"
        >Fechar</md-button
      >
    </div>

    </wizard-tab-cad>

    <wizard-tab-cad>
      <template slot="label">
        Serviços
      </template>

      <div class="md-layout">  
        <div class="md-layout-item md-size-55">

          <div class="button-container">
            <ejs-autocomplete 
                v-model="servicoSelecionado"
                floatLabelType="Auto" 
                :select='recebeServicoAuto' 
                :autofill='true' 
                :dataSource='lstServicos' 
                :fields="{value: 'descricao', text: 'descricao'}" 
                placeholder='Selecione o Serviço'
                ></ejs-autocomplete>
                <md-button class="md-success md-sm" @click="incluiServicoFuncionario">Adiconar (+)</md-button>
          </div>                              
        </div>
        
        <div class="md-layout-item md-size-45">
          <div class="md-layout-item md-inline-checkboxes">
            <md-checkbox v-model="item.ativo">Cadastro Ativo</md-checkbox>
            <md-checkbox v-model="item.ativo_agenda">Ativo na Agenda</md-checkbox>
          </div>
        </div>

        <div class="md-layout-item md-size-100">
              <md-table v-model="servicosFunc" table-header-color="green">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Cód. Serviço">{{ item.id_servico_produto }}</md-table-cell>
                  <md-table-cell md-label="Serviço">{{ item.descricao }}</md-table-cell>                                        
                  <md-table-cell class="text-right"> 
                    <md-button class="md-just-icon md-round md-simple md-danger" @click="excluirServicoFuncionario(item.id_func_serv)"><md-icon>close</md-icon></md-button>
                  </md-table-cell>
                </md-table-row>
              </md-table>
          </div>

      </div>

    </wizard-tab-cad>

  </simple-wizard-cad>




              </md-card>
            </div>
          </div>
        </div>
    
  </transition>
</template>

<script>
import Vue from "vue";
import Swal from "sweetalert2";
import VueNumeric from "vue-numeric";
import { NavTabsCard } from "@/components";
import api from "@/routes/api"

import { SimpleWizardCad, WizardTabCad } from "@/components";

import { DatePickerPlugin  } from "@syncfusion/ej2-vue-calendars";
Vue.use(DatePickerPlugin);


export default {
  name: "modal",
  components: {
    
    SimpleWizardCad,
    WizardTabCad
  },
  data() {
    return {
       avatar: {
        type: String,
        default: "./img/default-avatar.png"
      },
      maskData: 'dd/MM/yyyy',
      image: "",
      valor: 0.0,
      dtNasc: "",
      dtContrato: "",
      funcionario: {
        id_funcionario: 0,
        nome: "",
        email: "",
        cpf: "",
        celular: "",        
        sexo: "",                
        nascimento: "",
        estado_civil: "",
        contrato: "",
        cep: "",
        logradouro: "",
        numero: 0,
        bairro: "",
        uf: "",
        obs: "",
        rg: "",
        id_cidade: 0,
        tipo: "PROFISSIONAL",
        complemento: "",
        telefone: "",
        data_contrato: "",
        senha: "",
        cargo: "" 
      },
      lstMunicipios: [],
      lstServicos: [],
      servicoSelecionado: null,
      idServicoSelecionado: 0,
      servicosFunc: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },
  methods: {
    excluirServicoFuncionario: function (idExcluir) {   
      
      this.carregando = true;

      api
        .delete(`funcionarios-servicos/excluir-servico-funcionario/${idExcluir}`)
        .then(resp => {
          
          this.$notify({
            timeout: 2500,
            message: "Serviço removido com sucesso!!!",
            icon: "add_success",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success"
          });
          this.carregaServicosFuncionario();
          this.carregando = false;
        }).catch(resp => {
          this.$notify({
            timeout: 2500,
            message: "Erro ao remover serviço",
            icon: "add_danger",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger"
          });
          this.carregando = false;
        })

    },
    incluiServicoFuncionario() {

      if(this.idServicoSelecionado == 0){
        this.$notify({
            timeout: 2500,
            message: "Selecione um serviço para adicionar!!!",
            icon: "add_danger",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger"
          });
          return;
      }


      this.carregando = true;

       api
        .post(`funcionarios-servicos/incluir-servico-funcionario`, {
          "id_func_serv": 0,
          "id_funcionario": this.item.id_funcionario,
          "id_servico_produto": this.idServicoSelecionado
        }, 
        {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(resp => {
          
          this.$notify({
            timeout: 2500,
            message: "Serviço adicionado com sucesso!!!",
            icon: "add_success",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success"
          });
          this.servicoSelecionado = null;
          this.idServicoSelecionado = 0;
          this.carregaServicosFuncionario();
          this.carregando = false;

        })
    },
    carregaServicosFuncionario() {
        this.carregando = true;
        api
        .get(`funcionarios-servicos/lista-servicos-funcionarios/${this.item.id_funcionario}`).then(resp => {
        this.servicosFunc = resp.data.Data;
        this.carregando = false;
      });
    },
    recebeServicoAuto(args) {
      this.idServicoSelecionado = args.itemData.id_servico_produto
    },
    closeModal: function(pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    ChangeDataNasc() {
      this.item.nascimento = this.dtNasc;
    },
    ChangeDataContrato() {
      this.item.data_contrato = this.dtContrato;
    },
    carregaComboMunicipios(vrUf) {
      let urlCbxMunicipios =
        "cidades/lista-cidades/1/9999?uf=" + vrUf;

      if (vrUf.length < 2) {
        return;
      }
      this.carregando = true;
      api.get(urlCbxMunicipios).then(resp => {
        this.lstMunicipios = resp.data.Data;
        this.carregando = false;
      });
      
    },
    carregaListaServicos() {     
      this.carregando = true;
      api.get("servicos-produtos/lista-servicos-produtos/S/1/9999").then(resp => {
        this.lstServicos = resp.data.Data;
        this.carregando = false;
      });

    },
    salvaRegistro() {
      if (this.item.nome.length <= 5) {
        this.$notify({
          timeout: 2500,
          message: "Nome do funcionário/profissional inválido!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        return;
      }

      var itemNew = JSON.parse(JSON.stringify(this.item));
      itemNew.cpf = this.$options.filters["UnMask"](itemNew.cpf);

      if(itemNew.nascimento == null) {
        itemNew.nascimento = ''
      }

      if(itemNew.data_contrato == null) {
        itemNew.data_contrato = ''
      }

      if(itemNew.ativo == null) {
        itemNew.ativo = 0
      }
      if(itemNew.ativo_agenda == null) {
        itemNew.ativo_agenda = 0
      }

      itemNew.tipo_pessoa = ""
      itemNew.banco = ""
      itemNew.agencia = ""
      itemNew.conta = ""
      itemNew.chave_pix = ""

      if (this.item.id_funcionario == 0) {
        this.$emit("load", true);
        let urlApi = "funcionarios/incluir-funcionario";
        api
          .post(urlApi, itemNew, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.$emit("load", false);
            this.$notify({
              timeout: 2500,
              message: "Funcionário cadastrado com sucesso!!!",
              icon: "add_success",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success"
            });
            // this.closeModal(1, 1);
          })
          .catch(error => {
            this.$emit("load", false);
            alert(error);
          });
      } else {
        this.$emit("load", true);
        let urlApi = "funcionarios/alterar-funcionario";
        api
          .put(urlApi, itemNew, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.$emit("load", false);
            this.$notify({
              timeout: 2500,
              message: "Funcionário alterado com sucesso!!!",
              icon: "add_success",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success"
            });
            // this.closeModal(0);
          })
          .catch(error => {
            //alert(error);
            this.$emit("load", false);
          });
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      var vm = this;

      reader.onload = e => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    handlePreview(file) {
      this.model.imageUrl = URL.createObjectURL(file.raw);
    },
    alteraDataContrato(args) {
      this.item.data_contrato = args.value
    },
    alteraDataNascimento(args) {
      this.item.nascimento = args.value
    },
  },
  mounted() {
    
    if(this.item.nascimento != null) {
      this.item.nascimento = moment(this.item.nascimento).format("DD/MM/YYYY")
    } 
    
    if(this.item.data_contrato != null) {
      this.dtContrato = this.item.data_contrato.substr(0, 10);
    }
    
    
    this.carregaComboMunicipios(this.item.uf);
    this.carregaListaServicos();
    this.carregaServicosFuncionario()
  },
  props: ["item", "pagina", "termo", "perPage", "load"]
};
</script>


