<template>
  <div class="md-layout-item">
    <loading :active.sync="load" :can-cancel="true"></loading>
    <div class="md-layout">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <md-card-header-text>
            <div class="md-title">Categorias Cliente</div>
            <div class="md-subhead">Cadastro de Categorias de Cliente</div>
          </md-card-header-text>

          <md-card-actions>
            <md-button class="md-warning" @click="abreModal"
              >Adicionar</md-button
            >
          </md-card-actions>
        </md-card-header>

        <md-card-content>
          <md-table
            :value="this.tableData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="table-striped"
          >
            <!-- class="paginated-table table-striped table-hover" -->
            <md-table-toolbar>
              <div style="height: 80px">
                <md-field>
                  <md-input
                    type="search"
                    class="mb-5"
                    clearable
                    md-clear="getDados(1)"
                    style="width: 500px"
                    placeholder="Pesquisar Parametros..."
                    v-model="searchQuery"
                    v-on:keyup.enter="getDados(1)"
                  ></md-input>
                </md-field>
              </div>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell style="width: 5%" md-label="Cód." md-sort-by="id">
                {{ item.id }}
              </md-table-cell>
              <md-table-cell md-label="Categorias Cliente">
                {{ item.nome_categoria }}
              </md-table-cell>

              <md-table-cell class="text-right" md-label="">
                <div class="md-table-cell-container">
                  <md-button
                    class="
                      md-button md-just-icon md-theme-default md-simple md-round
                    "
                  >
                    <md-icon></md-icon>
                  </md-button>

                  <md-button
                    class="
                      md-button md-just-icon md-theme-default md-info md-round
                    "
                    @click.native="handleLike(item)"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>

                  <md-button
                    class="
                      md-button md-just-icon md-theme-default md-danger md-round
                    "
                    @click.native="handleDelete(item)"
                  >
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.descricao"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <modalFormCategoriasCliente
      :item="itemSel"
      :termo="searchQuery"
      :load="load"
      @closeModal="closeModal"
      @load="vrload"
      v-if="ativaModal"
    ></modalFormCategoriasCliente>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import api from "@/routes/api";
import moment from "moment";
import modalFormCategoriasCliente from "./modalFormCategoriasCliente.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
    modalFormCategoriasCliente
  },
  data() {
    return {
      currentSort: "descricao",
      currentSortOrder: "asc",
      ativaModal: false,
      dataInicio: "",
      dataFim: "",
      idCaixa: "",
      load: false,
      fullPage: true,
      footerTable: [],
      searchQuery: "",
      propsToSearch: ["Descricao"],
      tableData: [],
      fuseSearch: null,
      categoriasCliente: {
        id: 0,
        nome_categoria: ""
      },
      itemSel: null,
    };
  },
  methods: {
    getDados(nro_pagina) {
      this.load = true;
      if(this.searchQuery == ""){
        api
        .get(`categorias-cliente/lista-categorias-cliente`)
        .then((resp) => {
          this.tableData = resp.data.Data;
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
      } else {
        api
        .get(`categorias-cliente/lista-categorias-cliente?descricao=${this.searchQuery}`)
        .then((resp) => {
          this.tableData = resp.data.Data;
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
      }
      
    },
    vrload(value) {
      this.load = value;
    },
    abreModal() {
      let itemNew = this.categoriasCliente;
      (itemNew.id = 0),
        (itemNew.nome_categoria = ""),
        (this.itemSel = itemNew);
      this.ativaModal = true;
    },
    handleLike(item) {
      this.itemSel = item;
      this.ativaModal = true;
    },
    handleEdit(item) {
      Swal.fire({
        title: `You want to edit ${item.name}`,
        buttonsStyling: false,
        confirmButtonClass: "md-button md-info",
      });
    },
    handleDelete(item) {
      Swal.fire({
        title: "Confirma a Exclusão do registro?",
        text: `Não é possível reverter esta operação!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success btn-fill",
        cancelButtonClass: "md-button md-danger btn-fill",
        confirmButtonText: "Sim!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.load = true;
          api
            .delete(`/categorias-cliente/exclui-categoria-cliente/${item.id}`)
            .then((resp) => {
              this.load = false;
              this.getDados(1);
            })
            .catch((error) => {
              this.load = false;
              Swal.fire({
                title: "Falha ao Excluir Registro!",
                text: `${error}`,
                type: "error",
                confirmButtonClass: "md-button md-success btn-fill",
                buttonsStyling: false,
              });
            });
        }
      });
    },
    deleteRow(item) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === item.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    closeModal(pagina = 0, atualiza = 0) {
      let itemNew = this.custo_fixo;
      this.itemSel = itemNew;
      this.ativaModal = false;

      if (atualiza == 1) {
        if (pagina > 0) {
          this.currentPage = pagina;
        }

        this.getDados();
      }
    },
  },
  mounted() {
    this.getDados(1);
  },
  watch: {
    currentPage() {
      this.getDados();
    },
    searchQuery() {
      if (this.searchQuery == "") {
        this.getDados(1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text-right .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
.md-table .md-table-head:last-child {
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total {
    display: inline-flex;
    font-weight: 500;
    font-size: 1.0625rem;
    margin-right: 50px;
  }

  &.table-striped .td-price {
    border-bottom: 0;
  }

  .td-price {
    font-size: 26px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div {
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.table-shopping .md-table-head:nth-child(5),
.table-shopping .md-table-head:nth-child(7),
.table-shopping .md-table-head:nth-child(6) {
  text-align: right;
}
</style>
