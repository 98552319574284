<template>
  <div class="md-layout-item">
    <loading :active.sync="load" :can-cancel="true"></loading>
    <div class="md-layout">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <md-card-header-text>
            <div class="md-title">Pacotes</div>
            <div class="md-subhead">Cadastro de Pacotes</div>
          </md-card-header-text>

          <md-card-actions>
            <md-button class="md-warning" @click="abreModal"
              >Adicionar</md-button
            >
          </md-card-actions>
        </md-card-header>

        <md-card-content>
          <md-table
            :value="this.tableData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="table-striped"
          >
            <!-- class="paginated-table table-striped table-hover" -->
            <md-table-toolbar>
              <div style="height: 80px">
                <md-field>
                  <md-input
                    type="search"
                    class="mb-5"
                    clearable
                    md-clear="getDados(1)"
                    style="width: 500px"
                    placeholder="Pesquisar Pacotes..."
                    v-model="searchQuery"
                    v-on:keyup.enter="getDados(1)"
                  ></md-input>
                </md-field>
              </div>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell style="width: 5%" md-label="Cód.">
                {{ item.id_pacote }}
              </md-table-cell>
              <md-table-cell md-label="Nome do pacote" md-sort-by="cidade">
                {{ item.nome_pacote }}
              </md-table-cell>
              <md-table-cell md-label="Validade" md-sort-by="cidade">
                {{ item.validade }} dias
              </md-table-cell>
              <md-table-cell md-label="Observação" md-sort-by="cidade">
                {{ item.observacao }}
              </md-table-cell>

              <md-table-cell class="text-right" md-label="">
                <div class="md-table-cell-container">
                  <md-button
                    class="
                      md-button md-just-icon md-theme-default md-simple md-round
                    "
                  >
                    <md-icon></md-icon>
                  </md-button>

                  <md-button
                    class="
                      md-button md-just-icon md-theme-default md-info md-round
                    "
                    @click.native="handleLike(item)"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>

                  <md-button
                    class="
                      md-button md-just-icon md-theme-default md-danger md-round
                    "
                    @click.native="handleDelete(item)"
                  >
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.descricao"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions item="itemSel" md-alignment="space-between">
          <div class>
            <p class="card-category">
              Mostrando {{ from + 1 }} a {{ to }} de {{ total }} entradas
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
    </div>
    <modalFormPacotes
      :item="itemSel"
      :pagina="pagination.currentPage"
      :perPage="pagination.perPage"
      :termo="searchQuery"
      :load="load"
      @closeModal="closeModal"
      @load="vrload"
      v-if="ativaModal"
    ></modalFormPacotes>
  </div>
</template>

<script>
import { Pagination } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import api from "@/routes/api";
import moment from "moment";
import modalFormPacotes from "./modalFormPacotes.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Pagination,
    Loading,
    modalFormPacotes,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    currentPage() {
      let result = this.pagination.currentPage;
      return result;
    },

    to() {
      let highBound = this.from + this.pagination.perPage;

      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  data() {
    return {
      currentSort: "descricao",
      currentSortOrder: "asc",
      ativaModal: false,
      dataInicio: "",
      dataFim: "",
      idCaixa: "",
      load: false,
      fullPage: true,
      pagination: {
        perPage: 7,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [],
      searchQuery: "",
      propsToSearch: ["Descricao"],
      tableData: [],
      fuseSearch: null,
      pacotes: {
        id_pacote: 0,
        data_cadastro: "",
        nome_pacote: "",
        validade: 0,
        ativo: true,
        observacao: "",
      },
      itemSel: null,
    };
  },
  methods: {
    getDados(nro_pagina) {
      this.load = true;
      if(this.searchQuery == ""){
        api
        .get(`pacotes/lista-pacotes/${nro_pagina}/${this.pagination.perPage}`)
        .then((resp) => {
          this.tableData = resp.data.Data;
          this.searchedData = resp.data.data;
          this.pagination.total = Number(resp.data.QtdeRegistros);
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
      } else {
        api
        .get(`pacotes/lista-pacotes/${nro_pagina}/${this.pagination.perPage}?nome=${this.searchQuery}`)
        .then((resp) => {
          this.tableData = resp.data.Data;
          this.searchedData = resp.data.data;
          this.pagination.total = Number(resp.data.QtdeRegistros);
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
      }
      
    },
    vrload(value) {
      this.load = value;
    },
    abreModal() {
      let itemNew = this.pacotes;
      (itemNew.id_pacote = 0),
        (itemNew.data_cadastro = ""),
        (itemNew.nome_pacote = ""),
        (itemNew.validade = 0),
        (itemNew.ativo = true),
        (itemNew.observacao = ""),
        (this.itemSel = itemNew);
      this.ativaModal = true;
    },
    customSort(value) {},
    handleLike(item) {
      this.itemSel = item;
      this.ativaModal = true;
    },
    handleEdit(item) {
      Swal.fire({
        title: `You want to edit ${item.name}`,
        buttonsStyling: false,
        confirmButtonClass: "md-button md-info",
      });
    },
    handleDelete(item) {
      Swal.fire({
        title: "Confirma a Exclusão do registro?",
        text: `Não é possível reverter esta operação!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success btn-fill",
        cancelButtonClass: "md-button md-danger btn-fill",
        confirmButtonText: "Sim!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.load = true;
          api
            .delete(`/pacotes/exclui-pacote/${item.id_pacote}`)
            .then((resp) => {
              this.load = false;
              this.getDados(1);
            })
            .catch((error) => {
              this.load = false;
              Swal.fire({
                title: "Falha ao Excluir Registro!",
                text: `${error}`,
                type: "error",
                confirmButtonClass: "md-button md-success btn-fill",
                buttonsStyling: false,
              });
            });
        }
      });
    },
    deleteRow(item) {
      let indexToDelete = this.tableData.findIndex(
        (tableRow) => tableRow.id === item.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    closeModal(pagina = 0, atualiza = 0) {
      let itemNew = this.custo_fixo;
      this.itemSel = itemNew;
      this.ativaModal = false;

      if (atualiza == 1) {
        if (pagina > 0) {
          this.currentPage = pagina;
        }

        this.getDados(this.pagination.currentPage);
      }
    },
  },
  mounted() {
    this.getDados(1);
  },
  watch: {
    currentPage() {
      this.getDados(this.pagination.currentPage);
    },
    searchQuery() {
      if (this.searchQuery == "") {
        this.getDados(1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text-right .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
.md-table .md-table-head:last-child {
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total {
    display: inline-flex;
    font-weight: 500;
    font-size: 1.0625rem;
    margin-right: 50px;
  }

  &.table-striped .td-price {
    border-bottom: 0;
  }

  .td-price {
    font-size: 26px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div {
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.table-shopping .md-table-head:nth-child(5),
.table-shopping .md-table-head:nth-child(7),
.table-shopping .md-table-head:nth-child(6) {
  text-align: right;
}
</style>
