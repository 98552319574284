<template>
  <div class="md-layout-item">
    <loading :active.sync="load" :can-cancel="true"></loading>
    <div class="md-layout">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <md-card-header-text>
            <div class="md-title">Contas a Receber/Recebidas</div>
            <div class="md-subhead">Lançamentos de Contas a Receber/Recebidas</div>
          </md-card-header-text>

          <md-card-actions>
            <md-button class="md-warning" @click="abreModal()"
              >Adicionar</md-button
            >
          </md-card-actions>
        </md-card-header>

        <md-card-content>
          <md-table
            :value="this.tableData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="table-striped"
          >
            <!-- class="paginated-table table-striped table-hover" -->
            <md-table-toolbar>
                <div class="md-layout">
                    <div class="md-layout-item md-size-20">
                        <div style="height: 80px">
                            <md-field>
                            <md-input
                                type="search"
                                class="mb-5"
                                clearable
                                md-clear="getDados(1)"
                                style="width: 500px"
                                placeholder="Pesquisar por Cliente..."
                                v-model="searchQuery"
                                v-on:keyup.enter="getDados(1)"
                            ></md-input>
                            </md-field>
                        </div>
                    </div>

                    <div class="md-layout-item md-size-10">
                        <div style="height: 80px">
                            <md-field>
                            <md-input
                                type="search"
                                class="mb-5"
                                clearable
                                md-clear="getDados(1)"
                                style="width: 500px"
                                placeholder="Cód. Receita"
                                v-model="codReceita"
                                v-on:keyup.enter="getDados(1)"
                            ></md-input>
                            </md-field>
                        </div>
                    </div>

                </div>
                    
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell style="width:  5%" md-label="Cód.Receita.">
                {{ item.ID_Conta_Receber }}
              </md-table-cell>
              <md-table-cell md-label="Nº Parcela" md-sort-by="nome">
                {{ item.Nro_Parcela }}
              </md-table-cell>
              <md-table-cell md-label="Cliente">
                {{  item.Cliente }}
              </md-table-cell>
              <md-table-cell md-label="Vencimento">
                {{  moment(item.Vencimento).format("DD/MM/YYYY") }}
              </md-table-cell>
              <md-table-cell md-label="Valor">
                {{ item.Valor | currency }}
              </md-table-cell>
              <md-table-cell md-label="Acrés.">
                {{ item.Acrescimo  | currency }}
              </md-table-cell>
              <md-table-cell md-label="Desc.">
                {{ item.Desconto  | currency }}
              </md-table-cell>
              <md-table-cell md-label="Sub-Total">
                {{ item.SubTotal  | currency }}
              </md-table-cell>
              <md-table-cell md-label="Valor Recebido">
                {{ item.Total_Recebido  | currency }}
              </md-table-cell>
              <md-table-cell md-label="A Receber">
                {{ item.SaldoDevedor  | currency }}
              </md-table-cell>

              <md-table-cell class="text-right" md-label="">
                <div class="md-table-cell-container">
                  <md-button
                    class="md-button md-just-icon md-theme-default md-simple md-round"
                  >
                    <md-icon></md-icon>
                  </md-button>

                  <md-button
                    class="md-button md-just-icon md-theme-default md-success md-round"
                    @click.native="abreModalRecebimentos(item)"
                  >
                    <md-icon>price_check</md-icon>
                  </md-button>

                  <md-button
                    class="md-button md-just-icon md-theme-default md-warning md-round"
                    @click.native="handleLike(item)"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>

                  <md-button
                    class="md-button md-just-icon md-theme-default md-danger md-round"
                    @click.native="handleDelete(item)"
                  >
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.id_funcionario"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions item="itemSel" md-alignment="space-between">
          <div class>
            <p class="card-category">
              Mostrando {{ from + 1 }} a {{ to }} de {{ total }} entradas
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
    </div>
    
    <modalFormRecebimentos 
        @load="vrload"
        v-if="ativaModal"
        @closeModal="closeModal"
        :item="itemSel"
        >
    </modalFormRecebimentos>

  </div>
</template>

<script>
import { Pagination } from "@/components";
import api from "@/routes/api";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import modalFormRecebimentos from "./modalRecebimentos.vue";

export default {
  components: {
    Pagination,
    modalFormRecebimentos,
    Loading
  },
  
  data() {
    return {
      itemSelecionado: null,
      currentSort: "",
      currentSortOrder: "asc",
      ativaModal: false,
      load: false,
      fullPage: true,
      pagination: {
        perPage: 7,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      footerTable: [],
      searchQuery: "",
      codReceita: "",
      propsToSearch: [],
      tableData: [],

      fuseSearch: null,
      funcionario: {
        id_funcionario: 0,
        nome: "",
        email: "",
        cpf: "",
        celular: "",        
        sexo: "",                
        nascimento: "",
        estado_civil: "",
        contrato: "",
        cep: "",
        logradouro: "",
        numero: 0,
        bairro: "",
        uf: "",
        obs: "",
        rg: "",
        id_cidade: 0,
        tipo: "FUNCIONARIO",
        complemento: "",
        telefone: "",
        data_contrato: "",
        senha: "",
        cargo: ""                
      },
      itemSel: null
    };
  },
  methods: {
    getDados(nro_pagina) {
      this.load = true;
      api
        .get(
          `contas-a-receber/contas-a-receber-periodo/${nro_pagina}/${this.pagination.perPage}/2021-01-01/2024-12-31?nomeCliente=${this.searchQuery}`
        )
        .then(resp => {
          this.tableData = resp.data.Data;
          this.searchedData = resp.data.Data;
          this.pagination.total = Number(resp.data.QtdeRegistros);
          this.load = false;
        })
        .catch(error => {
          this.load = false;
        });
    },
    vrload(value) {
      this.load = value;
    },
    abreModal() {           
      this.ativaModal = true;

      // console.log(this.ativaModal)
    },
    abreModalRecebimentos(item) {
        this.itemSel = item
        this.ativaModal = true;
    },
    customSort(value) {},
    handleLike(item) {
      this.itemSel = item;
      this.ativaModal = true;
    },
    handleEdit(item) {
      Swal.fire({
        title: `You want to edit ${item.nome}`,
        buttonsStyling: false,
        confirmButtonClass: "md-button md-info"
      });
    },
    handleDelete(item) {
      Swal.fire({
        title: "Confirma a Exclusão do registro?",
        text: `Não é possível reverter esta operação!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success btn-fill",
        cancelButtonClass: "md-button md-danger btn-fill",
        confirmButtonText: "Sim!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false
      }).then(result => {
        
        
        
      });
    },
    deleteRow(item) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === item.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    closeModal(pagina = 0, atualiza = 0) {
      
      this.ativaModal = false;

      if (atualiza == 1) {
        if (pagina > 0) {
          this.currentPage = pagina;
        }

        this.getDados(this.currentPage);
      }
    },
    
  },
  mounted() {    
    this.getDados(1);
  },
  computed: {
   
    currentPage() {
      let result = this.pagination.currentPage;
      return result;
    },

    to() {
      let highBound = this.from + this.pagination.perPage;

      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    }
  },
  watch: {
    currentPage() {
      this.getDados(this.pagination.currentPage);
    },
    searchQuery() {
      if (this.searchQuery == "") {
        this.getDados(1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.text-right .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
.md-table .md-table-head:last-child {
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total {
    display: inline-flex;
    font-weight: 500;
    font-size: 1.0625rem;
    margin-right: 50px;
  }

  &.table-striped .td-price {
    border-bottom: 0;
  }

  .td-price {
    font-size: 26px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div {
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.table-shopping .md-table-head:nth-child(5),
.table-shopping .md-table-head:nth-child(7),
.table-shopping .md-table-head:nth-child(6) {
  text-align: right;
}
</style>
