<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header class="md-tabs-navigation md-card-header-warning">
                  <h4 class="title" style="color: white">
                    Cadastro de Fornecedores
                    <strong>{{
                        item.id_fornecedor > 0
                          ? " - Código: " + item.id_fornecedor
                          : ""
                    }}</strong>
                  </h4>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Razão Social</label>
                        <md-input v-model="item.razao_social" @input="
                          item.razao_social = item.razao_social.toUpperCase()
                        "></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Nome Fantasia</label>
                        <md-input v-model="item.nome_fantasia" @input="
                          item.nome_fantasia =
                          item.nome_fantasia.toUpperCase()
                        "></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-15">
                      <md-field>
                        <label for="tppessoa">Tipo Pessoa</label>
                        <md-select v-model="item.tipo_pessoa" name="tppessoa" id="tppessoa">
                          <md-option value="F">Física</md-option>
                          <md-option value="J">Jurídica</md-option>
                        </md-select>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-55">
                      <md-field>
                        <label>E-Mail</label>
                        <md-input v-model="item.email" @input="item.email = item.email.toLowerCase()"></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-30">
                      <md-field>
                        <label>CPF/CNPJ</label>
                        <md-input v-model="item.cnpj_cpf" v-mask="['###.###.###-##', '##.###.###/####-##']"></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-25">
                      <md-field>
                        <label>Celular</label>
                        <md-input v-model="item.celular" v-mask="['(##) ####-####', '(##) #####-####']"></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25">
                      <md-field>
                        <label>Telefone</label>
                        <md-input v-model="item.telefone" v-mask="['(##) ####-####', '(##) #####-####']"></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25">
                      <md-field>
                        <label>Insc. Estadual</label>
                        <md-input v-model="item.inscricao_estadual"></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25">
                      <md-field>
                        <label>Insc. Municipal</label>
                        <md-input v-model="item.inscricao_municipal"></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <br />
                      <span slot="header" class="badge badge-warning">Endereço do Fornecedor</span>
                      <md-field class="md-size-10"></md-field>
                    </div>

                    <div class="md-layout-item md-size-50">
                      <md-field class="md-size-10">
                        <label>Logradouro</label>
                        <md-input @input="item.endereco = item.endereco.toUpperCase()" maxlength="100"
                          md-counter="false" v-model="item.endereco"></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-10">
                      <md-field class="md-size-15">
                        <label>Número</label>
                        <md-input type="text" pattern="\d*" maxlength="5" md-counter="false" v-model="item.numero">
                        </md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-40">
                      <md-field class="md-size-10">
                        <label>Complemento</label>
                        <md-input @input="
                          item.complemento = item.complemento.toUpperCase()
                        " maxlength="100" md-counter="false" v-model="item.complemento"></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-40">
                      <md-field class="md-size-10 my-input-upper">
                        <label>Bairro</label>
                        <md-input maxlength="100" md-counter="false" v-model="item.bairro"></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-10">
                      <md-field class="md-size-10 my-input-upper">
                        <label>UF</label>
                        <md-input @input="item.uf = item.uf.toUpperCase()" maxlength="2" md-counter="false"
                          v-model="item.uf" @change="item.id_cidade = 0
                          carregaComboMunicipios(item.uf)
                          "></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-50">
                      <md-field class="md-size-10">
                        <label>Cidade</label>
                        <md-select v-model="item.id_cidade" name="idcidade" id="idcidade">
                          <md-option v-for="(itemMuni, indexmun) in lstMunicipios" v-bind:key="indexmun"
                            :value="itemMuni.id_cidade">{{ itemMuni.cidade }}</md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button class="md-success md-sm md-round" @click="salvaRegistro">Salvar</md-button>&nbsp;
                  <md-button class="md-danger md-sm md-round" @click="closeModal(0, 0)">Cancelar</md-button>
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from "@/routes/api";
export default {
  components: {},
  name: "modal",
  data() {
    return {
      lstMunicipios: [],
    };
  },
  mounted() {
    this.carregaComboMunicipios(this.item.uf);
  },
  methods: {
    closeModal: function (pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    carregaComboMunicipios(vrUf) {
      let urlCbxMunicipios =
        `cidades/lista-cidades/1/9999?uf=${vrUf}`;
      if (vrUf.length < 2) {
        return;
      }
      this.carregando = true;
      api.get(urlCbxMunicipios).then((resp) => {
        
        this.lstMunicipios = resp.data.Data;
        this.carregando = false;
      });

      //this.load = false;
    },
    salvaRegistro() {
      if (this.item.razao_social.length <= 5) {
        this.$notify({
          timeout: 2500,
          message: "Razão social inválida!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }

      if (this.item.telefone.length != 14 && this.item.telefone !== "") {
        this.$notify({
          timeout: 2500,
          message: "Telefone inválido!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }

      //SE FOR INCLUSÃO
      if (this.item.id_fornecedor == 0) {
        api
          .post(`fornecedores/incluir-fornecedor`, this.item)
          .then((resp) => {
            this.$emit("load", false);
            this.item.razao_social = ""
            this.closeModal(1, 1);

            this.$notify({
              timeout: 2500,
              message: "Fornecedor incluído com sucesso!!!",
              icon: "add_success",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });
          })
          .catch((error) => {
            this.$emit("load", false);

            this.$notify({
              timeout: 2500,
              message: "Não foi possível incluir o fornecedor!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            this.$emit("load", false);
            return;
          });
      } else {
        this.$emit("load", true);
        api
          .put("fornecedores/alterar-fornecedor", this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);

            this.$notify({
              timeout: 2500,
              message: "Fornecedor alterado com sucesso!!!",
              icon: "add_success",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Erro ao alterar fornecedor!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            this.$emit("load", false);
            return;
          });
      }
    },
  },
  props: ["item", "pagina", "termo", "perPage", "load"],
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 10;
}

.modal-container {
  max-width: 900px;
}

.md-menu-content {
  z-index: 99999 !important;
}

.my-input-upper input {
  text-transform: uppercase;
}

.my-input-lower input {
  text-transform: lowercase;
}
</style>
