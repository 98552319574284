<template>
  <div id="schedule-vue-sample" class="md-layout-item">

    
    <div id="container">

      <FormAgenda v-if="mostraForm" :dataEditor="dataEditor" :compromissos="compromissos" @AbreFechaForm="AbreFechaForm" @carregaCompromissos="carregaCompromissos"/>

      <vue-element-loading :active="carregandoCompromisso">
        <img src="https://www.animatedimages.org/data/media/335/animated-hairdresser-image-0074.gif" width="128px" height="128px">
      </vue-element-loading>


      <ejs-schedule
        id="Schedule"
        width="100%"
        height="700px"
        :dataBound="onDataBound"
        :eventSettings="eventSettings"
        :selectedDate="selectedDate"
        :group="group"
        :currentView="currentView"
        :eventRendered="oneventRendered" 
        :created="onCreate" 
        :actionBegin="onActionBegin" 
        :actionComplete="onActionComplete"
        :actionFailure="onActionFailure" 
        :cellClick="onCellClick" 
        :cellDoubleClick="onCellDoubleClick" 
        :navigating="onNavigating"
        :eventClick="onEventClick" 
        :popupOpen="onPopupOpen"        
        :showQuickInfo="showQuickInfo" 
        :showHeaderBar=true
        :timezone='timezone'
        :timeFormat='timeFormat'
        
      >
        <e-views>
          <e-view
            option="Day"
            dateFormat="dd-MMM-yyyy"
            startHour="07:00"
            endHour="22:00"
            :allowVirtualScrolling='true'
          ></e-view>
          <e-view option="Week" 
            dateFormat="dd-MMM-yyyy"
            startHour="07:00"
            endHour="22:00"></e-view>
          <e-view option="Month" 
            dateFormat="dd-MMM-yyyy"
            startHour="07:00"
            endHour="22:00"></e-view>
          <!-- <e-view option="TimelineWeek"></e-view> -->
          <!-- <e-view option="TimelineMonth"></e-view> -->
          <e-view option="Agenda"></e-view>
           <!-- <e-view option="WorkWeek"></e-view> -->
        </e-views>
        <e-resources>
          <e-resource
            field="OwnerId"
            title="Profissionais"
            name="Profissionais"
            :allowMultiple="allowMultiple"
            :dataSource="dsProfissionais"
            textField="nome"
            idField="id_funcionario"
            colorField="cor"
          >
          </e-resource>
        </e-resources>
      </ejs-schedule>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from 'moment';
import editortemplate from "../Dashboard/custom-editor.vue";
import api from "@/routes/api";
import FormAgenda from "./FormAgenda.vue"
import VueElementLoading from 'vue-element-loading'


 
import {
  SchedulePlugin,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  TimelineViews,
  TimelineMonth,
  View 
} from "@syncfusion/ej2-vue-schedule";

Vue.use(SchedulePlugin);

let resourceData2 = []


export default {
  components: {
  FormAgenda,
  VueElementLoading
},
  data() {
    return {
      carregandoCompromisso: false,
      mostraForm: false,
      width: "100%",
      height: "1000px",
      currentView: "Day",
      //timezone: 'UTC',
      timeFormat: 'HH:mm',
      timezone: 'America/Campo_Grande',
      selectedDate: new Date(),
      selectedEndDate: new Date(),
      //cssClass: "virtual-scroll",
      cssClass: 'schedule-group',
      virtualScroll: true,
      editorTemplate: function () {
          return { template: editortemplate }
      },
      showQuickInfo: false,
      group: {
        resources: ["Profissionais"]
      },
      allowMultiple: true,
      dsProfissionais: [],
      eventSettings: { dataSource: this.compromissos },
      dataEditor: null,
      compromissos: []
    };
  },
  methods: {
    alerta() {
      Vue.toast("Agendamentos Cliente <strong>Vilani</strong>", {
        duration: 43000,
        mode: "override",
        closeable: true,
        horizontalPosition: "left",
        verticalPosition: "top",
        defaultTransition: "fade",
        className: "md-card md-card-login md-theme-default",
        customClass:
          ".et-wrapper.my-class { \n\
        background-color: red;\n}",
        html: "How are <strong>you</strong> <i>doing</i>?"
      });
    },
    AbreFechaForm: function (visible) {
      this.mostraForm = visible
    },
    Carregaprofissionais: function (prServico = 0) {
      this.load = true;

                if(prServico == null) {
                    prServico = 0
                }

                api
                    .get(
                    `profissionais/lista-profissionais-combo/${prServico}`
                    )
                    .then(resp => {
                        this.dsProfissionais = resp.data;
                        this.load = false;
                    })
                    .catch(error => {
                        this.load = false;
                    });
    },
    onPopupOpen: function (args) {
      
        let scheduleObj = document.getElementById('Schedule').ej2_instances[0]; 

        if (args.type === 'Editor') {
              
              let statusElement = args.element.querySelector('#EventType');
              
              document.getElementsByClassName("e-footer-content")[1].style.display="none";
              document.getElementsByClassName("e-footer-content")[0].style.display="none";
              document.getElementById("Schedule_dialog_wrapper_dialog-header").style.display="none";              
              document.getElementsByClassName("md-toolbar md-transparent md-theme-default md-elevation-0")[0].style.display="none"

              this.dataEditor = args.data
              this.mostraForm = true

              //this.$refs.dropdownCli.value = "3" 

              // scheduleObj.popupClose();
              args.cancel = true;
        }

        // if (args.type === 'Editor') {
        //       let statusElement = args.element.querySelector('#EventType');
        //       statusElement.setAttribute('name', 'EventType');
        // }

    },
    appendElement: function (html) {
        // let span = document.createElement('span');
        // span.innerHTML = html;
        // let log = document.getElementById('EventLog');
        // log.insertBefore(span, log.firstChild);
    },
    onClear: function () {
        // document.getElementById('EventLog').innerHTML = '';
    },
    onCreate: function () {
        // this.appendElement('Schedule <b>Load</b> event called<hr>');
    },
    onActionBegin: function () {
        // this.appendElement('Schedule <b>Action Begin</b> event called<hr>');
    },
    onActionComplete: function () {
        // this.appendElement('Schedule <b>Action Complete</b> event called<hr>');
        
        // let span = document.createElement('span');
        // span.innerHTML = html;
        // let log = document.getElementById('EventLog');
        // log.insertBefore(span, this.editorTemplate);

        
    },
    onActionFailure: function (args) {
        // this.appendElement('Schedule <b>Action Failure</b> event called<hr>');
    },
    onCellDoubleClick: function () {
        // this.appendElement('SChedule <b>Cell Double Click</b> event called<hr>');
    },
    onCellClick: function () {
        // this.appendElement('Schedule <b>Cell Click</b> event called<hr>');
    },
    onNavigating: function (args) {
      if(args.action == 'date') {
        this.selectedDate = args.currentDate
        this.selectedEndDate = args.currentDate
      }

      switch (args.currentView) {
       
        case "Week":
          this.selectedEndDate.setDate(this.selectedDate.getDate()  + 7); 
          
          break;
        case "Month":
          this.selectedEndDate.setDate(this.selectedDate.getDate()  + 30); 
          
          break;
        default:
          
          break;    
      }

       this.carregaCompromissos(moment(this.selectedDate).format('YYYY-MM-DD'), moment(this.selectedEndDate).format('YYYY-MM-DD'))

    },
    onEventClick: function () {
        // this.appendElement('Schedule <b>Event Click</b> event called<hr>');
    },
    oneventRendered: function (args) {
        // let scheduleObj = this.$refs.ScheduleObj;
        // let categoryColor = args.data.CategoryColor;
        // if (!args.element || !categoryColor) {
        //     return;
        // }
        // if (scheduleObj.ej2Instances.currentView === 'Agenda') {
        //     (args.element.firstChild).style.borderLeftColor = categoryColor;
        // } else {
        //     args.element.style.backgroundColor = categoryColor;
        // }

    },
    carregaCompromissos: function(diaIni, diaFim) {
      this.carregandoCompromisso = true;

      api
        .get(`agenda/lista-compromissos/${diaIni}/${diaFim}`)
        .then((resp) => {
          
          this.compromissos = resp.data

          let scheduleObj = document.getElementById("Schedule").ej2_instances[0]; 
          
          scheduleObj.eventSettings.dataSource = this.compromissos 

          this.carregandoCompromisso = false;
        })
        .catch((error) => {
          this.carregandoCompromisso = false;
        });
    },
    onDataBound: function(args) { 
      let scheduleObj = document.getElementById('Schedule').ej2_instances[0];
      scheduleObj.scrollTo(this.selectedDate.getTime()); 
      

    }, 
  },
  provide: {
    schedule: [Day, Week, WorkWeek, Month, Agenda, TimelineViews, TimelineMonth]
  },
  mounted() {

    this.Carregaprofissionais(0)
    this.carregaCompromissos(moment(String(this.selectedDate)).format('YYYY-MM-DD'), moment(String(this.selectedDate)).format('YYYY-MM-DD'))
    
  },
  watch: {
    carregaCompromissos(){
      this.carregaCompromissos(moment(String(this.selectedDate)).format('YYYY-MM-DD'), moment(String(this.selectedDate)).format('YYYY-MM-DD'))
    }
  }
};
</script>

<style lang="scss">
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css";

.content {
   padding:0;
  }

  .main-panel > .content {
    padding: 0px 0px;
}

</style>

