// =========================================================
// * Vue Material Dashboard PRO - v1.4.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
import VueTheMask from "vue-the-mask";
import moment from "moment";
import VueCurrencyFilter from "vue-currency-filter";
import { registerLicense, L10n, loadCldr, setCulture, setCurrencyCode } from "@syncfusion/ej2-base";

import { pt } from "./pages/Dashboard/pt.json";
import * as numberingSystems from "./pages/Dashboard/numberingSystems.json";
import * as cagregorian from "./pages/Dashboard/ca-gregorian.json";
import * as currencies from "./pages/Dashboard/currencies.json";
import * as numbers from "./pages/Dashboard/numbers.json";
import * as timeZoneNames from "./pages/Dashboard/timeZoneNames.json";

import App from "./App.vue";
import Chartist from "chartist";

import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import CxltToastr from "cxlt-vue2-toastr";
Vue.use(CxltToastr);

import VueNotification from "@kugatsu/vuenotification";

import routes from "./routes/routes";

registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1hXn5fc31WTmBaUkc=');

Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(VueTheMask);

Vue.use(VueNotification, {
  error: {
    background: "green",
    color: "red"
  }
});

Vue.use(VueCurrencyFilter, {
  symbol: "R$",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true
});

Vue.prototype.moment = moment;

loadCldr(
  require("./pages/Dashboard/currencies.json"),
  require("./pages/Dashboard/numberingSystems.json"),
  require("./pages/Dashboard/ca-gregorian.json"),
  require("./pages/Dashboard/numbers.json"),
  require("./pages/Dashboard/timeZoneNames.json")
);

setCulture('pt'); 
setCurrencyCode("BRL");

L10n.load({ 'pt': pt })


const router = new VueRouter({
  routes, // short for routes: routes
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active"
});

const MaskCpfCnpj = {
  install(Vue, options) {
    Vue.filter("MaskCpfCnpj", (documentId, documentType) => {
      documentId =
        typeof documentId != "string" ? documentId.toString() : documentId;
      
        if (documentId == null || documentId == "") return "";

      if (documentId.length > 11) {
        documentId = documentId.padStart(14, "0");
        documentId = documentId.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      } else {
        documentId = documentId.padStart(11, "0");
        documentId = documentId.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4"
        );
      }
      return documentId;
    });
  }
};
Vue.use(MaskCpfCnpj);

const MaskTelefone = {
  install(Vue, options) {
    Vue.filter("MaskTelefone", documentId => {
      documentId =
        typeof documentId != "string" ? documentId.toString() : documentId;
      if (documentId.length == 11) {
        documentId =
          "(" +
          documentId.substr(0, 2) +
          ")" +
          " " +
          documentId.substr(2, 5) +
          "-" +
          documentId.substr(7, 4);
      }
      if (documentId.length == 10) {
        documentId =
          "(" +
          documentId.substr(0, 2) +
          ")" +
          " " +
          documentId.substr(2, 4) +
          "-" +
          documentId.substr(6, 4);
      }
      return documentId;
    });
  }
};
Vue.use(MaskTelefone);

const UnMask = {
  install(Vue, options) {
    Vue.filter("UnMask", documentId => {
      documentId =
        typeof documentId != "string" ? documentId.toString() : documentId;
      documentId = documentId.replace(/[.|/|-]/g, "");
      documentId = documentId.replace(/[)|(| ]/g, "");
      return documentId;
    });
  }
};
Vue.use(UnMask);

// global library setup
Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  router
});
