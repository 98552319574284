<template>
  <div id="formAgendamento">
    <div class="md-layout">
      <vue-element-loading :active="load">
        <img
          src="https://www.animatedimages.org/data/media/335/animated-hairdresser-image-0074.gif"
          width="128px"
          height="128px"
        />
      </vue-element-loading>
      <div class="md-layout-item md-size-55">
        <!-- <vue-element-loading :active="load" spinner="bar-fade-scale" color="#FF6700"/> -->

        <md-card full>
          <div class="md-layout-item md-size-100">
            <md-card-header class="md-card-header-warning">
              <h4 class="title" style="color: white">
                Agendando Cliente
                <span style="opacity: 0; color: white">----------</span
                ><md-button class="md-success md-sm md-round" @click="Salvar"
                  >Salvar</md-button
                >
                <md-button
                  class="md-danger md-sm md-round"
                  @click="AbreFechaForm"
                  >Fechar</md-button
                >
              </h4>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <div class="button-container">
                    <ejs-autocomplete 
                      v-model="itemVmodelCiente"
                      floatLabelType="Auto" 
                      :select='recebeValorAutoCliente' 
                      :autofill='autofill' 
                      :dataSource='clientesCombo' 
                      :fields="{text: 'AutoComplete', value: 'AutoComplete'}" 
                      placeholder='Selecione o Cliente'>
                    </ejs-autocomplete>
                    <button type="button" class="md-button md-success md-simple md-just-icon md-theme-default" @click="abreModalCliente = true"><div class="md-ripple"><div class="md-button-content mt-4">
                      <i class="md-icon md-icon-font md-theme-default">group_add</i></div> </div>
                      <md-tooltip md-direction="top">Cadastrar Novo Cliente</md-tooltip>
                    </button>
                  </div>
                </div>

                <div class="md-layout-item md-size-50">
                  <ejs-autocomplete
                    v-model="itemVmodelFuncionario"
                    floatLabelType="Auto"
                    :select="recebeValorAutoFuncionario"
                    :autofill="autofill"
                    :dataSource="profissionaisCombo"
                    :fields="{ text: 'nome', value: 'nome' }"
                    placeholder="Selecione o Profissional"
                  ></ejs-autocomplete>
                </div>

                <div class="md-layout-item md-size-50">
                  <div class="e-float-input">
                    <ejs-autocomplete
                      v-model="itemVmodelServico"
                      floatLabelType="Auto"
                      :select="recebeValorAutoServico"
                      :autofill="autofill"
                      :dataSource="servicosCombo"
                      :fields="{ text: 'descricao', value: 'descricao' }"
                      placeholder="Selecione o Serviço"
                    ></ejs-autocomplete>
                    <span class="e-float-line"></span>
                  </div>
                </div>

                <!-- :change='CarregaProfissionaisCombo' -->

                <div class="md-layout-item md-size-25">
                  <div class="e-float-input">
                    <ejs-datetimepicker
                      id="dtIniServico"
                      v-model="dataInicio"
                      :format="format"
                      :placeholder="'Início do Serviço'"
                      floatLabelType="Auto"
                    ></ejs-datetimepicker>
                  </div>
                </div>

                <div class="md-layout-item md-size-25">
                  <div class="e-float-input">
                    <ejs-datetimepicker
                      id="dtFimServico"
                      v-model="dataFim"
                      :format="format"
                      :placeholder="'Término do Serviço'"
                      floatLabelType="Auto"
                    ></ejs-datetimepicker>
                  </div>
                </div>

                <div class="md-layout-item md-size-100">
                  <md-field class="md-size-100">
                    <label>Observações</label>
                    <md-input
                      v-model="agendamento.Obs"
                      maxlength="255"
                      md-counter="false"
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </md-card-content>

            <!-- <div class="footer mx-auto text-center">
              <md-button
                class="md-success md-sm md-round"
                @click="Salvar"
              >Salvar</md-button>&nbsp;
              <md-button
                class="md-danger md-sm md-round"
                @click="AbreFechaForm"
              >Fechar</md-button>
            </div> -->
            <br />
          </div>
        </md-card>
      </div>

      <div class="md-layout-item md-size-45">
        <md-card full>
          <div class="md-layout-item md-size-100">
            <md-card-header class="md-card-header-warning">
              <h4 class="title" style="color: white">
                Cliente:
                {{
                  agendamento.NomeCliente.slice(
                    0,
                    agendamento.NomeCliente.indexOf("-")
                  )
                }}
                <span style="opacity: 0; color: white">----</span>
                <md-button
                  v-show="servicosCliente.length > 0"
                  class="md-primary md-sm md-round"
                  @click="AbrirComanda()"
                  >{{
                    idComandaAberta > 0
                      ? "Visualizar Comanda " + idComandaAberta
                      : "Abrir Comanda"
                  }}</md-button
                >
              </h4>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <md-table
                  v-model="servicosCliente"
                  table-header-color="orange"
                  v-show="servicosCliente.length > 0"
                >
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Horário">{{
                      item.HoraInicio + " - " + item.HoraFim
                    }}</md-table-cell>
                    <!-- <md-table-cell md-label="Item">{{ item.ID_Agendamento }}</md-table-cell> -->
                    <md-table-cell md-label="Serviço">{{
                      item.Servico
                    }}</md-table-cell>
                    <md-table-cell md-label="Profissional">{{
                      item.Profissional
                    }}</md-table-cell>
                    <md-table-cell md-label="Valor">{{
                      item.Valor | currency
                    }}</md-table-cell>

                    <md-table-cell md-label=""
                      ><md-button
                        v-show="item.Status != 'Cancelado'"
                        class="md-just-icon md-simple md-danger"
                        @click="excluirCompromisso(item)"
                      >
                        <md-icon>close</md-icon>
                        <md-tooltip md-direction="top">Excluir</md-tooltip>
                      </md-button></md-table-cell
                    >
                  </md-table-row>
                </md-table>
              </div>
            </md-card-content>
          </div>
        </md-card>
      </div>
    </div>

    <modalComanda @closeModal="closeModal" v-if="mostraModalComanda" />

    <modalFormCliente
      :item="itemCliente"
      :load="load"
      @closeModal="closeModal"
      @load="vrload"
      v-if="abreModalCliente"
    ></modalFormCliente>
  </div>
</template>


<script>
// <script lang='ts'>

import Vue from "vue";
import api from "@/routes/api";
import { DateTimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { SchedulePlugin } from "@syncfusion/ej2-vue-schedule";
import { Dialog } from "@syncfusion/ej2-popups";
import { extend } from "@syncfusion/ej2-base";
import Swal from "sweetalert2";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import { AutoCompletePlugin } from "@syncfusion/ej2-vue-dropdowns";
import modalComanda from "../Dashboard/modalComanda.vue";
import modalFormCliente from "../Cadastros/Clientes/modalFormCliente.vue";

Vue.use(DropDownListPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(SchedulePlugin);

export default {
  components: {
    VueElementLoading,
    modalComanda,
    modalFormCliente,
  },
  data() {
    return {
      abreModalCliente: false,
      mostraModalComanda: false,
      dataInicio: new Date(),
      dataFim: new Date(),
      servicosCombo: [],
      clientesCombo: [],
      profissionaisCombo: [],
      load: true,
      agendamento: {
        ID_Agendamento: 0,
        ID_Cliente: 0,
        ID_Funcionario: 0,
        ID_Servico: 0,
        NomeCliente: "",
        Inicio: new Date(),
        Fim: new Date(),
        DiaInteiro: false,
        Sala: 0,
        Obs: ""
      },
      servicosCliente: [
        {
          ID_Agendamento: 0,
          NomeCliente: "",
          HoraInicio: "",
          HoraFim: "",
          DiaInteiro: false,
          ID_Cliente: 0,
          ID_Profissional: 0,
          Profissional: "",
          ID_Servico: 0,
          Servico: "",
          Valor: 0,
          Sala: 0,
          Obs: "",
        },
      ],
      itemCliente: {
        data_hora_cadastro: "",
        nome: "",
        email: "",
        cpf: "",
        foto: "",
        sexo: 1,
        nascimento: "",
        estado_civil: 1,
        profisscao: "",
        cep: "",
        logradouro: "",
        numero: 0,
        complemento: "",
        bairro: "",
        celular: "",
        telefone: "",
        excluido: false,
        obs: "",
        id_cidade: 0,
        id_cliente: 0,
        uf: "",
      },
      cancelaCompromissoAgenda: {
        ID_Agendamento: 0,
        Motivo_Cancelamento: ""
      },
      itemClienteCombo: null,
      itemVmodelCiente: null,
      itemFuncionarioCombo: null,
      itemVmodelFuncionario: null,
      itemServicoCombo: null,
      itemVmodelServico: null,
      fields: { value: "name" },
      autofill: true,
      selectedCountry: null,
      selectedItemCountry: null,
      getValueCountry: null,
      countryList: [
        {
          id: 1,
          name: "Algeria",
        },
        {
          id: 2,
          name: "Argentina",
        },
        {
          id: 3,
          name: "Brazil",
        },
        {
          id: 4,
          name: "Canada",
        },
        {
          id: 5,
          name: "Italy",
        },
        {
          id: 16,
          name: "Japan",
        },
        {
          id: 7,
          name: "United Kingdom",
        },
        {
          id: 8,
          name: "United States",
        },
      ],
      countries: [],
      data: {},
      format: "dd/MM/yyyy HH:mm",
      idComandaAberta: 0,
    };
  },
  methods: {
    vrload() {},
    closeModal(pagina = 0, atualiza = 0, idcliente = 0) {
      this.abreModalCliente = false;
      this.CarregaClientesCombo();
      
    },
    getCountries(searchTerm) {
      this.countries = new Promise((resolve) => {
        window.setTimeout(() => {
          if (!searchTerm) {
            resolve(this.countryList);
          } else {
            const term = searchTerm.toLowerCase();

            resolve(
              this.countryList.filter(({ name }) =>
                name.toLowerCase().includes(term)
              )
            );
          }
        }, 500);
      });
    },

    recebeValorAutoCliente(args) {
      this.itemClienteCombo = args;
      this.agendamento.ID_Cliente = args.itemData.id_cliente;
      this.agendamento.NomeCliente = args.itemData.AutoComplete;
      this.VerificaComandaAberta(this.agendamento.ID_Cliente);

      this.CarregaServicosDoCliente(
        this.agendamento.ID_Cliente,
        moment(String(this.data.StartTime)).format("YYYY-MM-DD")
      );
    },
    recebeValorAutoFuncionario(args) {
      this.itemFuncionarioCombo = args;
      this.agendamento.ID_Funcionario = args.itemData.id_funcionario;
      this.itemVmodelServico = "";
      this.agendamento.ID_Servico = 0;

      this.CarregaServicosCombo();
    },
    recebeValorAutoServico(args) {
      this.itemServicoCombo = args;
      this.agendamento.ID_Servico = args.itemData.idServicoProduto;
    },
    AbreFechaForm: function (visible) {
      this.$emit("AbreFechaForm", false);
      // let scheduleObj = document.getElementById('Schedule').ej2_instances[0];
      // scheduleObj.closeEditor();
      // moment(String(agendamento.dtInicioServico)).format('MM/DD/YYYY hh:mm')
    },
    AbrirComanda() {
      if (this.idComandaAberta > 0) {
        this.$router.push("/dashboard");
        return;
      }

      api
        .post(`comanda/abrir_comanda/${this.agendamento.ID_Cliente}`)
        .then((resp) => {
          this.idComandaAberta = resp.data.ID_Comanda;
        })
        .catch((error) => {
          alert(error);
        });
    },
    VerificaComandaAberta(vrIdCliente) {
      api
        .get(`comanda/busca_comanda_aberta/${vrIdCliente}`)
        .then((resp) => {
          this.idComandaAberta = resp.data.ID_Comanda;
        })
        .catch((error) => {
          alert(error);
        });
    },
    Salvar() {
      // sessionStorage.setItem('AgendamentoAtual', '')

      const today = new Date();

      if (this.dataFim < this.dataInicio) {
        this.$notify({
          timeout: 2500,
          message: "Data/Hora do Término inferior a de início!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }

      if (this.agendamento.ID_Cliente == 0) {
        this.$notify({
          timeout: 2500,
          message: "Cliente inválido!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }

      if (this.agendamento.ID_Funcionario == 0) {
        this.$notify({
          timeout: 2500,
          message: "Profissional inválido!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }

      if (this.agendamento.ID_Servico == 0) {
        this.$notify({
          timeout: 2500,
          message: "Informe o Serviço!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }

      this.agendamento.Inicio = moment(this.dataInicio).format(
        "YYYY-MM-DD HH:mm"
      );
      this.agendamento.Fim = moment(this.dataFim).format("YYYY-MM-DD HH:mm");

      let urlApi = "agenda/agenda-compromisso";
      api
        .post(urlApi, this.agendamento, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          let scheduleObj =
            document.getElementById("Schedule").ej2_instances[0];
          
          this.$emit("AbreFechaForm", false);

          this.$emit(
            "carregaCompromissos",
            moment(scheduleObj.activeView.renderDates[0]).format("YYYY-MM-DD"),
            moment(scheduleObj.activeView.renderDates[0]).format("YYYY-MM-DD")
          );
          
          this.CarregaServicosDoCliente(
            this.agendamento.ID_Cliente,
            moment(String(this.data.StartTime)).format("YYYY-MM-DD")
          );

          this.$emit("load", false);
        })
        .catch((error) => {
          this.$emit("load", false);
          this.$notify({
            timeout: 2500,
            message: error,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        });

        
    },
    CarregaServicosDoCliente(vrIdCliente, vrData) {
      api
        .get(`agenda/lista-servicos-dia/${vrIdCliente}/${vrData}`)
        .then((resp) => {
          this.servicosCliente = resp.data;
        })
        .catch((error) => {
          this.$notify({
            timeout: 2500,
            message: error,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        });
    },
    OnSelect(args) {
      //alert(args);
    },
    CarregaServicosCombo() {
      this.load = true;
      // this.agendamento.ID_Servico = 0
      // this.itemVmodelServico = ""

      api
        .get(
          `servicos/lista-servicos-combo/s/${
            this.agendamento.ID_Funcionario === null
              ? 0
              : this.agendamento.ID_Funcionario
          }`
        )
        .then((resp) => {
          this.servicosCombo = resp.data;
          this.itemVmodelServico = this.servicosCombo.filter(
            (c) => c.idServicoProduto === this.dataEditor.ID_Servico
          )[0].descricao;
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
    },
    CarregaClientesCombo: async function () {
      this.load = true;
      api
        .get("clientes/lista-clientes-combo")
        .then((resp) => {
          this.clientesCombo = resp.data;

          var idCliCombo = this.compromissos.filter(
            (d) => d.Id === this.dataEditor.Id
          );
          this.itemVmodelCiente = this.clientesCombo.filter(
            (c) => c.id_cliente === idCliCombo[0].ID_Cliente
          )[0].AutoComplete;
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
    },
    CarregaProfissionaisCombo: function (args) {
      this.load = true;

      api
        .get(`profissionais/lista-profissionais-combo/0`)
        .then((resp) => {
          this.profissionaisCombo = resp.data;

          this.itemVmodelFuncionario = this.profissionaisCombo.filter(
            (c) => c.id_funcionario === this.dataEditor.OwnerId
          )[0].nome;
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
    },
    async excluirCompromisso(item) {
      // console.log(item);
      this.cancelaCompromissoAgenda.ID_Agendamento = item.ID_Agendamento;
      const { value: cancelReason } = await Swal.fire({
        title: 'Informe o Motivo do Cancelamento',
        input: 'text',
        inputPlaceholder: 'Motivo do Cancelamento',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        confirmButtonClass: "md-button md-success btn-fill",
        cancelButtonClass: "md-button md-danger btn-fill",
        inputValidator: (value) => {
          if (!value) {
            return 'Você precisa informar um motivo!';
          }
        }
      });

      if (cancelReason) {
        this.cancelaCompromissoAgenda.Motivo_Cancelamento = cancelReason;
        
        api.post("agenda/cancela-compromisso-agenda", this.cancelaCompromissoAgenda)
        .then(response => {

          let scheduleObj =
            document.getElementById("Schedule").ej2_instances[0];
          
          this.$emit("AbreFechaForm", false);

          this.$emit(
            "carregaCompromissos",
            moment(scheduleObj.activeView.renderDates[0]).format("YYYY-MM-DD"),
            moment(scheduleObj.activeView.renderDates[0]).format("YYYY-MM-DD")
          );


          this.CarregaServicosDoCliente(
            this.agendamento.ID_Cliente,
            moment(String(this.data.StartTime)).format("YYYY-MM-DD")
          )
          
          this.$notify({
            timeout: 2500,
            message: "Agendamento cancelado com sucesso!",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success"
          });
        })
        .catch(error => {
          this.$notify({
            timeout: 2500,
            message: error.message,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger"
          });
        });
      }
    }
  },
  mounted: function () {
    this.data = this.dataEditor;
    this.dataInicio = this.data.StartTime;
    this.dataFim = this.data.EndTime;
    this.agendamento.ID_Funcionario = this.dataEditor.OwnerId;
    // this.agendamento.ID_Servico =
    //   typeof this.dataEditor.ID_Servico === "undefined"
    //     ? 0
    //     : this.dataEditor.ID_Servico;
    this.agendamento.Obs =
      typeof this.data.Obs == "undefined" ? "" : this.data.Obs;
    this.agendamento.ID_Agendamento = !this.dataEditor.Id
      ? 0
      : this.dataEditor.Id;
    this.agendamento.ID_Cliente =
      typeof this.dataEditor.ID_Cliente == "undefined"
        ? 0
        : this.dataEditor.ID_Cliente;

    this.CarregaServicosDoCliente(
      this.agendamento.ID_Cliente,
      moment(String(this.data.StartTime)).format("YYYY-MM-DD")
    );

    this.VerificaComandaAberta(this.agendamento.ID_Cliente);

    var idCliCombo = this.compromissos.filter(
      (d) => d.Id === this.dataEditor.Id
    );

    this.CarregaServicosCombo()

    if (typeof idCliCombo[0].Subject != "undefined") {
      this.agendamento.NomeCliente = idCliCombo[0].Subject;
    }

    this.load = false;
  },
  created: async function () {
    await this.CarregaClientesCombo();
    await this.CarregaServicosCombo();
    await this.CarregaProfissionaisCombo();
  },
  props: ["dataEditor", "compromissos"],
  computed: {
    // idClienteComputed: {
    //   get () {
    //     return this.agendamento.ID_Cliente
    //   },
    //   set (val) {
    //     return this.agendamento.ID_Cliente = val
    //   }
    // }
  },
  watch: {
    recebeValorAutoFuncionario() {
      this.itemVmodelServico = "";
      this.agendamento.ID_Servico = 0;

      this.CarregaServicosCombo();
    },
  },
};
</script>

<style>
div#Schedule_dialog_wrapper {
  width: 900px !important;
}
</style>

<style lang="scss" scoped>
.md-autocomplete {
}

transparente {
  opacity: 0; /* Mal consegue ver o texto acima do background */

  #input-container .e-input-group {
    /* csslint allow: adjoining-classes */
    margin: 30px 0;
  }
  #input-container .e-float-input {
    /* csslint allow: adjoining-classes */
    margin: 30px 0;
  }
  .e-input-group-icon:before {
    font-family: e-icons;
  }

  .e-input-group .e-input-group-icon.e-input-popup-date {
    /* csslint allow: adjoining-classes */
    font-size: 16px;
  }

  .e-input-group.e-small .e-input-group-icon.e-input-popup-date {
    /* csslint allow: adjoining-classes */
    font-size: 14px;
  }

  .e-input-group-icon.e-input-popup-date:before {
    /* csslint allow: adjoining-classes */
    content: "";
  }

  .e-input-group-icon.e-input-up:before {
    /* csslint allow: adjoining-classes */
    content: "\e85e";
  }

  .e-input-group-icon.e-input-down:before {
    /* csslint allow: adjoining-classes */
    content: "";
  }

  .e-input-group-icon.e-input-plus:before {
    /* csslint allow: adjoining-classes */
    content: "\e7ba";
  }

  .e-input-group-icon.e-input-minus:before {
    /* csslint allow: adjoining-classes */
    content: "\e814";
  }

  .e-input-group-icon.e-input-date:before {
    /* csslint allow: adjoining-classes */
    content: "";
  }

  .e-input-group-icon.e-input-left:before {
    /* csslint allow: adjoining-classes */
    content: "\e904";
  }

  .e-input-group-icon.e-input-right:before {
    /* csslint allow: adjoining-classes */
    content: "\e913";
  }

  .e-input-group-icon.e-input-reload:before {
    /* csslint allow: adjoining-classes */
    content: "\e837";
  }

  .e-input-group-icon.e-input-search:before {
    /* csslint allow: adjoining-classes */
    content: "\e806";
  }

  pb {
    padding-bottom: 7px;
  }
}
</style>