<template>
  <transition name="modal">
    <!-- <loading :active.sync="load" :can-cancel="true"></loading> -->
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header
                  class="md-tabs-navigation md-card-header-warning"
                >
                  <h4 class="title" style="color: white">
                    Conta a Pagar - Código: {{ item.id_conta_pagar }}
                  </h4>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-25">
                      <ejs-autocomplete
                        v-model="itemVmodelFornecedor"
                        floatLabelType="Auto"
                        :autofill="autofill"
                        :dataSource="fornecedoresCombo"
                        :select="recebeValorFornecedor"
                        :fields="{
                          text: 'razao_social',
                          value: 'razao_social',
                        }"
                        placeholder="Selecione o Fornecedor"
                      ></ejs-autocomplete>
                    </div>
                    <div class="md-layout-item md-size-25">
                      <ejs-autocomplete
                        v-model="itemVmodelCentroCusto"
                        floatLabelType="Auto"
                        :autofill="autofill"
                        :dataSource="centroCustoCombo"
                        :select="recebeValorCentroCusto"
                        :fields="{ text: 'descricao', value: 'descricao' }"
                        placeholder="Selecione o Centro de Custo"
                      ></ejs-autocomplete>
                    </div>

                    <!-- <div class="md-layout-item md-size-15">
                      <ejs-datepicker
                        v-bind:value="
                          item.data_quitacao != null && item.data_quitacao != ''
                            ? moment(item.data_quitacao).format('DD/MM/YYYY')
                            : null
                        "
                        :placeholder="'Data Quitação'"
                        floatLabelType="Auto"
                        :format="maskData"
                        :enableMask="true"
                        :change="alteraData"
                      ></ejs-datepicker>
                    </div> -->

                    <!-- <div class="md-layout-item md-size-20">
                      <div style="height: 80px">
                        <md-field>
                          <label>Condição de Pagamento</label>
                          <md-input
                            type="search"
                            class="mb-5"
                            clearable
                            style="width: 500px"
                            v-model="item.condicao_pagamento"
                          ></md-input>
                        </md-field>
                      </div>
                    </div> -->

                    <div class="md-layout-item md-size-20">
                      <div style="height: 80px">
                        <ejs-dropdownlist
                          floatLabelType="Auto"
                          id="dropdownlist"
                          v-model="item.condicao_pagamento"
                          :dataSource="condicaoPagamento"
                          :fields="fieldsCondicaoPagamento"
                          placeholder="Condição Pagamento"
                        ></ejs-dropdownlist>
                      </div>
                    </div>

                    <div>
                      Quitado:
                      <md-switch
                        style="margin: 0 0 0 0"
                        v-model="item.quitada"
                      ></md-switch>
                    </div>
                  </div>
                </md-card-content>

                <div v-show="item.id_conta_pagar > 0">
                  <md-card-header
                    class="md-tabs-navigation md-card-header-warning"
                  >
                    <h4 class="title" style="color: white">Parcelas</h4>
                  </md-card-header>

                  <md-card-content>
                    <div class="md-layout">
                      <div
                        v-show="incluindoItem == false"
                        class="md-layout-item md-size-10"
                      >
                        <md-button
                          class="
                            md-button
                            md-just-icon
                            md-theme-default
                            md-info
                            md-sm
                          "
                          @click.native="novoItem"
                        >
                          <md-icon>add</md-icon>
                        </md-button>
                      </div>

                      <div
                        v-show="incluindoItem"
                        class="md-layout-item md-size-15"
                      >
                        <md-field class="md-size-10 my-input-upper">
                          <label>Nro. de Parcelas</label>
                          <md-input
                            disabled="true"
                            v-model="contaPagarParcelas.nro_parcela"
                            autocomplete="nope"
                            maxlength="100"
                            md-counter="false"
                          ></md-input>
                        </md-field>
                      </div>

                      <div
                        v-show="incluindoItem"
                        class="md-layout-item md-size-20"
                      >
                        <ejs-datepicker
                          v-bind:value="
                            contaPagarParcelas.vencimento != null &&
                            contaPagarParcelas.vencimento != ''
                              ? moment(contaPagarParcelas.vencimento).format(
                                  'DD/MM/YYYY'
                                )
                              : null
                          "
                          :placeholder="'Data Vencimento'"
                          floatLabelType="Auto"
                          :format="maskData"
                          :enableMask="true"
                          :change="alteraDataVencimento"
                        ></ejs-datepicker>
                      </div>

                      <div
                        v-show="incluindoItem"
                        class="md-layout-item md-size-15"
                      >
                        <md-field class="md-size-10 my-input-upper">
                          <label>Valor</label>
                          <md-input
                            v-model="contaPagarParcelas.valor"
                            autocomplete="nope"
                            maxlength="100"
                            md-counter="false"
                          ></md-input>
                        </md-field>
                      </div>

                      <div
                        v-show="incluindoItem"
                        class="md-layout-item md-size-15"
                      >
                        <md-field class="md-size-10 my-input-upper">
                          <label>Acréscimo</label>
                          <md-input
                            v-model="contaPagarParcelas.acrescimo"
                            autocomplete="nope"
                            maxlength="100"
                            md-counter="false"
                          ></md-input>
                        </md-field>
                      </div>

                      <div
                        v-show="incluindoItem"
                        class="md-layout-item md-size-15"
                      >
                        <md-field class="md-size-10 my-input-upper">
                          <label>Desconto</label>
                          <md-input
                            v-model="contaPagarParcelas.desconto"
                            autocomplete="nope"
                            maxlength="100"
                            md-counter="false"
                          ></md-input>
                        </md-field>
                      </div>

                      <div
                        v-show="incluindoItem"
                        class="md-layout-item md-size-15"
                      >
                        <md-field class="md-size-10 my-input-upper">
                          <label>Subtotal</label>
                          <md-input
                            disabled="true"
                            v-model="contaPagarParcelas.subtotal"
                            autocomplete="nope"
                            maxlength="100"
                            md-counter="false"
                          ></md-input>
                        </md-field>
                      </div>

                      <div v-show="incluindoItem">
                        Quitado:
                        <md-switch
                          style="margin: 0 0 0 0"
                          v-model="contaPagarParcelas.quitada"
                        ></md-switch>
                      </div>

                      <div
                        v-show="contaPagarParcelas.quitada && incluindoItem"
                        class="md-layout-item md-size-20"
                      >
                        <ejs-datepicker
                          v-bind:value="
                            contaPagarParcelas.data_quitacao != null &&
                            contaPagarParcelas.data_quitacao != ''
                              ? moment(contaPagarParcelas.data_quitacao).format(
                                  'DD/MM/YYYY'
                                )
                              : null
                          "
                          :placeholder="'Data Quitação'"
                          floatLabelType="Auto"
                          :format="maskData"
                          :enableMask="true"
                          :change="alteraDataQuitacao"
                        ></ejs-datepicker>
                      </div>

                      <div
                        v-show="contaPagarParcelas.quitada"
                        class="md-layout-item md-size-60"
                      ></div>
                      
                      <div
                        v-show="contaPagarParcelas.quitada == false"
                        class="md-layout-item md-size-80"
                      ></div>

                      <div
                        v-show="incluindoItem"
                        class="md-layout-item md-size-5 text-right"
                      >
                        <md-button
                          @click="cancelaInclusaoContaParcela"
                          class="
                            md-button md-just-icon md-theme-default md-danger
                          "
                        >
                          <md-icon>close</md-icon>
                        </md-button>
                      </div>
                      <div
                        v-show="incluindoItem"
                        class="md-layout-item md-size-5 text-right"
                      >
                        <md-button
                          class="
                            md-button md-just-icon md-theme-default md-success
                          "
                          @click.native="addItemContaParcela"
                        >
                          <md-icon>done</md-icon>
                        </md-button>
                      </div>

                      <div
                        v-show="incluindoItem == false"
                        class="md-layout-item md-size-90"
                      >
                        <md-table v-model="lstItensContaPagarParcela">
                          <md-table-row
                            slot="md-table-row"
                            slot-scope="{ item }"
                          >
                            <md-table-cell
                              style="width: 14%"
                              md-label="Nro. Parcelas."
                              >{{ item.id_parcela }}
                            </md-table-cell>
                            <md-table-cell
                              style="width: 14%"
                              md-label="Valor"
                              >{{ item.valor | currency }}</md-table-cell
                            >
                            <md-table-cell
                              style="width: 14%"
                              md-label="Acréscimo"
                              >{{ item.acrescimo | currency }}
                            </md-table-cell>
                            <md-table-cell
                              style="width: 14%"
                              md-label="Desconto"
                              >{{ item.desconto | currency }}
                            </md-table-cell>
                            <md-table-cell
                              style="width: 14%"
                              md-label="Vencimento"
                              >{{
                                moment(item.vencimento).format("DD/MM/YYYY")
                              }}</md-table-cell
                            >
                            <md-table-cell
                              style="width: 14%"
                              md-label="Subtotal"
                              >{{ item.subtotal | currency }}
                            </md-table-cell>
                            <md-table-cell>
                              <md-button
                                @click="abreModalPagamentos(item.id_parcela)"
                                class="md-just-icon md-simple md-success"
                              >
                                <md-icon>paid</md-icon>
                                <md-tooltip md-direction="top"
                                  >Pagamento de Conta</md-tooltip
                                >
                              </md-button>
                              <md-button
                                @click="editaContaPagarParcelaItem(item)"
                                class="md-just-icon md-simple md-primary"
                              >
                                <md-icon>edit</md-icon>
                                <md-tooltip md-direction="top"
                                  >Editar</md-tooltip
                                >
                              </md-button>

                              <md-button
                                @click="excluiContaPagarParcelaItem(item)"
                                class="md-just-icon md-simple md-danger"
                              >
                                <md-icon>close</md-icon>
                                <md-tooltip md-direction="top"
                                  >Excluir</md-tooltip
                                >
                              </md-button>
                            </md-table-cell>
                          </md-table-row>
                        </md-table>
                      </div>
                    </div>
                  </md-card-content>
                </div>
                <div class="footer mx-auto">
                  <md-button
                    class="md-success md-sm md-round"
                    @click="salvaRegistro"
                    >Salvar</md-button
                  >&nbsp;
                  <md-button
                    class="md-danger md-sm md-round"
                    @click="closeModal(1, 1)"
                    >Cancelar</md-button
                  >
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
      <modalFormContaPagarPagamentos
        @load="vrload"
        v-if="ativaModal"
        @closeModal="closeModal"
        :idParcela="idParcelaSelecionada"
      >
      </modalFormContaPagarPagamentos>
    </div>
  </transition>
</template>

<script>
import Swal from "sweetalert2";
import Vue from "vue";
import api from "@/routes/api";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin, TextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import modalFormContaPagarPagamentos from "./modalContasPagarPagamentos.vue";

Vue.use(DatePickerPlugin, DropDownListPlugin, TextBoxPlugin);

export default {
  name: "modal",
  components: {
    modalFormContaPagarPagamentos,
  },
  data() {
    return {
      ativaModal: false,
      itemVmodelFornecedor: null,
      itemVmodelCentroCusto: null,
      idParcelaSelecionada: 0,
      fornecedoresCombo: [],
      centroCustoCombo: [],
      lstItensContaPagarParcela: [],
      itemFornecedorCombo: null,
      itemCentroCustoCombo: null,
      incluindoItem: false,
      itemSel: null,
      contaPagarParcelas: {
        id_parcela: 0,
        nro_parcela: 0,
        vencimento: "",
        valor: 0,
        acrescimo: 0,
        desconto: 0,
        subtotal: 0,
        quitada: false,
        data_quitacao: "",
        id_conta_pagar: this.item.id_conta_pagar,
      },
      
      condicaoPagamento: [
        { id: "A PRAZO", tipo: "A PRAZO" },
        { id: "A VISTA", tipo: "A VISTA" },
      ],
      fieldsCondicaoPagamento: { text: "tipo", value: "id" },
    };
  },
  methods: {
    closeModal: function (pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    salvaRegistro() {
      if (this.item.id_conta_pagar == 0) {
        this.$emit("load", true);
        api
          .put(`contas-a-pagar/incluir-conta-pagar`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);
            this.item.id_conta_pagar = resp.data.id_conta_pagar;
            // this.closeModal(1, 1);
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Não foi possivel incluir o pagamento!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      } else {
        this.$emit("load", true);
        api
          .post(`contas-a-pagar/altera-conta-pagar`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);
            this.closeModal(1, 1);
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "error",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            this.$emit("load", false);
            return;
          });
      }
    },
    addItemContaParcela() {
      if (
        this.contaPagarParcelas.vencimento == "" ||
        this.contaPagarParcelas.vencimento == null
      ) {
        this.$notify({
          timeout: 2500,
          message: "Insira a data de vencimento",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }

      if (this.contaPagarParcelas.data_quitacao == null) {
        this.contaPagarParcelas.data_quitacao = "";
      }

      if (this.contaPagarParcelas.acrescimo == "") {
        this.contaPagarParcelas.acrescimo = 0
      }

      if (this.contaPagarParcelas.desconto == "") {
        this.contaPagarParcelas.desconto = 0
      }

      if (this.contaPagarParcelas.valor == "") {
        this.$notify({
              timeout: 2500,
              message: "Insira um valor",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            return;
      }

      if (this.contaPagarParcelas.id_parcela == 0) {
        api
          .put(
            `contas-a-pagar-parcelas/incluir-conta-pagar-parcela?idContaPagar=${this.item.id_conta_pagar}`,
            this.contaPagarParcelas
          )
          .then((resp) => {
            this.carregaItemContaPagarParcela();
            this.incluindoItem = false;
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Não conseguimos incluir a parcela",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      } else {
        api
          .post(
            `contas-a-pagar-parcelas/altera-conta-pagar-parcela?idContaPagar=${this.item.id_conta_pagar}`,
            this.contaPagarParcelas
          )
          .then((resp) => {
            this.carregaItemContaPagarParcela();
            this.incluindoItem = false;
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Não foi possível fazer a atualização dos valores!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      }
    },
    novoItem() {
      this.contaPagarParcelas.id_parcela = 0;
      this.contaPagarParcelas.nro_parcela = 0;
      this.contaPagarParcelas.vencimento = "";
      this.contaPagarParcelas.valor = 0;
      this.contaPagarParcelas.acrescimo = 0;
      this.contaPagarParcelas.desconto = 0;
      this.contaPagarParcelas.sub_total = 0;
      this.contaPagarParcelas.quitada = false;
      this.contaPagarParcelas.data_quitacao = "";

      this.incluindoItem = true;
    },
    editaContaPagarParcelaItem(itemContaPagarParcelas) {
      this.contaPagarParcelas.id_parcela = itemContaPagarParcelas.id_parcela;
      this.contaPagarParcelas.nro_parcela = itemContaPagarParcelas.nro_parcela;
      this.contaPagarParcelas.vencimento = itemContaPagarParcelas.vencimento;
      this.contaPagarParcelas.valor = itemContaPagarParcelas.valor;
      this.contaPagarParcelas.acrescimo = itemContaPagarParcelas.acrescimo;
      this.contaPagarParcelas.desconto = itemContaPagarParcelas.desconto;
      this.contaPagarParcelas.subtotal = itemContaPagarParcelas.subtotal;
      this.contaPagarParcelas.quitada = itemContaPagarParcelas.quitada;
      this.contaPagarParcelas.data_quitacao =
        itemContaPagarParcelas.data_quitacao;
      this.contaPagarParcelas.id_conta_pagar =
        itemContaPagarParcelas.id_conta_pagar;

      this.incluindoItem = true;
    },
    cancelaInclusaoContaParcela() {
      this.incluindoItem = false;
    },
    excluiContaPagarParcelaItem(ItemPacoteItem) {
      this.$emit("load", true);
      Swal.fire({
        title: "Confirma a Exclusão do registro?",
        text: `Não é possível reverter esta operação!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success btn-fill",
        cancelButtonClass: "md-button md-danger btn-fill",
        confirmButtonText: "Sim!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.load = true;
          api
        .delete(
          `contas-a-pagar-parcelas/exclui-conta-pagar-parcela/${ItemPacoteItem.id_parcela}`
        )
        .then((resp) => {
          
          this.carregaItemContaPagarParcela();
          this.$emit("load", false);
          this.$notify({
              timeout: 2500,
              message: "Parcela excluída com sucesso!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
          
          return ;
        })
            .catch((error) => {
              this.load = false;
              Swal.fire({
                title: "Falha ao Excluir Registro!",
                text: `${error}`,
                type: "error",
                confirmButtonClass: "md-button md-success btn-fill",
                buttonsStyling: false,
              });
            });
        }
      });

      
      
    },
    alteraDataVencimento(args) {
      this.contaPagarParcelas.vencimento = args.value;
    },
    alteraDataQuitacao(args) {
      this.contaPagarParcelas.data_quitacao = args.value;
    },
    carregaItemContaPagarParcela() {
      api
        .get(
          `contas-a-pagar-parcelas/lista-contas-pagar-parcelas/1/999/${this.item.id_conta_pagar}`
        )
        .then((resp) => {
          this.lstItensContaPagarParcela = resp.data.Data;
        });
    },
    CarregaForncedoresCombo: function (args) {
      this.load = true;
      api
        .get(`fornecedores/lista-fornecedores/1/999`)
        .then((resp) => {
          this.fornecedoresCombo = resp.data.Data;

          this.itemVmodelFornecedor = this.fornecedoresCombo.filter(
            (c) => c.id_fornecedor === this.dataEditor.OwnerId
          )[0].razao_social;
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
    },

    abreModalPagamentos(idParcela) {
      // let itemNew = this.contaPagarParcelas;
      // this.itemSel = itemNew;

      this.idParcelaSelecionada = idParcela

      this.ativaModal = true;
    },

    recebeValorFornecedor(args) {
      this.itemFornecedorCombo = args;
      this.item.id_fornecedor = args.itemData.id_fornecedor;
    },
    CarregaCentroCustoCombo: function (args) {
      this.load = true;
      api
        .get(`centro-custos/lista-centro-custo/0/999`)
        .then((resp) => {
          this.centroCustoCombo = resp.data.Data;

          this.itemVmodelCentroCusto = this.centroCustoCombo.filter(
            (c) => c.id_centro_de_custos === this.dataEditor.OwnerId
          )[0].descricao;
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
    },
    recebeValorCentroCusto(args) {
      this.itemCentroCustoCombo = args;
      this.item.id_centro_custo = args.itemData.id_centro_de_custos;
    },
  },
  mounted() {
    (this.itemVmodelFornecedor = this.item.razao_social),
      (this.itemVmodelCentroCusto = this.item.descricao);
  },
  created: async function () {
    await this.CarregaForncedoresCombo(),
      await this.CarregaCentroCustoCombo(),
      await this.carregaItemContaPagarParcela();
  },
  props: ["item", "pagina", "termo", "perPage", "load"],
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 10;
}
.modal-container {
  max-width: 950px;
}
.md-menu-content {
  z-index: 99999 !important;
}
.my-input-upper input {
  text-transform: uppercase;
}
.my-input-lower input {
  text-transform: lowercase;
}

// .md-tabs-navigation {
//     -webkit-box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
//     box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
//     margin: 0px 0 0;
//     border-radius: 3px;
//     padding: 0px 0px 0px 0px;
//     background-color: #999999;
// }
#tab-home > div > div.md-card.md-card-nav-tabs.md-theme-default > div {
  padding: 0 0 0 0;
}

#tab-home
  > div
  > div.md-card.md-card-nav-tabs.md-theme-default
  > div
  > div
  > div.md-content.md-tabs-content.md-theme-default {
  min-height: 135px;
}

body
  > div
  > div.main-panel.ps
  > div.content
  > div
  > div.modal-mask
  > div
  > div
  > div
  > div
  > div
  > div.md-content.md-tabs-content.md-theme-default {
  min-height: 650px;
}
</style>
