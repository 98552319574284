import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";
import GuardRoute from "./guard-route";
import Router from "vue-router";


// Dashboard pages
//import Dashboard from "@/pages/Dashboard/Dashboard.vue";
//import DashFinanceiro from "@/pages/Dashboard/DashboardFinanceiro.vue";

const Dashboard = () => import("@/pages/Dashboard/Dashboard.vue");
const DashFinanceiro = () => import("@/pages/Dashboard/DashboardFinanceiro.vue");
const Login = () => import("@/pages/Dashboard/Pages/Login.vue");

// Pages
// import User from "@/pages/Dashboard/Pages/UserProfile.vue";
// import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
// import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
// import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";

// import Register from "@/pages/Dashboard/Pages/Register.vue";
// import Lock from "@/pages/Dashboard/Pages/Lock.vue";

// import Login from "@/pages/Dashboard/Pages/Login.vue";
// Components pages
// import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
// import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
// import Panels from "@/pages/Dashboard/Components/Panels.vue";
// import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
// import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
// import Icons from "@/pages/Dashboard/Components/Icons.vue";
// import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Forms pages
// import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
// import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
// import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
// import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";

// TableList pages
// import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
// import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
// import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Maps pages
// import GoogleMaps from "@/pages/Dashboard/Maps/GoogleMaps.vue";
// import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";
// import VectorMaps from "@/pages/Dashboard/Maps/VectorMaps.vue";

// Calendar
// import Calendar from "@/pages/Dashboard/Calendar.vue";

import Agenda from "@/pages/Agenda/Agenda.vue";

// Charts
import Charts from "@/pages/Dashboard/Charts.vue";
import Widgets from "@/pages/Dashboard/Widgets.vue";

//CADASTROS
import CadCustoFixo from "@/pages/Cadastros/CustoFixo/ListaCustosFixos.vue";
import CadCidades from "@/pages/Cadastros/Cidades/ListaCidades.vue";
import CadClientes from "@/pages/Cadastros/Clientes/ListaClientes.vue";
import CadFornecedores from "@/pages/Cadastros/Fornecedores/ListaFornecedores.vue";
import CadFuncionarios from "@/pages/Cadastros/Funcionarios/ListaFuncionarios.vue";
import CadGruposProdServ from "@/pages/Cadastros/GruposProdServ/ListaGruposProdServ.vue";
import CadProfissionais from "@/pages/Cadastros/Profissionais/ListaProfissionais.vue";
import CadProdutosServicos from "@/pages/Cadastros/ProdutosServicos/ListaProdutosServicos.vue";
import CadSangrias from "@/pages/Cadastros/Sangria/ListaSangria.vue";
import CadVales from "@/pages/Cadastros/Vales/ListaVale.vue";
import CadPacotes from "@/pages/Cadastros/Pacotes/ListaPacotes.vue";
import CadMensagens from "@/pages/Mensageria/ListaMensagens.vue";
import CadParametros from "@/pages/Cadastros/Parametros/ListaParametros.vue";
import CadCategoriasCliente from "@/pages/Cadastros/CategoriasCliente/ListaCategoriasCliente.vue";

//FINANCEIRO
import ContasReceber from "@/pages/Financeiro/ContasReceber/ListaContasReceber.vue"
import ContasPagar from "@/pages/Financeiro/ContasPagar/ListaContasPagar.vue"

import Vue from "vue";
Vue.use(Router);


// let componentsMenu = {
//   path: "/components",
//   component: DashboardLayout,
//   redirect: "/components/buttons",
//   name: "Components",
//   children: [
//     {
//       path: "buttons",
//       name: "Buttons",
//       components: { default: Buttons }
//     },
//     {
//       path: "grid-system",
//       name: "Grid System",
//       components: { default: GridSystem }
//     },
//     {
//       path: "panels",
//       name: "Panels",
//       components: { default: Panels }
//     },
//     {
//       path: "sweet-alert",
//       name: "Sweet Alert",
//       components: { default: SweetAlert }
//     },
//     {
//       path: "notifications",
//       name: "Notifications",
//       components: { default: Notifications }
//     },
//     {
//       path: "icons",
//       name: "Icons",
//       components: { default: Icons }
//     },
//     {
//       path: "typography",
//       name: "Typography",
//       components: { default: Typography }
//     }
//   ]
// };

// let formsMenu = {
//   path: "/forms",
//   component: DashboardLayout,
//   redirect: "/forms/regular",
//   name: "Forms",
//   children: [
//     {
//       path: "regular",
//       name: "Regular Forms",
//       components: { default: RegularForms }
//     },
//     {
//       path: "extended",
//       name: "Extended Forms",
//       components: { default: ExtendedForms }
//     },
//     {
//       path: "validation",
//       name: "Validation Forms",
//       components: { default: ValidationForms }
//     },
//     {
//       path: "wizard",
//       name: "Wizard",
//       components: { default: Wizard }
//     }
//   ]
// };

// let tablesMenu = {
//   path: "/table-list",
//   component: DashboardLayout,
//   redirect: "/table-list/regular",
//   name: "Tables",
//   children: [
//     {
//       path: "regular",
//       name: "Regular Tables",
//       components: { default: RegularTables }
//     },
//     {
//       path: "extended",
//       name: "Extended Tables",
//       components: { default: ExtendedTables }
//     },
//     {
//       path: "paginated",
//       name: "Cadastro de Cliente",
//       components: { default: PaginatedTables }
//     }
//   ]
// };

// let mapsMenu = {
//   path: "/maps",
//   component: DashboardLayout,
//   name: "Maps",
//   redirect: "/maps/google",
//   children: [
//     {
//       path: "google",
//       name: "Google Maps",
//       components: { default: GoogleMaps }
//     },
//     {
//       path: "full-screen",
//       name: "Full Screen Map",
//       meta: {
//         hideContent: true,
//         hideFooter: true,
//         navbarAbsolute: true
//       },
//       components: { default: FullScreenMap }
//     },
//     {
//       path: "vector-map",
//       name: "Vector Map",
//       components: { default: VectorMaps }
//     }
//   ]
// };

// let pagesMenu = {
//   path: "/pages",
//   component: DashboardLayout,
//   name: "Pages",
//   redirect: "/pages/user",
//   children: [
//     // {
//     //   path: "user",
//     //   name: "User Page",
//     //   components: { default: User }
//     // },
//     // {
//     //   path: "timeline",
//     //   name: "Timeline Page",
//     //   components: { default: TimeLine }
//     // },
//     // {
//     //   path: "rtl",
//     //   name: "وحة القيادة",
//     //   meta: {
//     //     rtlActive: true
//     //   },
//     //   components: { default: RtlSupport }
//     // }
//   ]
// };

let cadastros = {
  path: "/",
  component: DashboardLayout,

  children: [
    
    {
      path: "/cadastros/cad-custo-fixo",
      name: "Cadastro de Custos Fixos",
      components: { default: CadCustoFixo }
    },
    {
      path: "/cadastros/cad-cidades",
      name: "Cadastro de Cidades",
      components: { default: CadCidades }
    },
    {
      path: "/cadastros/cad-clientes",
      name: "Cadastro de Clientes",
      components: { default: CadClientes }
    },
    {
      path: "/cadastros/cad-fornecedores",
      name: "Cadastro de Fornecedores",
      components: { default: CadFornecedores }
    },
    {
      path: "/cadastros/cad-funcionarios",
      name: "Cadastro de Funcionários",
      components: { default: CadFuncionarios }
    },
    {
      path: "/cadastros/cad-grupos-prodserv",
      name: "Cadastro de grupos de Produtos e Serviços",
      components: { default: CadGruposProdServ }
    },
    {
      path: "/cadastros/cad-profissionais",
      name: "Cadastro de Profissionais",
      components: { default: CadProfissionais }
    },
    {
      path: "/cadastros/cad-produtos-servicos",
      name: "Cadastro de Produtos e Serviços",
      components: { default: CadProdutosServicos }
    },
    {
      path: "/cadastros/cad-sangria",
      name: "Lançamento de Sangrias",
      components: { default: CadSangrias }
    },
    {
      path: "/cadastros/cad-vale",
      name: "Lançamento de Vales",
      components: { default: CadVales }
    },
    {
      path: "/cadastros/cad-pacote",
      name: "Cadastros de Pacotes",
      components: { default: CadPacotes }
    },
    {
      path: "/cadastros/cad-mensagens",
      name: "Cadastro de Mensagens",
      components: { default: CadMensagens }
    },
    {
      path: "/cadastros/cad-parametros",
      name: "Cadastro de Parametros",
      components: { default: CadParametros }
    },
    {
      path: "/cadastros/cad-categorias-cliente",
      name: "Cadastro Categorias de Cliente",
      components: { default:  CadCategoriasCliente}
    }
  ]
};


let financeiro = {
  path: "/",
  component: DashboardLayout,

  children: [
    {
      path: "/financeiro/contas-a-receber",
      name: "Contas a Receber",
      components: { default: ContasReceber }
    },
    {
      path: "/financeiro/contas-a-pagar",
      name: "Contas a Pagar",
      components: { default: ContasPagar }
    }
  ]
};


let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login
    }
    // {
    //   path: "/register",
    //   name: "Register",
    //   component: Register
    // },
    // {
    //   path: "/pricing",
    //   name: "Pricing",
    //   component: Pricing
    // },
    // {
    //   path: "/lock",
    //   name: "Lock",
    //   component: Lock
    // }
  ]
};

const routes = [
  // {
  //   path: "/",
  //   redirect: "/login",
  //   name: "Login"
  // },
  // componentsMenu,
  // formsMenu,
  // tablesMenu,
  //mapsMenu,
  // pagesMenu,
  cadastros,
  financeiro,
  authPages,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard},
        beforeEnter: (to, from, next) => {
          GuardRoute.verificarUsuarioLogado(to, from, next);
        }
      },
      {
        path: "dash-financeiro",
        name: "Dashboard Financeiro",
        components: { default: DashFinanceiro }
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Agenda },
        beforeEnter: (to, from, next) => {
          GuardRoute.verificarUsuarioLogado(to, from, next);
        }
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts }
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets }
      }
    ]
  }
];

export default routes;
