<template>
    <transition name="modal">
      <!-- <loading
        :active.sync="load"
        :can-cancel="true"
        :is-full-page="fullpage"
      ></loading>-->
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class>
              <div class>
                <md-card>
                  <md-card-header
                    class="md-tabs-navigation md-card-header-warning"
                  >
                    <h4 class="title" style="color:white">
                      Cadastro de Categorias Clientes
                      <strong>{{
                        item.id > 0
                          ? " - Código: " + item.id
                          : ""
                      }}</strong>
                    </h4>
                  </md-card-header>
                  <md-card-content>
                    <div class="md-layout">
                      <div class="md-layout-item md-size-100">
                        <md-field>
                          <label>Nome Categoria</label>
                          <md-input
                            v-model="item.nome_categoria"
                          ></md-input>
                        </md-field>
                      </div>
                    </div>
                  </md-card-content>
  
                  <div class="footer mx-auto">
                    <md-button
                      class="md-success md-sm md-round"
                      @click="salvaRegistro"
                      >Salvar</md-button
                    >&nbsp;
                    <md-button
                      class="md-danger md-sm md-round"
                      @click="closeModal(0, 0)"
                      >Cancelar</md-button
                    >
                  </div>
                  <br />
                </md-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import Swal from "sweetalert2";
  import api from "@/routes/api";
  export default {    
    name: "modal",
    data() {
      // return {
      //   valor: 0.0,
      //   money: {
      //     decimal: ",",
      //     thousands: ".",
      //     prefix: "R$ ",
      //     suffix: "",
      //     precision: 2,
      //     masked: false /* doesn't work with directive */
      //   }
      // };
    },
    methods: {
      closeModal: function(pagina = 0, atualiza = 0) {
        this.$emit("closeModal", pagina, atualiza);
      },
      salvaRegistro() {
        if (this.item.nome_categoria.length <= 4) {
          this.$notify({
            timeout: 2500,
            message: "O nome do parametro deve conter ao menos 5 caracteres!!!",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger"
          });
          return;
        }
  
        if (this.item.valor <= 0) {
          this.$notify({
            timeout: 2500,
            message: "Valor inválido!!!",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger"
          });
          return;
        }
  
        //SE FOR INCLUSÃO
        if (this.item.id == 0) {
          this.$emit("load", true);
          api
            .put('categorias-cliente/incluir-categoria-cliente', this.item, {
              headers: {
                "Content-Type": "application/json"
              }
            })
            .then(resp => {
              this.$emit("load", false);
              this.closeModal(1, 1);
            })
            .catch(error => {
              this.$emit("load", false);
              alert(error);
            });
        } else {
          
          this.$emit("load", true);
          api
            .post(`categorias-cliente/alterar-categoria-cliente`, this.item, {
              headers: {
                "Content-Type": "application/json"
              }
            })
            .then(resp => {
              this.$emit("load", false);
              this.closeModal(0);
            })
            .catch(error => {
              //alert(error);
              this.$emit("load", false);
            });
        }
      }
    },
    props: ["item", "termo", "load"]
  };
  </script>
  
  <style lang="scss">
  .modal-enter {
    opacity: 0;
  }
  
  .modal-leave-active {
    opacity: 10;
  }
  
  .modal-container {
    max-width: 800px;
  }
  
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  </style>
  