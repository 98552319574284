<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive },
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          :link="{ name: 'Dashboards', icon: 'dashboard', path: '/dashboard' }"
        >
          <sidebar-item :link="{ name: 'Atendimento', path: '/dashboard' }">
          </sidebar-item>
          <sidebar-item
            :link="{ name: 'Financeiro', path: '/dash-financeiro' }"
          >
          </sidebar-item>
          <sidebar-item :link="{ name: 'Estoque', path: '#' }"> </sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{ name: 'Agenda', icon: 'date_range', path: '/calendar' }"
        >
        </sidebar-item>

        <!-- CADASTROS -->
        <sidebar-item :link="{ name: 'Cadastros', icon: 'pages' }">         
          <sidebar-item
            :link="{ name: 'Cidades', path: '/cadastros/cad-cidades' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Categorias Prod./Serv.',
              path: '/cadastros/cad-grupos-prodserv',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Produtos e Serviços',
              path: '/cadastros/cad-produtos-servicos',
            }"
          />
          <sidebar-item
            :link="{ name: 'Custos Fixos', path: '/cadastros/cad-custo-fixo' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Profissionais',
              path: '/cadastros/cad-profissionais',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Fornecedores',
              path: '/cadastros/cad-fornecedores',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Funcionários',
              path: '/cadastros/cad-funcionarios',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Clientes', path: '/cadastros/cad-clientes' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Sangrias',
              path: '/cadastros/cad-sangria',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Vales',
              path: '/cadastros/cad-vale',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Pacotes',
              path: '/cadastros/cad-pacote',
            }"
          ></sidebar-item>          
          
          <sidebar-item
            :link="{
              name: 'Categorias Cliente',
              path: '/cadastros/cad-categorias-cliente',
            }"
          ></sidebar-item>
        </sidebar-item>

        <!-- MOVIMENTAÇÃO DE ESTOQUE -->
        <sidebar-item :link="{ name: 'Mov. Estoque', icon: 'apps' }">
          <sidebar-item
            :link="{ name: 'Pedidos de Compra', path: '#' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Transf. Produtos', path: '#' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Inventário', path: '#' }"
          ></sidebar-item>
        </sidebar-item>

        <!-- FINANCEIRO -->
        <sidebar-item :link="{ name: 'Financeiro', icon: 'content_paste' }">
          <sidebar-item
            :link="{ name: 'Contas a Pagar', path: '/financeiro/contas-a-pagar' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Contas a Receber',
              path: '/financeiro/contas-a-receber',
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Despesas Permanentes', path: '#?dp' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Plano de Contas', path: '#?pc' }"
          ></sidebar-item>
        </sidebar-item>

        <!-- CAIXA -->
        <sidebar-item :link="{ name: 'Caixa', icon: 'point_of_sale' }">
          <sidebar-item
            :link="{ name: 'Abertura de Caixa', path: '#' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Fechamento de Caixa', path: '#' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Frente de Caixa', path: '#' }"
          ></sidebar-item>
          <sidebar-item :link="{ name: 'Comandas', path: '#' }"></sidebar-item>
        </sidebar-item>

        <!-- RELATÓRIOS -->
        <sidebar-item :link="{ name: 'Relatórios', icon: 'place' }">
          <sidebar-item
            :link="{ name: 'Financeiros', path: '#' }"
          ></sidebar-item>
          <sidebar-item :link="{ name: 'Estoque', path: '#' }"></sidebar-item>
          <sidebar-item :link="{ name: '...', path: '#' }"></sidebar-item>
        </sidebar-item>

        <!-- MENSAGERIA -->
        <sidebar-item :link="{ name: 'Campanhas', icon: 'message' }">
          <sidebar-item
            :link="{ name: 'Whatsapp', icon: 'arrow_right_alt', path: '/cadastros/cad-mensagens' }"
          ></sidebar-item>
          
        </sidebar-item>

        <!-- CONFIGURAÇÕES -->
        <sidebar-item
          :link="{ name: 'Configurações', icon: 'settings', path: '/cadastros/cad-parametros' }"
        ></sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin
        :color.sync="sidebarBackground"
        :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini"
        :sidebarImg.sync="sidebarImg"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import FixedPlugin from "../../FixedPlugin.vue";
import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    FixedPlugin,
    MobileMenu,
    UserMenu,
    ZoomCenterTransition,
  },
  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage: "./img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    reinitScrollbar();
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
