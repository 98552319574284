<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header class="md-tabs-navigation md-card-header-warning">
                  <h4 class="title" style="color:white">
                    Cadastro de Produtos e Serviços
                    <strong v-if="produtoServico !== null">{{
                    produtoServico.id_servico_produto > 0
                    ? " - Código: " + produtoServico.id_servico_produto
                    : ""
                    }}</strong>
                  </h4>

                </md-card-header>
                <md-card-content>
                  <div class="md-layout">

                    <div v-if="produtoServico.tipo !== null" class="md-layout-item md-size-15">
                      <ejs-dropdownlist floatLabelType="Auto" id='dropdownlist' v-model="ProdServSel"
                        :dataSource='tipoProdServ' :fields='fieldsPS' :select='selectTipo' placeholder='Tipo'>
                      </ejs-dropdownlist>
                    </div>

                    <div class="md-layout-item md-size-40">
                      <md-field>
                        <label>Descrição</label>
                        <md-input v-model="produtoServico.descricao"></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-30">
                      <ejs-autocomplete v-model="grupoProdServ" floatLabelType="Auto"
                        :select='selectProdServAutocomplete' :dataSource='categorias' :fields="{text: 'descricao'}"
                        placeholder='Selecione a Categoria'></ejs-autocomplete>
                    </div>
                    <div class="md-layout-item md-size-15">




                      <ejs-numerictextbox style="text-align:left;font-clor:red" placeholder='Valor de Venda..'
                        :showSpinButton='false' floatLabelType="Auto" v-model="produtoServico.valor">
                      </ejs-numerictextbox>
                    </div>





                    <div v-show="produtoServico.tipo == 'P'" class="md-layout-item md-size-20">
                      <md-field>
                        <label for="tppessoa">Embalagem</label>
                        <md-select v-model="produtoServico.embalagem" name="embalagem" id="embalagem">
                          <md-option value="FRASCO">FRASCO</md-option>
                          <md-option value="TUBO">TUBO</md-option>
                          <md-option value="AMPOLA">AMPOLA</md-option>
                          <md-option value="SPRAY">SPRAY</md-option>
                          <md-option value="POTE">POTE</md-option>
                          <md-option value="UNIDADE">UNIDADE</md-option>
                          <md-option value="KIT">KIT</md-option>
                        </md-select>
                      </md-field>
                    </div>

                    <div v-show="produtoServico.tipo == 'P'" class="md-layout-item md-size-15">
                      <md-field>
                        <label>Qtde.Embalagem</label>
                        <md-input v-model="produtoServico.qtd_emb"></md-input>
                      </md-field>
                    </div>

                    <div v-show="produtoServico.tipo == 'P'" class="md-layout-item md-size-15">
                      <md-field>
                        <label for="tppessoa">Unidade</label>
                        <md-select v-model="produtoServico.unidade" name="unidade" id="unidade">
                          <md-option value="Litro">Litro</md-option>
                          <md-option value="Mililitro">Mililitro</md-option>
                          <md-option value="Kilo">Kilo</md-option>
                          <md-option value="Grama">Grama</md-option>
                        </md-select>
                      </md-field>
                    </div>

                    <div v-show="produtoServico.tipo == 'P'" class="md-layout-item md-size-35">
                      <md-field>
                        <label>Marca</label>
                        <md-input v-model="produtoServico.marca"
                          @input="produtoServico.marca = produtoServico.marca.toUpperCase()"></md-input>
                      </md-field>
                    </div>

                    <div v-show="produtoServico.tipo == 'P'" class="md-layout-item md-size-15">
                      <md-field>
                        <label for="consumoint">Consumo Interno?</label>
                        <md-select v-model="produtoServico.consumo_interno" :value="0" name="consumoint"
                          id="consumoint">
                          <md-option value="true">SIM</md-option>
                          <md-option value="false">NÃO</md-option>
                        </md-select>
                      </md-field>
                    </div>



                    <div v-show="produtoServico.tipo == 'P'" class="md-layout-item md-size-30">
                      <md-field>
                        <label>Código Barras</label>
                        <md-input v-model="produtoServico.cod_barras" type="numeric"></md-input>
                      </md-field>
                    </div>


                    <div v-show="produtoServico.tipo == 'P'" class="md-layout-item md-size-20">
                      <md-field>
                        <label>Estoque Mínimo</label>
                        <md-input v-model="produtoServico.estoque_minimo" type="numeric"></md-input>
                      </md-field>
                    </div>

                    <div v-show="produtoServico.tipo == 'P'" class="md-layout-item md-size-50" />

                    <div v-show="produtoServico.tipo == 'S'" class="md-layout-item md-size-20">
                      <md-field>
                        <label>Duração</label>
                        <md-input v-model="produtoServico.tempo" :value="0"></md-input>
                      </md-field>
                    </div>

                    <div v-show="produtoServico.tipo == 'S'" class="md-layout-item md-size-20">
                      <md-field>
                        <label>Retorno (dias)</label>
                        <md-input v-model="produtoServico.retorno" type="numeric"></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-60">
                    </div>

                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Informações Complementares</label>
                        <md-input v-model="produtoServico.info_complementar"
                          @input="produtoServico.info_complementar = produtoServico.info_complementar.toUpperCase()">
                        </md-input>
                      </md-field>
                    </div>

                  </div>
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button class="md-success md-sm md-round" @click="salvaRegistro">Salvar</md-button>&nbsp;
                  <md-button class="md-danger md-sm md-round" @click="closeModal(0, 0)">Cancelar</md-button>
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from "vue";
import { AutoCompletePlugin } from '@syncfusion/ej2-vue-dropdowns';
import VueNumeric from "vue-numeric";
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { DropDownListPlugin  } from '@syncfusion/ej2-vue-inputs';
import api from "@/routes/api"

Vue.use(NumericTextBoxPlugin, DropDownListPlugin);


export default {
  //components: { VueNumeric },
  name: "modal",
  data() {
    return {
      valor: 0.0,
      dtNasc: "",
      dtContrato: "",
      lstMunicipios: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      produtoServico: {
        id_servico_produto: 0,
        descricao: "",
        marca: "",
        valor: 0,
        tempo: "000000",
        retorno: 0,
        grupo: "",
        subgrupo: "",
        cod_barras: "",
        embalagem: "",
        qtd_emb: 0,
        unidade: "",
        cmv: 0,
        cmv_porcentagem: 0,
        icms: 0,
        icms_porcentagem: 0,
        custo_fixo: 0,
        custo_fixo_porcentagem: 0,
        comissao: 0,
        comissao_porcentagem: 0,
        lucro: 0,
        lucro_porcentagem: 0,
        tipo: "P",
        id_grupo: "0",
        consumo_interno: false,
        estoque_minimo: 0,
        info_complementar: "",
        qtde_estoque: 0
      },
      categorias: [],
      grupoProdServ: "",
      tipoProdServ: [
        { id: 'P', tipo: 'Produto' },
        { id: 'S', tipo: 'Serviço' }
      ],
      fieldsPS: { text: 'tipo', value: 'id'},
      ProdServSel: 'P'
    };
  },
  mounted() {
    this.carregaProdutoServico(this.idProdServico)
    // this.ProdServSel = this.produtoServico.tipo
    this.carregaCategorias('', this.ProdServSel)
  },
  // directives: { money: VMoney },
  methods: {
    closeModal: function(pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, 1);
    },
    carregaProdutoServico: async function(idProdServ) {
       await api.get("servicos-produtos/prod-serv-por-id/" + idProdServ).then(resp => {                       
        this.carregaCategorias('', resp.data.Data.tipo)
        this.ProdServSel = resp.data.Data.tipo  
        this.produtoServico = resp.data.Data;  

      });
    },
    carregaCategorias: function(descricao = '', vrtipo = 'P') {
      
      api.get(`categorias/lista-categorias/1/9999/${vrtipo}?descricao=${descricao}`).then(resp => {
        this.categorias = resp.data.Data;   
        if(this.produtoServico !== null) {
          this.grupoProdServ = this.categorias.filter(c => c.id_grupo === this.produtoServico.id_grupo)[0].descricao;     
        }
        
      });
    },
    selectProdServAutocomplete(args) {
      this.produtoServico.id_grupo = args.itemData.id_grupo      
    },
    selectTipo(args) {
      this.produtoServico.tipo = args.itemData.id;
      this.carregaCategorias('', args.itemData.id);
      this.grupoProdServ = ""
    },
    salvaRegistro() {

      if (this.produtoServico.tempo == null) {
        this.produtoServico.tempo = ""
      }    
      
      if (this.produtoServico.retorno == null) {
        this.produtoServico.retorno = ""
      } 

      if (this.produtoServico.retorno == null) {
        this.produtoServico.retorno = ""
      }

      if (this.produtoServico.descricao.length <= 3) {
        this.$notify({
          timeout: 2500,
          message: "Nome do Produto/Serviço inválido!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        return;
      }

      //SE FOR INCLUSÃO
      if (this.produtoServico.id_servico_produto == 0) {
        this.$emit("load", true);
        let urlApi = "servicos-produtos/incluir-servicos-produtos";
        api
          .put(urlApi, this.produtoServico, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.$emit("load", false);
            this.closeModal(1, 1);
          })
          .catch(error => {
            this.$emit("load", false);
            alert(error);
          });
      } else {
        this.$emit("load", true);
        let urlApi = "servicos-produtos/alterar-servicos-produtos";
        api
          .post(urlApi, this.produtoServico, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.$emit("load", false);
            this.closeModal(0);
          })
          .catch(error => {
            //alert(error);
            this.$emit("load", false);
          });
      }
    }
  },
  props: ["idProdServico", "pagina", "termo", "perPage", "load"]
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 10;
}

.modal-container {
  max-width: 900px;
}

.md-menu-content {
  z-index: 99999 !important;
}

.my-input-upper input {
  text-transform: uppercase;
}

.my-input-lower input {
  text-transform: lowercase;
}
</style>
