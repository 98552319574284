<template>
    <div>
        <md-card full>
            <md-card-header class="md-tabs-navigation md-card-header-warning">
                <h4 class="title" style="color:white">Agendamento</h4> {{data}}
            </md-card-header>
            <md-card-content>
                <div class="md-layout">
                
                <div class="md-layout-item md-size-50">
                    <div class="e-float-input">
                        <ejs-dropdownlist id='cbCliente' 
                        name="Cliente" class="e-field" 
                        placeholder= 'Selecione o Cliente' 
                        floatLabelType= "Auto" 
                        :dataSource="clientesCombo"
                        :fields="{text: 'nome', value: 'id_cliente'}"
                        v-model="agendamento.idCliente"
                        ></ejs-dropdownlist>
                    </div>
                </div>

                <div class="md-layout-item md-size-50">
                    <div class="e-float-input">
                        <ejs-dropdownlist 
                        id='cbProfissional'
                        v-model="agendamento.idFuncionario"                         
                        name="Profissional" class="e-field" 
                        placeholder= 'Selecione o Profissional' 
                        floatLabelType= "Auto" 
                        :dataSource='profissionaisCombo'
                        :fields="{text: 'nome', value: 'id_funcionario'}"
                        @change="CarregaServicosCombo(agendamento.idFuncionario)"
                        :showClearButton='true' 
                        ></ejs-dropdownlist>
                    </div>
                </div>
                
                <div class="md-layout-item md-size-50">
                    <div class="e-float-input">
                        <ejs-dropdownlist id='Servico' 
                        name="Servico;" 
                        v-model="agendamento.idServico" 
                        class="e-field " 
                        placeholder= 'Serviço' 
                        :dataSource='servicosCombo' 
                        :fields="{text: 'descricao', value: 'idServicoProduto'}"
                        floatLabelType= "Auto"
                        @change="CarregaProfissionaisCombo(agendamento.idServico)"
                        :showClearButton='true' 
                        ></ejs-dropdownlist>
                        <span class="e-float-line"></span>
                    </div>
                </div>   

                <div class="md-layout-item md-size-25">
                    <div class="e-float-input">
                        <ejs-datetimepicker id="dtIniServico" v-model="agendamento.dtInicioServico" :format="'dd/MM/yyyy hh:mm'" :placeholder="'Início do Serviço'" floatLabelType= "Auto"></ejs-datetimepicker>
                    </div>    
                </div>       

                <div class="md-layout-item md-size-25">
                    <div class="e-float-input">
                        <ejs-datetimepicker id="dtFimServico" v-model="agendamento.dtTerminoServico" :format="'dd/MM/yyyy hh:mm'" :placeholder="'Término do Serviço'" floatLabelType= "Auto"></ejs-datetimepicker>
                    </div>    
                </div>  

                <div class="md-layout-item md-size-100">
                    <md-field class="md-size-100">
                    <label>Observações</label>
                    <md-input
                        maxlength="255"
                        md-counter="false"
                    ></md-input>
                    </md-field>
                </div>

               
                </div>
            </md-card-content>

            <div class="footer mx-auto">
                <md-button class="md-success md-sm md-round" @click="fechar">Salvar</md-button>&nbsp;
                <md-button class="md-danger md-sm md-round" @click="fechar">Cancelar</md-button>
            </div>
            <br />
        </md-card>


    </div>
</template>

<script lang='ts'>
import Vue from "vue";
import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import { AutoCompleteComponent, AutoCompletePlugin } from '@syncfusion/ej2-vue-dropdowns';
import { Dialog } from '@syncfusion/ej2-popups';
import { extend } from '@syncfusion/ej2-base';
import Swal from "sweetalert2";
import axios from "axios";

import {
  SchedulePlugin
} from "@syncfusion/ej2-vue-schedule";

Vue.use(DropDownListPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(AutoCompletePlugin);

Vue.use(SchedulePlugin);

    export default {
        data() {
            return {
                servicosCombo:  [],
                clientesCombo: [],
                profissionaisCombo: [],
                load: false,
                agendamento: {
                    idCliente: 0,
                    idFuncionario: 2,
                    idServico: 0,
                    nomeCliente: "",
                    dtInicioServico: new Date(),
                    dtTerminoServico: new Date(),
                    diaInteiro: false,
                    sala: 0,
                    obs: ""
                },
                

                data: {}
            }
        },
        methods: {
            fechar () {
                let scheduleObj = document.getElementById('Schedule').ej2_instances[0];
                scheduleObj.closeEditor();
                // moment(String(agendamento.dtInicioServico)).format('MM/DD/YYYY hh:mm')
            },
            OnSelect (args) {
                alert(args);

            },
            CarregaServicosCombo(prProfissional = 0) {
                this.load = true;

                if(prProfissional == null) {
                    prProfissional = 0
                }

                axios
                    .get(
                    `https://api.salaoday.com.br/api/servicos/lista-servicos-combo/s/${prProfissional}`
                    )
                    .then(resp => {
                        this.servicosCombo = resp.data;
                        this.load = false;
                    })
                    .catch(error => {
                        this.load = false;
                    });
            },
            CarregaClientesCombo() {
                this.load = true;
                axios
                    .get(
                    "https://api.salaoday.com.br/api/clientes/lista-clientes-combo"
                    )
                    .then(resp => {
                        this.clientesCombo = resp.data;
                        this.load = false;
                    })
                    .catch(error => {
                        this.load = false;
                    });
            },
            CarregaProfissionaisCombo(prServico = 0) {
                this.load = true;

                if(prServico == null) {
                    prServico = 0
                }

                axios
                    .get(
                    `https://api.salaoday.com.br/api/profissionais/lista-profissionais-combo/${prServico}`
                    )
                    .then(resp => {
                        this.profissionaisCombo = resp.data;                        
                        this.load = false;
                    })
                    .catch(error => {
                        this.load = false;
                    });
            },
        },
        mounted() {
            this.CarregaServicosCombo()
            this.CarregaClientesCombo()
            this.CarregaProfissionaisCombo()

            this.agendamento.dtInicioServico = this.data.StartTime
            this.agendamento.dtTerminoServico = this.data.EndTime
            //this.agendamento.idFuncionario = this.data.OwnerId
        }
    }

</script>

<style>
div#Schedule_dialog_wrapper { 
  width: 900px !important; 
} 
</style>