<template>
  <transition  name="modal">
    
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header
                  class="md-card-header-warning"
                >
                
                  <h4 class="title" style="color:white">
                    Comanda {{ Comanda.ID_Comanda }} - ( {{Comanda.StatusComanda}} ) - {{ Comanda.nome }}
                  </h4>                  
                </md-card-header>
                
                <md-card-content>
                <div>
                  <div class="md-layout">

                      <div class="md-layout-item form-v md-size-100">
                        <div class="e-float-input">
                          <ejs-autocomplete 
                          v-model="itemVmodelCiente"
                          floatLabelType="Auto" 
                          :select='recebeValorAutoCliente' 
                          :autofill='autofill' 
                          :dataSource='clientesCombo' 
                          :fields="{text: 'AutoComplete', value: 'AutoComplete'}" 
                          placeholder='Selecione o Cliente'></ejs-autocomplete>
                        </div>
                      </div>

                      <div class="md-layout-item form-v md-size-28">                  
                          <div class="e-float-input">
                            <ejs-autocomplete 
                            v-model="itemVmodelServico"
                            floatLabelType="Auto" 
                            :select='recebeValorAutoServico'                             
                            :dataSource='servicosCombo' 
                            :fields="{text: 'descricao', value: 'descricao'}" 
                            placeholder='Adicionar Produto/Serviço'></ejs-autocomplete>
                            <span class="e-float-line"></span>
                          </div>
                        </div>

                        <div class="md-layout-item form-v md-size-27">                  
                          <div class="e-float-input">
                            <ejs-autocomplete 
                            v-model="itemVmodelProfissional"
                            floatLabelType="Auto" 
                            :select='recebeValorAutoProfissional'                             
                            :dataSource='profissionaisCombo' 
                            :fields="{text: 'nome', value: 'nome'}" 
                            placeholder='Profissional'></ejs-autocomplete>
                            <span class="e-float-line"></span>
                          </div> 
                        </div>

                        <div class="md-layout-item form-v md-size-5">    
                          <div class="e-float-input"><ejs-textbox style="text-align:center" cssClass='text-center' v-model="itemComandaForm.Quantidade" floatLabelType="Auto" placeholder="Qtde."></ejs-textbox></div>              
                        </div>

                        <div class="md-layout-item form-v md-size-10">    
                          <div class="e-float-input">                            
                            <ejs-numerictextbox style="text-align:center" v-model="itemComandaForm.Valor_Unitario" cssClass='text-center' placeholder='Valor Unit.' :showSpinButton='false' floatLabelType="Auto"></ejs-numerictextbox>   
                          </div>              
                        </div>

                        <div class="md-layout-item form-v md-size-10">    
                          <div class="e-float-input"><ejs-textbox style="text-align:center" cssClass='text-center' :value="itemComandaForm.Valor_Unitario * itemComandaForm.Quantidade | currency" floatLabelType="Auto" disabled placeholder="Sub-Total"></ejs-textbox></div>              
                        </div>
                        
                        <div class="md-layout-item form-v md-size-5">    
                          <md-button class="md-button md-sm md-just-icon md-theme-default md-primary"
                            :disabled = "Comanda.StatusComanda != 'Aberta'"
                            @click="itemComandaForm.ID_Item_Comanda == 0 ? AdicionaItem() : EditarItemComanda()">
                            <md-icon>{{ itemComandaForm.ID_Item_Comanda == 0 ? 'add' : 'done' }}</md-icon> {{ itemComandaForm.itemComnadaForm }}
                          </md-button>
                        </div>

                        <div v-if="itemComandaForm.ID_Item_Comanda > 0" class="md-layout-item form-v md-size-5">    
                          <md-button class="md-button md-sm md-just-icon md-theme-default md-danger"
                            :disabled = "Comanda.StatusComanda != 'Aberta'"
                            @click="cancelaEdicao">
                            <md-icon>close</md-icon> 
                          </md-button>
                        </div>

                    <div class="md-size-50">
                      
                    </div>

                    <div v-if="itensComanda.length > 0" class="md-layout-item md-size-100">
                      <div>
                        <md-table v-model="itensComanda" table-header-color="orange" class="vs-table">
                          <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Item">{{ item.ID_Item_Comanda }}</md-table-cell>
                            <md-table-cell md-label="Descrição">({{item.ID_SERVICO_PRODUTO}}) - {{ item.descricao }}</md-table-cell>
                            <md-table-cell md-label="Profissional / Cliente">{{ item.nome }} <br> {{ item.Nome_Cliente }}</md-table-cell>
                            <md-table-cell md-label="Qtde.">{{ item.Quantidade }}</md-table-cell>
                            <md-table-cell md-label="Valor Unit.">{{ item.Valor_Unitario | currency}}</md-table-cell>
                            <md-table-cell md-label="SubTotal">{{ item.SubTotal | currency}}</md-table-cell>
                            <md-table-cell class="text-right" md-label="">
                              <div class="button-container">
                                
                                <md-button v-if="item.tipo=='S'"
                                  :disabled = "Comanda.StatusComanda != 'Aberta'"
                                  
                                  class="md-button md-just-icon md-theme-default md-primary"
                                  @click="abreModalProdutosServico(item)">
                                  <md-icon >post_add</md-icon>
                                </md-button>
                                <div style="padding: 0px 1px"> </div>
                                <md-button class="md-button md-just-icon md-theme-default md-warning"
                                  :disabled = "Comanda.StatusComanda != 'Aberta'"
                                  @click.native="SelecionaItem(item)">
                                  <md-icon>edit</md-icon>
                                </md-button>
                                <div style="padding: 0px 1px"> </div>
                                <md-button class="md-button md-just-icon md-theme-default md-danger"
                                  :disabled = "Comanda.StatusComanda != 'Aberta'"
                                  @click.native="ExcluirItemComanda(item.ID_Item_Comanda)">
                                  <md-icon>close</md-icon>
                                </md-button>
                              </div>
                          </md-table-cell>

                          </md-table-row>
                        </md-table>
                        
                      </div>
                    </div>
                  </div>

                  <hr>
                  <div style="padding: 0 30px 0 0px" class="text-right">
                    
                    <span style="padding: 0 50px 0 0">*** Total Serviços: <strong>{{ ValorTotalServico | currency }}</strong></span>
                    <span style="padding: 0 50px 0 0">*** Total Produtos: <strong>{{ ValorTotalProdutos | currency }}</strong></span>
                    
                    <span style="padding: 0 10px 0 0">*** Total Comanda:</span> &nbsp;  <strong>{{ ValorTotalComanda | currency }}</strong>
                  </div>
                  
                  
                </div>

                <!-- <div class="md-layout"> 

                  <div class="md-layout-item md-size-35">
                    <ejs-dropdownlist id='dropdownlist' floatLabelType='Auto' v-model="formaPagamento" :dataSource='formasPagamento' placeholder='Selecione a Forma de Pagamento'></ejs-dropdownlist>   
                  </div>

                  <div v-if="formaPagamento == 'Dinheiro'" class="md-layout-item md-size-20">   
                    <ejs-numerictextbox placeholder='Valor recebido' :showSpinButton='false' floatLabelType="Auto" v-model="valorRecebido" v-bind:value='valorRecebido'></ejs-numerictextbox>   
                  </div>
                  
                  <div v-if="formaPagamento == 'Dinheiro'" class="md-layout-item md-size-20">   
                    <ejs-numerictextbox  style="text-align:center;font-size:19px;font-clor:red" cssClass='text-center' placeholder='*** Troco ***' :showSpinButton='false' :readonly='true' floatLabelType="Auto" v-bind:value='ValorTroco'></ejs-numerictextbox>   
                  </div>
                 
                </div> -->
 
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button
                    v-if="Comanda.StatusComanda == 'Aberta'"
                    @click="recebimento()"
                    class="md-success md-sm ">Finalizar Comanda
                  </md-button>
                  
                  <md-button
                    class="md-danger md-sm "
                     @click="closeModal()">Voltar</md-button
                  >
                </div>

                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>



  </transition>
</template>

<script>
import Vue from "vue";
import Swal from "sweetalert2";
import { TextBoxPlugin, DropDownListPlugin  } from '@syncfusion/ej2-vue-inputs';
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import api from "@/routes/api";
import { ComboBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';

Vue.use(ComboBoxPlugin);
Vue.use(NumericTextBoxPlugin);
Vue.use( TextBoxPlugin, DropDownListPlugin  );

export default {
  name: "modal",
  data() {
    return {
      fechandoComanda: false,
      informarRecebimento: false,
      itemVmodelCiente: null,
      idClienteComanda: null,
      clientesCombo: [],
      value: 10,
      valorRecebido: 0,
      servicosCombo: [],
      formaPagamento: 0,
      motivoCancelamento: '',
      StatusAgenda: ['Cancelado', 'Faltou', 'Desmarcado'],
      formasPagamento: ['Dinheiro', 'Cartão de Crédito', 'Cartão de Débito', 'Cheque', 'PIX'],
      itensComanda: [],
      itemVmodelServico: null,
      itemVmodelProfissional: null,
      profissionaisCombo: [],
      itemComandaForm: {
        ID_Item_Comanda: 0,
        ID_Comanda: 0,
        ID_SERVICO_PRODUTO: 0,
        Quantidade: 0,
        Valor_Unitario: 0,
        Data_Retorno: "",
        Status_Servico: "",
        ID_Funcionario: 0,
        ID_Agendamento: 0,
        SubTotal: 0
      },
      load: false,
      valor: 0.0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },
  // directives: { money: VMoney },
  methods: {
    closeModal: function(atualizaTela = false) {
      
      if(this.Comanda.Valor_Total_Comanda != this.ValorTotalComanda) {
        this.$emit("closeModal", true);
      } else {
        this.$emit("closeModal", atualizaTela);
      }
      
    },
    alteraStatusAgenda(args) {
      
    },
    CarregaClientesCombo: async function () {
      
      this.load = true;
      api
        .get("clientes/lista-clientes-combo")
        .then((resp) => {
          this.clientesCombo = resp.data;          
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
    },
    recebimento: function() {
      
      api
        .get(
          `comanda/pendencias/${this.Comanda.ID_Comanda}`
        )
        .then(resp => {
          if (resp.data.qtde > 0) {

             this.$notify({
              timeout: 2500,
              message: "Existem serviços não resolvidos para essa comanda!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger"
            });
            return

          } else {
            let vrComanda = this.Comanda;
            vrComanda.Valor_Total_Comanda = this.ValorTotalComanda;
            this.$emit("recebimento", vrComanda);
            
          }
          this.load = false;
        })
        .catch(error => {
          this.load = false;
        });


      
      
    },
    abreModalProdutosServico: function(itemComanda) {
      this.$emit("abreModalProdutosServico", itemComanda);
    },
    recebeValorAutoCliente (args) {
      this.idClienteComanda = args.itemData.id_cliente
    },
    CarregaItensComanda() {
      api
        .get(
          `comanda/lista_itens/${this.Comanda.ID_Comanda}`
        )
        .then(resp => {
          this.itensComanda = (resp.data == "{}" ? [] : resp.data);                 
          this.load = false;
        })
        .catch(error => {
          this.load = false;
        });
    },
    CarregaComboServicosProdutos(args) {
      this.load = true;
      api
      .get(
        `servicos/lista-servicos-combo/t/0`
      )
      .then((resp) => {
        this.servicosCombo = resp.data;
        this.itemVmodelServico = this.servicosCombo.filter(c => c.idServicoProduto === this.dataEditor.ID_Servico)[0].descricao
        this.load = false;
      })
      .catch((error) => {
        this.load = false;
      });
        
    },
    recebeValorAutoServico (args) {
            
      this.itemComandaForm.ID_SERVICO_PRODUTO = args.itemData.idServicoProduto
      this.itemComandaForm.Valor_Unitario = args.itemData.valor
      this.itemComandaForm.Quantidade = 1

    },
    recebeValorAutoProfissional (args) { 
      this.itemComandaForm.ID_Funcionario = args.itemData.id_funcionario
    },
    AdicionaItem() {      
      if(this.itemComandaForm.ID_SERVICO_PRODUTO == 0) {
        this.$notify({
          timeout: 2500,
          message: "Produto inválido!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        return
      }

      if(this.itemComandaForm.Quantidade <= 0) {
        this.$notify({
          timeout: 2500,
          message: "Quantidade inválido!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        return
      }

      this.itemComandaForm.ID_Comanda = this.Comanda.ID_Comanda;
      this.itemComandaForm.SubTotal = this.itemComandaForm.Quantidade * this.itemComandaForm.Valor_Unitario;
      this.itemComandaForm.Status_Servico = "Finalizado"
      this.itemComandaForm.ID_Cliente = this.idClienteComanda

        let urlApi = "comanda/incluir-item";
        api
          .post(urlApi, this.itemComandaForm, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.CarregaItensComanda();
          })
          .catch(error => {
            this.$notify({
            timeout: 2500,
            message: error,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger"
          });
          });


    },
    cancelaEdicao() {
        this.itemComandaForm.ID_Item_Comanda = 0
        this.itemComandaForm.Valor_Unitario = null
        this.itemComandaForm.Quantidade = null
        this.itemVmodelServico = null
        this.itemVmodelProfissional = null
        this.itemComandaForm.SubTotal = null
    },
    SelecionaItem: function(Item) {

      

      this.itemComandaForm.ID_Item_Comanda = Item.ID_Item_Comanda
      this.itemComandaForm.ID_Comanda = this.Comanda.ID_Comanda
      this.itemComandaForm.ID_SERVICO_PRODUTO = Item.ID_SERVICO_PRODUTO
      this.itemComandaForm.Quantidade = Item.Quantidade
      this.itemComandaForm.Valor_Unitario = Item.Valor_Unitario
      this.itemComandaForm.Data_Retorno = Item.Data_Retorno
      this.itemComandaForm.Status_Servico = Item.Status_Servico
      this.itemComandaForm.ID_Funcionario = Item.ID_Funcionario
      this.itemComandaForm.ID_Agendamento = Item.ID_Agendamento
      this.itemComandaForm.SubTotal = (Item.Quantidade * Item.Valor_Unitario)
      this.itemComandaForm.Data_Retorno = ""
      this.itemVmodelServico = Item.ID_Cliente

        

      this.itemVmodelCiente = Item.Nome_Cliente
      this.itemVmodelServico = Item.descricao
      this.itemVmodelProfissional = Item.nome
    },
    EditarItemComanda() {    

      this.itemComandaForm.SubTotal = this.itemComandaForm.Valor_Unitario * this.itemComandaForm.Quantidade

      let urlApi = "comanda/alterar-item";
        api
          .post(urlApi, this.itemComandaForm, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.CarregaItensComanda();
            this.cancelaEdicao();

          })
          .catch(error => {
              this.$notify({
              timeout: 2500,
              message: error,
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger"
            });
          });

    },
  async ExcluirItemComanda(idComandaItem) {

  Swal.fire({
    title: 'Confirma a Exclusão do Item?',
    input: 'textarea',
    inputLabel: 'Message',
    inputPlaceholder: 'Informe o Motivo do cancelamento aqui...',
    confirmButtonText: 'Confirmar',
    cancelButtonText: `Cancelar`,
    inputAttributes: {
      'aria-label': 'Confirma a Exclusão do Item?'
    },
    showCancelButton: true
  }).then((result) => {
    
    if(result.value != undefined) {
      
      let urlApi = `comanda/excluir-item/${this.Comanda.ID_Comanda}/${idComandaItem}?obs=${result.value}`;
        api
          .delete(urlApi)
          .then(resp => {
            this.CarregaItensComanda();
          })
          .catch(error => {
            this.$notify({
            timeout: 2500,
            message: error,
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger"
          });
        });
      
    }
    
  })


      // Swal.fire({
      //     title: "Excluir Item da Comanda",
      //     text: "",
      //     html: `Tem Certeza que Deseja Excluir o Item da Comanda? <br><br>
      //       <div class="md-field md-theme-default">                      
      //         <input type="text" v-model="motivoCancelamento" placeholder="Informe o Motivo do Cancelamento" id="md-input" class="md-input">
      //       </div>
      //       <br>
      //       `,
      //     showCancelButton: true,
      //     confirmButtonText: "Confirmar",
      //     cancelButtonText: "Cancelar",
      //     confirmButtonClass: "md-button md-success",
      //     cancelButtonClass: "md-button md-danger",
      //     buttonsStyling: false
      //   }).then(() => {
      //     alert(this.motivoCancelamento)
      //   }).catch(() => {
      //     alert('cancelado')
      //   });

    },
    CarregaProfissionaisCombo: function (args) {
     
      this.load = true;
      api
        .get(
          `profissionais/lista-profissionais-combo/0`
        )
        .then((resp) => {
          this.profissionaisCombo = resp.data;

          // this.itemVmodelFuncionario = this.profissionaisCombo.filter(c => c.id_funcionario === this.dataEditor.OwnerId)[0].nome
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
        
    },
  },
  mounted() {
    this.CarregaItensComanda()
    this.CarregaComboServicosProdutos()
    this.CarregaProfissionaisCombo()
    this.CarregaClientesCombo()
  },
  computed: {
    ValorTotalComanda() {

      if (this.itensComanda == {}) {
        return 0
      }
      
      if(this.itensComanda.length > 0) { 
        return this.itensComanda.reduce((acc, item) => acc + item.SubTotal, 0);
      } else {
        return 0
      }
      
    },
    ValorTotalProdutos() {
      if (this.itensComanda == {}) {
        return 0
      }
      if(this.itensComanda.length > 0) {
        return this.itensComanda.filter(({tipo}) => tipo === 'P').reduce((acc, item) => acc + item.SubTotal, 0);
      } else {  
        return 0
      }
      
    },
    ValorTotalServico() {
      if (this.itensComanda == {}) {
        return 0
      }
      else {
        if(this.itensComanda.length > 0) {
          return this.itensComanda.filter(({tipo}) => tipo === 'S').reduce((acc, item) => acc + item.SubTotal, 0);
        } else {
          return 0
        }
      }
      
      
    },
    ValorTroco() {
      return this.valorRecebido - this.ValorTotalComanda
    }
  },
  // props: ["Comanda"]
  props: {
    Comanda: {
      // Mantenha a propriedade "Comanda" existente
      type: Object,
      required: true
    },
    carregaComandas: {
      // Adicione a nova propriedade "carregaComandas"
      type: Function,
      required: true
    },
    // outras propriedades, se houver
  },
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 10;
}

.vs-table tbody tr td{
  font-size: 12px;
  padding: 0px 0px 0px 0px;
  height: 32px;
}

.vs-table thead tr th{
  font-size: 12px;
  padding: 0px 0px 0px 0px;
  height: 32px;
}

.form-v{
  padding: 0px 5px 0px 0px
}

.modal-container {
  max-width: 800px;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

input.center {
text-align: center;
}

.swal2-container {
  z-index: 9999;
}

</style>
