import { app } from "@/main";
import UsuarioLogado from './usuario-logado';
import Token from './token';
import Vue from "vue";

export default class GuardRoute {
    static verificarUsuarioLogado(to, from, next) {
        var usuario = UsuarioLogado.obterUsuarioLogado();
        
        if (!usuario) {
            next({ name: 'Login', query: {
                error: 'Usuário não autenticado!!!',
             } });

             this.$toast.toast.success({
                title:'dsdsadasd',
                message:''
            })
                       
        }
        else if (Token.expirado()) {
            UsuarioLogado.removerUsuarioLogado();
            next({ name: 'Login', query: {
                error: 'Sessão expirada!!!',
             }});
           
            
        }
        else {
            next();
        }
    }
}
