<template>
  <transition name="modal">
    <!-- <loading
      :active.sync="load"
      :can-cancel="true"
      :is-full-page="fullpage"
    ></loading> -->

    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header class="md-tabs-navigation md-card-header-warning">
                  <h4 class="title" style="color: white">
                    Cadastro de Mensagens
                  </h4>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-45">
                      <md-field>
                        <label>Titulo</label>
                        <md-input v-model="item.DadosDaMensagem.Titulo"></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-20">
                      <md-field>
                        <label>Tipo</label>
                        <md-input v-model="item.DadosDaMensagem.Tipo">
                        </md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-35">
                      <md-field>
                        <label>Nome da Imagem</label>
                        <md-input v-model="item.DadosDaMensagem.NomeImagem">
                        </md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Mensagem</label>
                        <md-textarea v-model="item.DadosDaMensagem.Mensagem"></md-textarea>
                      </md-field>
                    </div>
                  </div>

                  <br>
                  <div class="md-layout">
                    <md-table v-model="item.Destinatarios" class="table-striped md-card md-fixed-header" table-header-color="green">
                      <md-table-toolbar>
                        <div class="md-layout">
                          <div class="md-layout-item md-size-25">
                            <md-field>
                              <label>Destinatário</label>
                              <md-input id="inputDest" v-model="destinatario">
                              </md-input>
                            </md-field>
                          </div>
                          <div class="md-layout-item md-size-10">
                           <md-button class="md-button md-just-icon md-theme-default md-success md-sm"
                             @click="incluirDestinatario()">
                             <md-icon>add</md-icon>
                             <md-tooltip md-direction="top">Incluir Destinatário</md-tooltip>
                           </md-button>
                         </div>

                          <div class="md-layout-item md-size-25">
                            <md-field>
                              <label for="periodo">Aniversariantes</label>
                              <md-select v-model="filtroClientes" name="DrdfiltroClientes" id="DrdfiltroClientes">
                                <md-option value="">Selecione uma Opção</md-option>
                                <md-option value="dia">Dia</md-option>
                                <md-option value="semana">Semana</md-option>
                                <md-option value="mes">Mês</md-option>
                              </md-select>
                            </md-field>
                          </div>
                          <div class="md-layout-item md-size-10">
                            <md-button class="md-button md-just-icon md-theme-default md-success md-sm"
                              @click="incluirDestinatario()">
                              <md-icon>add</md-icon>
                              <md-tooltip md-direction="top">Incluir Destinatário</md-tooltip>
                            </md-button>
                          </div>

                          <div class="md-layout-item md-size-20">
                            <md-field>
                              <label for="categoriaCliente">Categorias Cliente</label>
                              <md-select v-model="categoriaClienteSelecionado" name="categoriaCliente" id="categoriaCliente">
                                <md-option v-for="categoria in categoriaCliente" :key="categoria.id" :value="categoria.id">
                                  {{ categoria.nome_categoria }}
                                </md-option>
                              </md-select>
                            </md-field>
                         </div>
                         <div class="md-layout-item md-size-10">
                           <md-button class="md-button md-just-icon md-theme-default md-success md-sm"
                             @click="buscaClientesPorCategoria()">
                             <md-icon>add</md-icon>
                             <md-tooltip md-direction="top">Incluir Categoria</md-tooltip>
                           </md-button>
                         </div>


                        </div>

                      </md-table-toolbar>
                      <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Destinatário">{{ item.Destinatario }}</md-table-cell>
                        <md-table-cell md-label="Data Envio">{{ item.DataHoraEnvio ? formatarData(item.DataHoraEnvio) : '' }}</md-table-cell>
                        <md-table-cell md-label="Entregue">{{ item.Entregue == false ? 'Não' : 'Sim' }}</md-table-cell>
                        <md-table-cell md-label="Visualizado">{{ item.Visualizado == false ? 'Não' : 'Sim' }}
                        </md-table-cell>
                        <md-table-cell md-label="Data Hora Visualização">{{ item.DataHoraVisualizacao }}
                        </md-table-cell>

                        <md-table-cell>
                          <md-button @click="excluirDestinatario(item)" class="md-just-icon md-simple md-danger">
                            <md-icon>close</md-icon>
                            <md-tooltip md-direction="top">Excluir Destinatário</md-tooltip>
                          </md-button>
                        </md-table-cell>
                      </md-table-row>
                    </md-table>

                  </div>

                </md-card-content>

                <div class="footer mx-auto">
                  <md-button class="md-success md-sm md-round" @click="salvaRegistro">Salvar</md-button>&nbsp;
                  <md-button class="md-info md-sm md-round" @click="dispararMensagens">Disparar</md-button>&nbsp;
                  <md-button class="md-warning md-sm md-round" @click="excluirTodos">Excluir Todos</md-button>&nbsp;
                  <md-button class="md-danger md-sm md-round" @click="closeModal(0, 0)">Fechar</md-button>
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from "@/routes/api";

export default {
  components: {},
  name: "modal",
  data() {
    return {
      destinatario: "",
      filtroClientes: "",
      categoriaClienteSelecionado: 0
    };
  },
  methods: {
    closeModal: function (pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    buscaClientesPorCategoria: async function (){
      await api.get(`categorias-cliente/busca-contatos-categoria/${this.categoriaClienteSelecionado}`).then(resp => {
            resp.data.Data.forEach((item) => {
              this.item.Destinatarios.push({
              ID_Mensagem: this.item.DadosDaMensagem.ID_Mensagem,
              Destinatario: item.celular,
              DataHoraEnvio: '',
              Entregue: false,
              Visualizado: false,
              DataHoraVisualizacao: '',
            });
          });
          this.categoriaClienteSelecionado = 0
          })
          .catch(error => {
            this.$emit("load", false);
            alert(error);
          });
    },
    salvaRegistro() {
      if (this.item.DadosDaMensagem.ID_Mensagem == 0) {
        this.$emit("load", true);
        api
          .post(`mensagens-disparo/incluir-nova-mensagem`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);
            this.closeModal(1, 1);

            this.$notify({
              timeout: 2500,
              message: "Mensagem incluída com sucesso!!!",
              icon: "add_success",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });

          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Erro ao incluir registro!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      }
      else {
        this.$emit("load", true);
        api
          .post(`mensagens-disparo/alterar-mensagem`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);

            this.$notify({
              timeout: 2500,
              message: "Mensagem alterada com sucesso!!!",
              icon: "add_success",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Erro ao alterar registro!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            this.$emit("load", false);
            return;
          });
      }
    },

    incluirDestinatario: async function () {

      // if (this.destinatario == "") {
      //   this.notifica("Destinatário não pode ser vazio!!!", "add_alert", "danger");
      //   return;
      // }

      for (var i = 0; i < this.item.Destinatarios.length; i++) {
        if (this.item.Destinatarios[i].Destinatario === this.destinatario) {
          this.notifica("O destinatário já foi incluso!!!", "add_alert", "danger");
          this.$emit("load", false);
          return;
        }
      }

      this.item.Destinatarios.push({
        ID_Mensagem: this.item.DadosDaMensagem.ID_Mensagem,
        Destinatario: this.destinatario,
        DataHoraEnvio: '',
        Entregue: false,
        Visualizado: false,
        DataHoraVisualizacao: '',
      });

      document.getElementById("inputDest").value = "";
      this.destinatario = "";

    },

    incluiClientesDestinatarios: async function () {
      this.$emit("load", true);
      await api
        .get(`mensagens-disparo/aniversariantes/${this.filtroClientes}`)
        .then((resp) => {
          this.statusCode = resp.statusCode;

          resp.data.Data.forEach((item) => {
            this.item.Destinatarios.push({
              ID_Mensagem: this.item.DadosDaMensagem.ID_Mensagem,
              Destinatario: item.celular,
              DataHoraEnvio: '',
              Entregue: false,
              Visualizado: false,
              DataHoraVisualizacao: '',
            });
          });

          this.$emit("load", false);
          this.$notify({
            timeout: 2500,
            message: "Clientes incluídos com sucesso!!!",
            icon: "add_success",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });

        })
        .catch((error) => {
          this.$notify({
            timeout: 2500,
            message: "Erro ao incluir clientes!!!",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
          this.$emit("load", false);
          return;
        });
    },

    notifica(mensagem, icone = "success", tipo = "add_success") {
      this.$notify({
        timeout: 2500,
        message: mensagem,
        icon: icone,
        horizontalAlign: "right",
        verticalAlign: "top",
        type: tipo,
      });
    },

    excluirDestinatario(item) {
      this.item.Destinatarios.splice(this.item.Destinatarios.indexOf(item), 1);
    },
    dispararMensagens() {
      this.$emit("load", true);
      api
        .get(`mensagens-disparo/disparar-mensagem/${this.item.DadosDaMensagem.ID_Mensagem}`)
        .then((resp) => {
          this.statusCode = resp.statusCode;
          this.$emit("load", false);

          this.$notify({
            timeout: 2500,
            message: "Disparo das mensagens iniciado!!!",
            icon: "add_success",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          this.closeModal(0);
        }).catch((error) => {
          this.$notify({
            timeout: 2500,
            message: "Erro ao disparar mensagens!!!",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
          this.$emit("load", false);
          return;
        }
        );
    },
    excluirTodos() {
      this.$emit("load", true);
      this.item.Destinatarios = [];
      this.$emit("load", false);
    },
    formatarData(data) {
      const dataObj = new Date(data);
      const dia = String(dataObj.getDate()).padStart(2, '0');
      const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
      const ano = dataObj.getFullYear();
      const horas = String(dataObj.getHours()).padStart(2, '0');
      const minutos = String(dataObj.getMinutes()).padStart(2, '0');
      return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
    },
  },
  props: ["item", "pagina", "termo", "perPage", "load", "categoriaCliente"],
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 10;
}

.modal-container {
  max-width: 800px;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>