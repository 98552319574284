import jwt_decode from "jwt-decode";
// import userLogado from "./usuario-logado"

export default class Token {
    static expirado() {
        var userLogado = localStorage.getItem('usuario_salao');
        var token = JSON.parse(userLogado);
        
        if (token) {
            var decoded = jwt_decode(token.token.access_token);
            var dataExpiracao = new Date(decoded.exp * 1000);
            return dataExpiracao < Date.now();
        } else {
            return true;
        }
    }
}