<template>
  <transition name="modal">
    <!-- <loading
      :active.sync="load"
      :can-cancel="true"
      :is-full-page="fullpage"
    ></loading>-->
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header
                  class="md-tabs-navigation md-card-header-warning"
                >
                  <h4 class="title" style="color:white">
                    Cadastro de Custos Fixos
                    <strong>{{
                      item.id_custo_fixo > 0
                        ? " - Código: " + item.id_custo_fixo
                        : ""
                    }}</strong>
                  </h4>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Descrição</label>
                        <md-input
                          v-model="item.descricao"
                          @input="item.descricao = item.descricao.toUpperCase()"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-25">
                      <md-field class="md-size-10 md-has-value">
                        <label>Valor</label>

                        <vue-numeric
                          class="md-input"
                          currency="R$"
                          separator="."
                          v-bind:precision="2"
                          v-model="item.valor"
                        ></vue-numeric>

                        <!-- <md-input
                          maxlength="10"
                          v-model.lazy="item.valor"
                          v-money="money"
                          md-counter="false"
                        ></md-input>-->
                      </md-field>

                      <!-- <money class="" v-model="item.valor" v-bind="money"></money> -->
                    </div>
                  </div>
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button
                    class="md-success md-sm md-round"
                    @click="salvaRegistro"
                    >Salvar</md-button
                  >&nbsp;
                  <md-button
                    class="md-danger md-sm md-round"
                    @click="closeModal(0, 0)"
                    >Cancelar</md-button
                  >
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Swal from "sweetalert2";
import api from "@/routes/api";
// import { Money } from "v-money";
import VueNumeric from "vue-numeric";
export default {
  components: { VueNumeric },
  name: "modal",
  data() {
    return {
      valor: 0.0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },
  // directives: { money: VMoney },
  methods: {
    closeModal: function(pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    salvaRegistro() {
      if (this.item.descricao.length <= 4) {
        this.$notify({
          timeout: 2500,
          message: "A descrição deve conter ao menos 4 caracteres!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        return;
      }

      if (this.item.valor <= 0) {
        this.$notify({
          timeout: 2500,
          message: "Valor inválido!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        return;
      }

      //SE FOR INCLUSÃO
      if (this.item.id_custo_fixo == 0) {
        this.$emit("load", true);
        api
          .post('custo-fixo/incluir-custo-fixo', this.item, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.$emit("load", false);
            this.closeModal(1, 1);
          })
          .catch(error => {
            this.$emit("load", false);
            alert(error);
          });
      } else {
        
        this.$emit("load", true);
        api
          .put(`custo-fixo/alterar-custo-fixo`, this.item, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.$emit("load", false);
            this.closeModal(0);
          })
          .catch(error => {
            //alert(error);
            this.$emit("load", false);
          });
      }
    }
  },
  props: ["item", "pagina", "termo", "perPage", "load"]
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 10;
}

.modal-container {
  max-width: 800px;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
