export default class UsuarioLogado {
    constructor(nome, email, id_usuario, token) {
        this.nome = nome;
        this.email = email;
        this.id_usuario = id_usuario;
        this.token = token;
    }
    static obterUsuarioLogado() {
        let usuarioLogado = JSON.parse(localStorage.getItem('usuario_salao'));
        return usuarioLogado;
    }

    static removerUsuarioLogado() {
        localStorage.removeItem('usuario_salao');
        localStorage.removeItem('access_token');
    }

    static inserirUsuarioLogado(usuarioLogado) {
        localStorage.setItem('usuario_salao', JSON.stringify(usuarioLogado));
    }
}