<template>
  <transition name="modal">
    <!-- <loading :active.sync="load" :can-cancel="true"></loading> -->
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header
                  class="md-tabs-navigation md-card-header-warning"
                >
                  <h4 class="title" style="color: white">
                    Lançamento de Pagamentos - Parcela: {{ idParcela }}
                  </h4>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout">
                    <!-- <div class="md-layout-item md-size-25">
                      <ejs-autocomplete
                        v-model="itemVmodelCentroCusto"
                        floatLabelType="Auto"
                        :autofill="autofill"
                        :dataSource="centroCustoCombo"
                        :select="recebeValorCentroCusto"
                        :fields="{ text: 'descricao', value: 'descricao' }"
                        placeholder="Selecione o Centro de Custo"
                      ></ejs-autocomplete>
                    </div> -->

                    <!-- <div class="md-layout-item md-size-20">
                      <ejs-datepicker
                        :placeholder="'Data do Pagamento'"
                        floatLabelType="Auto"
                        :format="maskData"
                        :enableMask="true"
                        :change="alteraDataPagamento"
                      ></ejs-datepicker>
                    </div> -->

                    <div class="md-layout-item md-size-25">
                      <md-field>
                        <label>Data do Pagamento</label>
                        <md-input
                          type="date"
                          v-model="contasPagarPagamento.data_pagamento"
                          @change="ChangeDataNasc()"
                        ></md-input>
                      </md-field>
                    </div>

                    <div v-show="itemVmodelFormaPagamento == 2" class="md-layout-item md-size-20">
                      <ejs-datepicker
                        :placeholder="'Data Pre'"
                        floatLabelType="Auto"
                        :format="maskData"
                        :enableMask="true"
                        :change="alteraData"
                      ></ejs-datepicker>
                    </div>

                    <div v-show="itemVmodelFormaPagamento == 2" class="md-layout-item md-size-15">
                      <md-field class="md-size-10 my-input-upper">
                        <label>Numero Cheque</label>
                        <md-input
                          autocomplete="nope"
                          maxlength="100"
                          md-counter="false"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-25">
                      <md-field class="md-size-10">
                        <label>Formas de pagamentos</label>
                        <md-select
                          v-model="contasPagarPagamento.id_forma_pagamento"
                          name="idcidade"
                          id="idcidade"
                        >
                          <md-option
                            v-for="(itemFP, indexFP) in formasPagamento"
                            v-bind:key="indexFP"
                            :value="formasPagamento[indexFP].ID_FormaPagamento"
                            >{{ formasPagamento[indexFP].Descricao }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>


                    <!-- <div class="md-layout-item md-size-20">
                      <div style="height: 80px">
                        <ejs-dropdownlist
                          floatLabelType="Auto"
                          id="dropdownlist"
                          :select="recebeValorFormaPagamento"
                          :dataSource="formasPagamento"
                          :fields="fieldsFormasPgto"
                          placeholder="Formas de Pagamento"
                        ></ejs-dropdownlist>
                      </div>
                    </div> -->

                    <div class="md-layout-item md-size-15">
                      <md-field class="md-size-10 my-input-upper">
                        <label>Valor</label>
                        <md-input
                          autocomplete="nope"
                          maxlength="100"
                          v-model="contasPagarPagamento.valor"
                          md-counter="false"
                        ></md-input>
                      </md-field>
                    </div>
                    <div v-show="itemVmodelFormaPagamento == 2">
                      Compensado:
                      <md-switch style="margin: 0 0 0 0"
                      ></md-switch>
                    </div>
                  </div>
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button
                    class="md-success md-sm md-round"
                    @click="salvaRegistro"
                    >Salvar</md-button
                  >&nbsp;
                  <md-button
                    class="md-danger md-sm md-round"
                    @click="closeModal(1, 1)"
                    >Cancelar</md-button
                  >
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Swal from "sweetalert2";
import Vue from "vue";
import api from "@/routes/api";
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin, TextBoxPlugin } from "@syncfusion/ej2-vue-inputs";

Vue.use(DatePickerPlugin, DropDownListPlugin, TextBoxPlugin);

export default {
  name: "modal",
  data() {
    return {
      itemVmodelFormaPagamento: null,
      itemVmodelCentroCusto: null,
      formaPagamentoCombo: [],
      centroCustoCombo: [],
      // ativaCheque: false,
      lstItensContaPagarParcela: [],
      itemFornecedorCombo: null,
      itemCentroCustoCombo: null,
      incluindoItem: false,
      contasPagarPagamento: {
        id_pagamento: 0,
        id_parcela: 0,
        id_forma_pagamento: 0,
        id_caixa: 0,
        valor: 0,
        data_pagamento: "",
        data_pre: "",
        compensado: true,
        nro_cheque: "",
        nro_auth_cartao: "",
      },
      fieldsFormasPgto: { text: "Descricao", value: "ID_FormaPagamento" },
      formasPagamento: [
        {
          ID_FormaPagamento: 1,
          Descricao: "Dinheiro",
        },
        {
          ID_FormaPagamento: 2,
          Descricao: "Cheque",
        },
        {
          ID_FormaPagamento: 3,
          Descricao: "Cartão de Crédito",
        },
        {
          ID_FormaPagamento: 4,
          Descricao: "Cartão de Débito",
        },
        {
          ID_FormaPagamento: 5,
          Descricao: "Pix",
        },
        {
          ID_FormaPagamento: 6,
          Descricao: "Transferência",
        },
      ],
      condicaoPagamento: [
        { id: "A PRAZO", tipo: "A PRAZO" },
        { id: "A VISTA", tipo: "A VISTA" },
      ],
      fieldsCondicaoPagamento: { text: "tipo", value: "id" },
    };
  },
  methods: {
    closeModal: function (pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    salvaRegistro() {
      
      if (this.contasPagarPagamento.data_pagamento == "" || 
          this.contasPagarPagamento.formasPagamento == 0 || 
          this.contasPagarPagamento.valor == 0
        ) {
        this.$notify({
          timeout: 2500,
          message: "Valores inválidos!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        return;
      }
      if (this.contasPagarPagamento.id_pagamento == 0) {
        this.$emit("load", true);
        api
          .put(`/contas-a-pagar-pagamentos/incluir-conta-pagar-pagamento`, this.contasPagarPagamento)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);
            this.closeModal(1, 1);
            // this.item.id_conta_pagar = resp.data.id_conta_pagar;
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Não foi possível inserir o registro!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      } else {
        this.$emit("load", true);
        api
          .post(`contas-a-pagar/altera-conta-pagar`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "error",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            this.$emit("load", false);
            return;
          });
      }
    },
    // recebeValorFormaPagamento(args) {
    //   this.itemVmodelFormaPagamento = args;
    //   if (this.itemVmodelFormaPagamento == 2) {
    //     this.ativaCheque = true;
    //   }
    //   //   this.agendamento.NomeCliente = args.itemData.AutoComplete;
    //   //   this.VerificaComandaAberta(this.agendamento.ID_Cliente);

    //   //   this.CarregaServicosDoCliente(
    //   //     this.agendamento.ID_Cliente,
    //   //     moment(String(this.data.StartTime)).format("YYYY-MM-DD")
    //   //   );
    // },
    addItemContaParcela() {
      if (
        this.contaPagarParcelas.vencimento == "" ||
        this.contaPagarParcelas.vencimento == null
      ) {
        this.$notify({
          timeout: 2500,
          message: "Insira a data de vencimento",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }

      if (this.contaPagarParcelas.data_quitacao == null) {
        this.contaPagarParcelas.data_quitacao = "";
      }

      if (this.contaPagarParcelas.id_parcela == 0) {
        api
          .put(
            `contas-a-pagar-parcelas/incluir-conta-pagar-parcela?idContaPagar=${this.item.id_conta_pagar}`,
            this.contaPagarParcelas
          )
          .then((resp) => {
            this.carregaItemContaPagarParcela();
            this.incluindoItem = false;
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Não conseguimos incluir a parcela",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      } else {
        api
          .post(
            `contas-a-pagar-parcelas/altera-conta-pagar-parcela?idContaPagar=${this.item.id_conta_pagar}`,
            this.contaPagarParcelas
          )
          .then((resp) => {
            this.carregaItemContaPagarParcela();
            this.incluindoItem = false;
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Saldo em caixa insuficiente!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      }
    },
    novoItem() {
      this.contaPagarParcelas.id_parcela = 0;
      this.contaPagarParcelas.nro_parcela = 0;
      this.contaPagarParcelas.vencimento = "";
      this.contaPagarParcelas.valor = 0;
      this.contaPagarParcelas.acrescimo = 0;
      this.contaPagarParcelas.desconto = 0;
      this.contaPagarParcelas.sub_total = 0;
      this.contaPagarParcelas.quitada = false;
      this.contaPagarParcelas.data_quitacao = "";

      this.incluindoItem = true;
    },
    editaContaPagarParcelaItem(itemContaPagarParcelas) {
      this.contaPagarParcelas.id_parcela = itemContaPagarParcelas.id_parcela;
      this.contaPagarParcelas.nro_parcela = itemContaPagarParcelas.nro_parcela;
      this.contaPagarParcelas.vencimento = itemContaPagarParcelas.vencimento;
      this.contaPagarParcelas.valor = itemContaPagarParcelas.valor;
      this.contaPagarParcelas.acrescimo = itemContaPagarParcelas.acrescimo;
      this.contaPagarParcelas.desconto = itemContaPagarParcelas.desconto;
      this.contaPagarParcelas.subtotal = itemContaPagarParcelas.subtotal;
      this.contaPagarParcelas.quitada = itemContaPagarParcelas.quitada;
      this.contaPagarParcelas.data_quitacao =
        itemContaPagarParcelas.data_quitacao;
      this.contaPagarParcelas.id_conta_pagar =
        itemContaPagarParcelas.id_conta_pagar;

      this.incluindoItem = true;
    },
    cancelaInclusaoContaParcela() {
      this.incluindoItem = false;
    },
    excluiContaPagarParcelaItem(ItemPacoteItem) {
      api
        .delete(
          `contas-a-pagar-parcelas/exclui-conta-pagar-parcela/${ItemPacoteItem.id_parcela}`
        )
        .then((resp) => {
          this.carregaItemContaPagarParcela();
        });
    },
    alteraDataPagamento(args) {
      this.item.data_pagamento = args.value;
    },
    alteraDataQuitacao(args) {
      this.contaPagarParcelas.data_quitacao = args.value;
    },
    carregaItemContaPagarParcela() {
      api
        .get(
          `contas-a-pagar-parcelas/lista-contas-pagar-parcelas/1/999/${this.item.id_conta_pagar}`
        )
        .then((resp) => {
          this.lstItensContaPagarParcela = resp.data.Data;
        });
    },
    // CarregaFormaPagamentoCombo: function (args) {
    //   this.load = true;
    //   api
    //     .get(`formas-pagamentos/lista-formas-pagamentos/1/999`)
    //     .then((resp) => {
    //       this.formaPagamentoCombo = resp.data.Data;

    //       this.itemVmodelFormaPagamento = this.formaPagamentoCombo.filter(
    //         (c) => c.id_forma_pagamento === this.dataEditor.OwnerId
    //       )[0].descricao;
    //       this.load = false;
    //     })
    //     .catch((error) => {
    //       this.load = false;
    //     });
    // },

    lancarPagamentoContas(itemContaPagarParcelas) {
      this.contaPagarParcelas.id_parcela = itemContaPagarParcelas.id_parcela;
      this.contaPagarParcelas.nro_parcela = itemContaPagarParcelas.nro_parcela;
      this.contaPagarParcelas.vencimento = itemContaPagarParcelas.vencimento;
      this.contaPagarParcelas.valor = itemContaPagarParcelas.valor;
      this.contaPagarParcelas.acrescimo = itemContaPagarParcelas.acrescimo;
      this.contaPagarParcelas.desconto = itemContaPagarParcelas.desconto;
      this.contaPagarParcelas.subtotal = itemContaPagarParcelas.subtotal;
      this.contaPagarParcelas.quitada = itemContaPagarParcelas.quitada;
      this.contaPagarParcelas.data_quitacao =
        itemContaPagarParcelas.data_quitacao;
      this.contaPagarParcelas.id_conta_pagar =
        itemContaPagarParcelas.id_conta_pagar;

      this.incluindoItem = true;
    },

    recebeValorFornecedor(args) {
      this.itemFornecedorCombo = args;
      this.item.id_fornecedor = args.itemData.id_fornecedor;
    },
    CarregaCentroCustoCombo: function (args) {
      this.load = true;
      api
        .get(`centro-custos/lista-centro-custo/0/999`)
        .then((resp) => {
          this.centroCustoCombo = resp.data.Data;

          this.itemVmodelCentroCusto = this.centroCustoCombo.filter(
            (c) => c.id_centro_de_custos === this.dataEditor.OwnerId
          )[0].descricao;
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
    },
    recebeValorCentroCusto(args) {
      this.itemCentroCustoCombo = args;
      this.item.id_centro_custo = args.itemData.id_centro_de_custos;
    },
  },
  
  mounted() {
    // (this.itemVmodelFornecedor = this.item.razao_social),
    //   (this.itemVmodelCentroCusto = this.item.descricao);
      // this.contasPagarPagamento.id_parcela = idParcela
      // console.log(this.contasPagarPagamento)

      this.contasPagarPagamento.id_parcela = this.idParcela
  },
  created: async function () {
    
    await this.CarregaFormaPagamentoCombo(),
    await this.CarregaCentroCustoCombo(),
    await this.carregaItemContaPagarParcela();
  },
  props: ["idParcela", "pagina", "termo", "perPage", "load"],
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 10;
}
.modal-container {
  max-width: 950px;
}
.md-menu-content {
  z-index: 99999 !important;
}
.my-input-upper input {
  text-transform: uppercase;
}
.my-input-lower input {
  text-transform: lowercase;
}

// .md-tabs-navigation {
//     -webkit-box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
//     box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
//     margin: 0px 0 0;
//     border-radius: 3px;
//     padding: 0px 0px 0px 0px;
//     background-color: #999999;
// }
#tab-home > div > div.md-card.md-card-nav-tabs.md-theme-default > div {
  padding: 0 0 0 0;
}

#tab-home
  > div
  > div.md-card.md-card-nav-tabs.md-theme-default
  > div
  > div
  > div.md-content.md-tabs-content.md-theme-default {
  min-height: 135px;
}

body
  > div
  > div.main-panel.ps
  > div.content
  > div
  > div.modal-mask
  > div
  > div
  > div
  > div
  > div
  > div.md-content.md-tabs-content.md-theme-default {
  min-height: 650px;
}
</style>
