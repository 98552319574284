import axios from 'axios'
import { app } from "@/main";
import userLogado from "./usuario-logado"

let apiUrl = process.env.VUE_APP_API_URL;

const instance = axios.create({
    baseURL: apiUrl,
    timeout: 30000,
    params: {}
});

instance.interceptors.request.use(
    config => {
        const token = userLogado.obterUsuarioLogado();
        
        if (token) {            
            config.headers.common["Authorization"] = `Bearer ${token.token.access_token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                case 400:
                    if (error.response.data.mensagem) {
                        app.$bvToast.toast(error.response.data.mensagem, {
                            title: `Erro`,
                            variant: "danger",
                            solid: true
                        });
                    }
                    break;
                case 401:
                    app.$router.push({ path: "/login" });
                    break;
                case 403:
                    break;
                case 404:
                    break;
                case 502:
            }
            return Promise.reject(error.response);
        }
    }
);

export default instance;