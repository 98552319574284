<template>
  <transition name="modal">
    <!-- <loading
      :active.sync="load"
      :can-cancel="true"
      :is-full-page="fullpage"
    ></loading>-->
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header
                  class="md-tabs-navigation md-card-header-warning"
                >
                  <h4 class="title" style="color: white">
                    Cadastro de Pacotes
                    <strong>{{
                      item.id_sangria > 0 ? " - Código: " + item.id_sangria : ""
                    }}</strong>
                  </h4>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-15">
                      <ejs-datepicker
                        :disabled="true"
                        v-bind:value="
                          item.data_cadastro != null && item.data_cadastro != ''
                            ? moment(item.data_cadastro).format('DD/MM/YYYY')
                            : null
                        "
                        :placeholder="'Data Pacote'"
                        floatLabelType="Auto"
                        :format="maskData"
                        :enableMask="true"
                        :change="alteraData"
                      ></ejs-datepicker>
                    </div>

                    <div class="md-layout-item md-size-20">
                      <md-field>
                        <label>Nome Pacote</label>
                        <md-input
                          v-model="item.nome_pacote"
                          @input="
                            item.nome_pacote = item.nome_pacote.toUpperCase()
                          "
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-15">
                      <md-field>
                        <label>Validade (em dias)</label>
                        <md-input
                          v-model="item.validade"
                          @input="item.validade = item.validade.toUpperCase()"
                        ></md-input>
                      </md-field>
                    </div>

                    <div style="margin: 0 0 0 0">
                      Ativo
                      <div>
                        <md-switch
                          style="margin: 0 0 0 0"
                          v-model="item.ativo"
                        ></md-switch>
                      </div>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Observação</label>
                        <md-textarea v-model="item.observacao"></md-textarea>
                      </md-field>
                    </div>
                  </div>
                </md-card-content>

                <md-card-content>
                  <div class="md-layout">
                    <div
                      v-show="incluindoItem == false"
                      class="md-layout-item md-size-10"
                    >
                      <md-button
                        class="
                          md-button md-just-icon md-theme-default md-info md-sm
                        "
                        @click.native="novoItem"
                      >
                        <md-icon>add</md-icon>
                      </md-button>
                    </div>

                    <div
                      v-show="incluindoItem"
                      class="md-layout-item md-size-30"
                    >
                      <div class="e-float-input">
                        <ejs-autocomplete
                          v-model="itemVmodelServico"
                          floatLabelType="Auto"
                          :select="recebeValorAutoServico"
                          :dataSource="servicosCombo"
                          :fields="{
                            text: 'descricao',
                            value: 'descricao',
                          }"
                          placeholder="Adicionar Produto/Serviço"
                        ></ejs-autocomplete>
                        <span class="e-float-line"></span>
                      </div>
                    </div>

                    <div
                      v-show="incluindoItem"
                      class="md-layout-item md-size-15"
                    >
                      <md-field class="md-size-10 my-input-upper">
                        <label>Quantidade</label>
                        <md-input
                          v-model="itemPct.qtde"
                          autocomplete="nope"
                          maxlength="100"
                          md-counter="false"
                        ></md-input>
                      </md-field>
                    </div>

                    <div
                      v-show="incluindoItem"
                      class="md-layout-item md-size-15"
                    >
                      <md-field class="md-size-10 my-input-upper">
                        <label>Valor unitário</label>
                        <md-input
                          v-model="itemPct.valor_unitario"
                          autocomplete="nope"
                          maxlength="100"
                          md-counter="false"
                        ></md-input>
                      </md-field>
                    </div>

                    <div
                      v-show="incluindoItem"
                      class="md-layout-item md-size-15"
                    ></div>

                    <div
                      v-show="incluindoItem"
                      class="md-layout-item md-size-5 text-right"
                    >
                      <md-button
                      @click="cancelaInclusaoMatricula"
                        class="
                          md-button md-just-icon md-theme-default md-danger
                        "
                      >
                        <md-icon>close</md-icon>
                      </md-button>
                    </div>
                    <div
                      v-show="incluindoItem"
                      class="md-layout-item md-size-5 text-right"
                    >
                      <md-button
                        class="
                          md-button md-just-icon md-theme-default md-success
                        "
                        @click.native="addItemPacote"
                      >
                        <md-icon>done</md-icon>
                      </md-button>
                    </div>

                    <div
                      v-show="incluindoItem == false"
                      class="md-layout-item md-size-90"
                    >
                      <md-table v-model="lstItensPacotes">
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell
                            >{{ item.id_pacote_item }}
                          </md-table-cell>
                          <md-table-cell>{{ item.descricao }}</md-table-cell>
                          <md-table-cell>{{ item.qtde }}</md-table-cell>
                          <md-table-cell>
                            <md-button
                              @click="editaPacoteItem(item)"
                              class="md-just-icon md-simple md-primary"
                            >
                              <md-icon>edit</md-icon>
                              <md-tooltip md-direction="top">Editar</md-tooltip>
                            </md-button>

                            <md-button
                              @click="excluiPacoteItem(item)"
                              class="md-just-icon md-simple md-danger"
                            >
                              <md-icon>close</md-icon>
                              <md-tooltip md-direction="top"
                                >Excluir</md-tooltip
                              >
                            </md-button>
                          </md-table-cell>
                        </md-table-row>
                      </md-table>
                    </div>
                  </div>
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button
                    class="md-success md-sm md-round"
                    @click="salvaRegistro"
                    :disabled="item.id_sangria > 0"
                    >Salvar</md-button
                  >&nbsp;
                  <md-button
                    class="md-danger md-sm md-round"
                    @click="closeModal(0, 0)"
                    >Cancelar</md-button
                  >
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from "@/routes/api";

export default {
  components: {},
  name: "modal",
  data() {
    return {
      valor: 0.0,
      statusCode: 0,
      incluindoItem: false,
      maskData: "dd/MM/yyyy",
      lstItensPacotes: [],
      servicosCombo: [],
      itemVmodelServico: "",
      subtotal: 0,
      itemPct: {
        id_pacote_item: 0,
        id_pacote: this.item.id_pacote,
        id_servico_produto: 0,
        qtde: 0,
        valor_unitario: 0,
        sub_total: this.qtde * this.valor_unitario,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  methods: {
    closeModal: function (pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    salvaRegistro() {
      if (this.item.data_cadastro == null) {
        this.item.data_cadastro = "";
      }

      //SE FOR INCLUSÃO
      if (this.item.id_pacote == 0) {
        this.$emit("load", true);
        api
          .put(`pacotes/incluir-pacote`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);
            this.closeModal(1, 1);
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Não foi possível inserir o pacote!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      } else {
        this.$emit("load", true);
        api
          .post(`pacotes/altera-pacote`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);
            this.closeModal(0);
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Saldo em caixa insuficiente!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            this.$emit("load", false);
            return;
          });
      }
    },
    addItemPacote() {
      this.itemPct.sub_total = this.itemPct.qtde * this.itemPct.valor_unitario;

      if (this.itemPct.id_pacote_item == 0) {
        api
          .put(`pacotes-item/incluir-pacote-item`, this.itemPct)
          .then((resp) => {
            this.carregaItemPacote();
            this.incluindoItem = false;
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Saldo em caixa insuficiente!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      } else {
        api
          .post(`pacotes-item/altera-pacote-item`, this.itemPct)
          .then((resp) => {
            this.carregaItemPacote();
            this.incluindoItem = false;
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Saldo em caixa insuficiente!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      }
    },
    novoItem() {
      this.itemPct.id_pacote_item = 0;
      this.itemPct.id_pacote = this.item.id_pacote;
      this.itemPct.id_servico_produto = 0;
      this.itemPct.qtde = 0;
      this.itemPct.valor_unitario = 0;
      this.itemPct.sub_total = 0;
      this.itemVmodelServico = "";

      this.incluindoItem = true;
    },
    editaPacoteItem(itemPacoteItem) {
      
      this.itemPct.id_pacote_item = itemPacoteItem.id_pacote_item;
      this.itemPct.id_pacote = itemPacoteItem.id_pacote;
      this.itemPct.id_servico_produto = itemPacoteItem.id_servico_produto;
      this.itemPct.qtde = itemPacoteItem.qtde;
      this.itemPct.valor_unitario = itemPacoteItem.valor_unitario;
      this.itemPct.sub_total = itemPacoteItem.sub_total;
      this.itemVmodelServico = itemPacoteItem.descricao;

      this.incluindoItem = true;
    },
    cancelaInclusaoMatricula() {
      this.incluindoItem = false;
    },
    excluiPacoteItem(ItemPacoteItem) {
      api
        .delete(
          `pacotes-item/exclui-pacote-item/${ItemPacoteItem.id_pacote_item}`
        )
        .then((resp) => {
          this.carregaItemPacote();
        });
    },
    carregaItemPacote() {
      api
        .get(`pacotes-item/lista-pacotes-itens/1/999/${this.item.id_pacote}`)
        .then((resp) => {
          this.lstItensPacotes = resp.data.Data;
        });
    },
    CarregaComboServicosProdutos(args) {
      this.load = true;

      api
        .get(`servicos/lista-servicos-combo/t/0`)
        .then((resp) => {
          this.servicosCombo = resp.data;
          this.itemVmodelServico = this.servicosCombo.filter(
            (c) => c.idServicoProduto === this.dataEditor.ID_Servico
          )[0].descricao;
          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
    },
    recebeValorAutoServico(args) {
      this.itemPct.id_servico_produto = args.itemData.idServicoProduto;
      this.itemPct.valor_unitario = args.itemData.valor;
    },
    alteraData(args) {
      
      this.item.data_cadastro = args.value;
    },
    verificaCaixaAberto() {
      let UserLogado = JSON.parse(localStorage.getItem("usuario_salao"));

      api
        .get(`caixa/verifica-caixa-aberto/${UserLogado.id_usuario}`)
        .then((resp) => {
          this.item.id_caixa = resp.data.ID_Caixa;
        });
    },
  },
  props: ["item", "pagina", "termo", "perPage", "load"],
  mounted() {
    this.verificaCaixaAberto();
    this.carregaItemPacote();
    this.CarregaComboServicosProdutos();
  },
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 10;
}

.modal-container {
  max-width: 800px;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
