<template>
  <div class="md-layout-item">
    <loading :active.sync="load" :can-cancel="true"></loading>
    <div class="md-layout">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <md-card-header-text>
            <div class="md-title">Produtos e Serviços</div>
            <div class="md-subhead">Cadastro de Produtos e Serviços</div>
          </md-card-header-text>

          <md-card-actions>
            <md-button class="md-warning" @click="abreModal"
              >Adicionar</md-button
            >
          </md-card-actions>
        </md-card-header>

        <md-card-content>
          <md-table
            :value="this.tableData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="table-striped"
          >
            <!-- class="paginated-table table-striped table-hover" -->
            <md-table-toolbar>
              <div style="height: 80px">
                

                <div class="md-layout">
                    <div class="md-layout-item">
                        <md-field>
                            <md-input
                                type="search"
                                class="mb-5"
                                clearable
                                md-clear="getDados(1)"
                                style="width: 500px"
                                placeholder="Pesquisar Produto/Serviço..."
                                v-model="searchQuery"
                                v-on:keyup.enter="getDados(1)"
                            ></md-input>
                        </md-field>
                    </div>
    
                    <div class="md-layout-item md-inline-checkboxes">
                        <md-field>
                            <label for="tppessoa">Tipo</label>
                            <md-select v-model="checkProdServ" name="tipo" id="tipo"  @input="getDados(1)">
                            <md-option value="P">Produto</md-option>
                            <md-option value="S">Servico</md-option>
                            </md-select>
                        </md-field>
                   
                    </div>
                </div>

              </div>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              
              <md-table-cell style="width:  5%" md-label="Cód.">
                {{ item.id_servico_produto }}
              </md-table-cell>
              <md-table-cell md-label="Nome" md-sort-by="nome">
                {{ item.descricao }}
              </md-table-cell>
              <md-table-cell md-label="Categoria">
                {{ item.categoria}}
              </md-table-cell>
              <md-table-cell v-if="item.tipo=='P'" md-label="Marca">
                {{ item.marca }}
              </md-table-cell>
              <md-table-cell v-if="item.tipo=='P'" md-label="Consumo">
                {{ item.consumo_interno ? 'SIM' : 'NÃO' }}
              </md-table-cell>
              <md-table-cell v-if="item.tipo=='P'" md-label="Embalagem">
                {{ item.embalagem }}
              </md-table-cell>
              <md-table-cell v-if="item.tipo=='S'" md-label="Tempo">
                {{ item.tempo }}
              </md-table-cell>  
              <md-table-cell v-if="item.tipo=='S'" md-label="Retorno">
                {{item.retorno}} Dias
              </md-table-cell> 
              <md-table-cell md-label="Valor">
                {{ item.valor | currency }}
              </md-table-cell> 
              <md-table-cell class="text-right" md-label="">
                <div class="md-table-cell-container">
                  <md-button
                    class="md-button md-just-icon md-theme-default md-simple md-round"
                  >
                    <md-icon></md-icon>
                  </md-button>

                  <md-button
                    class="md-button md-just-icon md-theme-default md-info md-round"
                    @click.native="editar(item.id_servico_produto)"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>

                  <md-button
                    class="md-button md-just-icon md-theme-default md-danger md-round"
                    @click.native="handleDelete(item)"
                  >
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.id_servico_produto"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions item="itemSel" md-alignment="space-between">
          <div class>
            <p class="card-category">
              Mostrando {{ from + 1 }} a {{ to }} de {{ total }} entradas
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
    </div>
    <modalFormProdutosServicos
      :idProdServico="itemSel"
      :pagina="pagination.currentPage"
      :perPage="pagination.perPage"
      :termo="searchQuery"
      :load="load"
      @closeModal="closeModal"
      @load="vrload"
      v-if="ativaModal"
    ></modalFormProdutosServicos>
  </div>
</template>

<script>
import { Pagination } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import modalFormProdutosServicos from "./modalFormProdutosServicos.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import api from "@/routes/api"

export default {
  components: {
    Pagination,
    modalFormProdutosServicos,
    Loading
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    currentPage() {
      let result = this.pagination.currentPage;
      return result;
    },

    to() {
      let highBound = this.from + this.pagination.perPage;

      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    }
  },
  data() {
    return {
      checkProdServ: "P",
      currentSort: "",
      currentSortOrder: "asc",
      ativaModal: false,
      load: false,
      fullPage: true,
      pagination: {
        perPage: 7,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      footerTable: [],
      searchQuery: "",
      propsToSearch: [],
      tableData: [],

      fuseSearch: null,
      produtoServico: {
        id_servico_produto: 0,
        descricao: "",
        marca: "",
        valor: "0",
        tempo: 0,
        retorno: "",
        grupo: "",
        subgrupo: "",
        cod_barras: "",
        embalagem: "",
        qtd_emb: 0,
        unidade: "",
        cmv: 0,
        cmv_porcentagem: 0,
        icms: 0,
        icms_porcentagem: 0,
        custo_fixo: 0,
        custo_fixo_porcentagem: 0,
        comissao: 0,
        comissao_porcentagem: 0,
        lucro: 0,
        lucro_porcentagem: 0,
        tipo: "",
        id_grupo: "0",
        consumo_interno: false,
        estoque_minimo: 0,
        info_complementar: ""
      },
      itemSel: 0
    };
  },
  methods: {
    getDados(nro_pagina) {
      this.load = true;
      api
        .get(
          `servicos-produtos/lista-servicos-produtos/${this.checkProdServ}/${nro_pagina}/${this.pagination.perPage}?descricao=${this.searchQuery}`
        )
        .then(resp => {
          this.tableData = resp.data.Data;
          this.searchedData = resp.data.Data;
          this.pagination.total = Number(resp.data.QtdeRegistros);
          this.load = false;
        })
        .catch(error => {
          this.load = false;
        });
    },
    vrload(value) {
      this.load = value;
    },
    abreModal() {
   
      this.ativaModal = true;
    },
    customSort(value) {},
    editar(value) {
      this.itemSel = value;
      this.ativaModal = true;
    },
    
    handleDelete(item) {
      Swal.fire({
        title: "Confirma a Exclusão do registro?",
        text: `Não é possível reverter esta operação!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success btn-fill",
        cancelButtonClass: "md-button md-danger btn-fill",
        confirmButtonText: "Sim!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.load = true;
          
          api
            .delete(`servicos-produtos/exclui-servico-produto/${item.id_servico_produto}`)
            .then(resp => {
              this.load = false;
              this.getDados(this.pagination.currentPage);

              this.$notify({
                timeout: 2500,
                message: "Serviço Produto excluído com sucesso",
                icon: "add_alert",
                horizontalAlign: "right",
                verticalAlign: "top",
                type: "success",
              });
            })
            .catch(error => {
              this.load = false;
              Swal.fire({
                title: "Falha ao Excluir Registro!",
                text: `${error}`,
                type: "error",
                confirmButtonClass: "md-button md-success btn-fill",
                buttonsStyling: false
              });
            });
        }
      });
    },
    deleteRow(item) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === item.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    closeModal(pagina = 0, atualiza = 0) {
      let itemNew = this.funcionario;
      this.itemSel = itemNew;
      this.ativaModal = false;

      if (atualiza == 1) {
        if (pagina > 0) {
          this.currentPage = pagina;
        }

        this.getDados(this.currentPage);
      }
    }
  },
  mounted() {
    this.getDados(1);
  },
  watch: {
    currentPage() {
      this.getDados(this.pagination.currentPage);
    },
    searchQuery() {
      if (this.searchQuery == "") {
        this.getDados(1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.text-right .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
.md-table .md-table-head:last-child {
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total {
    display: inline-flex;
    font-weight: 500;
    font-size: 1.0625rem;
    margin-right: 50px;
  }

  &.table-striped .td-price {
    border-bottom: 0;
  }

  .td-price {
    font-size: 26px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div {
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.table-shopping .md-table-head:nth-child(5),
.table-shopping .md-table-head:nth-child(7),
.table-shopping .md-table-head:nth-child(6) {
  text-align: right;
}
</style>
