<template>
  <transition name="modal">
    <!-- <loading :active.sync="load" :can-cancel="true"></loading> -->
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header
                  class="md-tabs-navigation md-card-header-warning"
                >
                  <h4 class="title" style="color:white">
                    Recebimentos da Parcela: {{item.ID_Parcela}}               
                  </h4>
                </md-card-header>
                <md-card-content>
                  
                  <div class="md-layout">
                    
                    <div v-show="incluindoRecebimento == false && item.SaldoDevedor > 0" class="md-layout-item md-size-10">
                        <md-button
                            class="md-button md-just-icon md-theme-default md-info md-sm"
                            @click.native="novoRecebimento"
                        >
                            <md-icon>add</md-icon>
                        </md-button>
                    </div>

                    <div v-show="incluindoRecebimento == true"  class="md-layout-item md-size-20">
                        <ejs-dropdownlist id='dropdownlist' ref='dpFormasPgto' :fields="fieldsFormasPgto" floatLabelType='Auto' v-model="recebimento.ID_FormaPagamento" :dataSource='formasPagamento' placeholder='Forma de Pagamento'></ejs-dropdownlist>   
                    </div>

                    <div v-show="incluindoRecebimento == true" class="md-layout-item md-size-15">
                        <ejs-numerictextbox style="text-align:center" v-model="recebimento.Valor" cssClass='text-center' placeholder='Valor Recebido' :showSpinButton='false' floatLabelType="Auto"></ejs-numerictextbox>   
                    </div>

                    <div v-show="incluindoRecebimento == true" class="md-layout-item md-size-15">
                        <ejs-numerictextbox id="NroCheque" :validateDecimalOnType='true' format='n0' style="text-align:center" 
                                  v-model="recebimento.NroCheque" cssClass='text-center' :decimals="0" placeholder='Nº Cheque.'
                                  :showSpinButton='false' floatLabelType="Auto"></ejs-numerictextbox>
                    </div>

                    <div v-show="incluindoRecebimento == true" class="md-layout-item md-size-20 ">                                        
                        <md-switch label="teste" v-model="recebimento.Compensado">Compensado</md-switch>                         
                    </div>
                    
                    <div v-show="incluindoRecebimento == true"  class="md-layout-item md-size-10 text-right">                        
                        <md-button
                            class="md-button md-just-icon md-theme-default md-success"
                            @click.native="salvarRecebimento">
                            <md-icon>done</md-icon>
                        </md-button>
                    </div>
                   <br><br><br>
                    
                        <div class="md-layout-item md-size-100">
                            <md-table v-model="lstRecebimentos">
                                <md-table-row
                                slot="md-table-row"
                                slot-scope="{ item }"                                        
                                >
                                <md-table-cell md-label="Data Recebimento">{{ moment(item.Data_Recebimento).format("DD/MM/YYYY") }}</md-table-cell>
                                <md-table-cell md-label="Forma Pgto.">{{ item.FormaPgto }}</md-table-cell>
                                <md-table-cell md-label="Valor Recebido">{{ item.Valor | currency }}</md-table-cell>
                                <md-table-cell md-label="Nº Cheque">{{ item.NroCheque }}</md-table-cell>
                                <md-table-cell ><strong><span v-if="item.ID_FormaPagamento == 2 || item.ID_FormaPagamento == 3" :class="{'badge badge-success': item.Compensado, 'badge badge-danger': !item.Compensado}">{{ item.Compensado ? 'Compensado' : 'Não Compensado' }}</span></strong> </md-table-cell>
                                <md-table-cell>
                                    <md-button @click="editarRecebimento(item)" class="md-just-icon md-simple md-primary">
                                    <md-icon>edit</md-icon>
                                    <md-tooltip md-direction="top">Editar</md-tooltip>
                                    </md-button>  

                                    <md-button @click="excluirRecebimento(item.ID_Recebimento)" class="md-just-icon md-simple md-danger">
                                    <md-icon>close</md-icon>
                                    <md-tooltip md-direction="top">Excluir</md-tooltip>
                                    </md-button>   

                                </md-table-cell>

                                </md-table-row>
                            </md-table>
                        </div>


                  </div>
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button
                    class="md-success md-sm md-round"
                    @click="salvaRegistro"
                    >Salvar</md-button
                  >&nbsp;
                  <md-button
                    class="md-danger md-sm md-round"
                    @click="closeModal(0, 0)"
                    >Cancelar</md-button
                  >
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Swal from "sweetalert2";
import Vue from "vue";
import api from "@/routes/api";
import { DatePickerPlugin  } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin, TextBoxPlugin } from '@syncfusion/ej2-vue-inputs';

Vue.use(DatePickerPlugin, DropDownListPlugin, TextBoxPlugin);

export default {
  name: "modal",
  data() {
    return {
      incluindoRecebimento: false,
      dataPre: "",
      idFormaPgto: 0,
      // ValorRecebido: 0,
      idCaixaAberto: 0,
      numeroCheque: "",
      Compensado: false,
      lstRecebimentos: [],
      recebimento: {
        ID_Recebimento: 0,
        ID_Parcela: 0,
        ID_FormaPagamento: 0,
        ID_Caixa: 0,
        Valor: 0,
        Data_Recebimento: new Date(),
        Data_Pre: "",
        Compensado: false,
        NroCheque: "",
        NroAuthCartao: ""
      },
      formasPagamento: [
        {
          "ID_FormaPagamento": 1,
          "Descricao": "Dinheiro"
        },
        {
          "ID_FormaPagamento": 2,
          "Descricao": "Cheque"
        },
        {
          "ID_FormaPagamento": 3,
          "Descricao": "Cartão de Crédito"
        },
        {
          "ID_FormaPagamento": 4,
          "Descricao": "Cartão de Débito"
        },
        {
          "ID_FormaPagamento": 5,
          "Descricao": "Pix"
        },
        {
          "ID_FormaPagamento": 6,
          "Descricao": "Transferência"
        }
      ],
      fieldsFormasPgto: { text: 'Descricao', value: 'ID_FormaPagamento' },
      formaPgto: "",
      valor: 0.0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },
  methods: {
    closeModal: function(pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    salvaRegistro() {
        this.$notify({
          timeout: 2500,
          message: "A descrição deve conter ao menos 5 caracteres!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });

        this.$emit("load", false);
        this.closeModal(0);
      
    },
    carregaRecebimentos(idParcela) {
      this.load = true;
      api
        .get(
          `contas-a-receber/recebimentos-parcela/${idParcela}`
        )
        .then(resp => {
          this.lstRecebimentos = resp.data.Data;
          this.searchedData = resp.data.Data;
          this.pagination.total = Number(resp.data.QtdeRegistros);
          this.load = false;
        })
        .catch(error => {
          this.load = false;
        });
    },
    ChangeDataPre(data) {
        thi.dataPre = data.value
    },
    verificaCaixaAberto() {
      let UserLogado = JSON.parse(localStorage.getItem("usuario_salao"))

      api.get(`caixa/verifica-caixa-aberto/${UserLogado.id_usuario}`)
      .then((resp) => {
        this.recebimento.ID_Caixa = resp.data.ID_Caixa;
      });
    },
    editarRecebimento(data) {
        this.incluindoRecebimento = true
    
        this.recebimento.ID_FormaPagamento = data.ID_FormaPagamento
        this.recebimento.ID_Parcela = data.ID_Parcela
        this.recebimento.ID_Recebimento = data.ID_Recebimento
        this.recebimento.ID_Caixa = data.ID_Caixa
        this.recebimento.Valor = data.Valor
        this.recebimento.Data_Recebimento = data.Data_Recebimento
        this.recebimento.NroCheque = data.NroCheque
        this.recebimento.Compensado = data.Compensado
        this.recebimento.NroAuthCartao = data.NroAuthCartao
    },
    // novoRecebimento() {
    //     this.incluindoRecebimento = true
    //     this.dataRecebimento = null
    //     this.idFormaPgto = null
    //     this.ValorRecebido = ""
    //     this.numeroCheque = ""
    //     this.Compensado = false
    // },
    salvarRecebimento () {
      
      this.recebimento.ID_Parcela = this.item.ID_Parcela

      if(this.recebimento.ID_Caixa == 0){
        this.$notify({
              timeout: 2500,
              message: "Não foi possivel lançar o recebimento pois não existe caixa aberto!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger"
          });
        return;
      }

      if(this.recebimento.NroCheque == null){
        this.recebimento.NroCheque = ""
      }
      
      if(this.recebimento.NroAuthCartao == null){
        this.recebimento.NroAuthCartao = ""
      }

      if(this.recebimento.Valor > this.item.SaldoDevedor) {
          this.$notify({
              timeout: 2500,
              message: "Valor Recebido Maior que o Saldo Devedor!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger"
          });
          return;
      }

      if(this.recebimento.ID_FormaPagamento == 2 && (this.recebimento.NroCheque == "" ||  this.recebimento.NroCheque == null)) {
          this.$notify({
              timeout: 2500,
              message: "Informe o Nº do Cheque!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger"
          });
          return;
      }

      this.load = true;
      if(this.recebimento.ID_Recebimento == 0){
        api.put('contas-a-receber/receber-parcela/', this.recebimento, {
          headers: {
            "Content-Type": "application/json",
          },
        }).then(
        resp => {
          this.load = false;
          this.closeModal(1,1);
        })
        .catch(error => {
          this.load = false;
        });
      } else {
        api.post('contas-a-receber/alterar-recebimento-parcela/', this.recebimento, {
          headers: {
            "Content-Type": "application/json",
          },
        }).then(
        resp => {
          this.load = false;
          this.closeModal(1,1);
        })
        .catch(error => {
          this.load = false;
        });
      }
    },
    excluirRecebimento(idRecebimento) {
      Swal.fire({
        title: "Confirma a Exclusão do registro?",
        text: `Não é possível reverter esta operação!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success btn-fill",
        cancelButtonClass: "md-button md-danger btn-fill",
        confirmButtonText: "Sim!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.load = true;
          api.delete(`contas-a-receber/excluir-conta-receber-recebimento/${idRecebimento}`).then(
          resp => {
            this.carregaRecebimentos(this.item.ID_Parcela)
            this.load = false;
            this.closeModal(1,1);
          })
          .catch(error => {
            this.load = false;
          });
        }
      });
    }
  },
  mounted() {
      this.carregaRecebimentos(this.item.ID_Parcela);
      this.verificaCaixaAberto()
  },
  props: ["item", "pagina", "termo", "perPage", "load"]
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 10;
}
.modal-container {
  max-width: 950px;
}
.md-menu-content {
  z-index: 99999 !important;
}
.my-input-upper input {
  text-transform: uppercase;
}
.my-input-lower input {
  text-transform: lowercase;
}



// .md-tabs-navigation {
//     -webkit-box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
//     box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
//     margin: 0px 0 0;
//     border-radius: 3px;
//     padding: 0px 0px 0px 0px;
//     background-color: #999999;
// }
#tab-home > div > div.md-card.md-card-nav-tabs.md-theme-default > div {
  padding: 0 0 0 0;
}

#tab-home > div > div.md-card.md-card-nav-tabs.md-theme-default > div > div > div.md-content.md-tabs-content.md-theme-default {
  min-height: 135px;  
}

body > div > div.main-panel.ps > div.content > div > div.modal-mask > div > div > div > div > div > div.md-content.md-tabs-content.md-theme-default {
  min-height: 650px;
}

</style>
