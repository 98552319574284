<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" alt="avatar" />
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span v-if="$route.meta.rtlActive">
          {{ rtlTitle }}
          <b class="caret"></b>
        </span>
        <span v-else>
          {{ title }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <a href="#vue">
                  <span class="sidebar-mini">MP</span>
                  <span class="sidebar-normal">Meu Perfil</span>
                </a>
              </li>
              <li>
                <a href="#vue">
                  <span class="sidebar-mini">EP</span>
                  <span class="sidebar-normal">Editar Perfil</span>
                </a>
              </li>
              <li>
                <a href="#vue">
                  <span class="sidebar-mini">C</span>
                  <span class="sidebar-normal">Configurações</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import UsuarioLogado from '@/routes/usuario-logado';
export default {
  components: {
    CollapseTransition
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    rtlTitle: {
      type: String,
      default: "تانيا أندرو"
    },
    avatar: {
      type: String,
      default: "./img/new_logo.png"
    }
  },
  data() {
    return {
      isClosed: true
    };
  },
  methods: {
    clicked: function(e) {
      e.preventDefault();
    },
    toggleMenu: function() {
      this.isClosed = !this.isClosed;
    },
    titleCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
      }
      
      // return splitStr.join(' '); 
      return splitStr[0] + ' ' + splitStr[splitStr.length - 1]; 
  }
  },
  mounted() {
    var usuario = UsuarioLogado.obterUsuarioLogado();
    this.title = this.titleCase(usuario.nome)
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
