<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header
                  class="md-tabs-navigation md-card-header-warning"
                >
                  <h4 class="title" style="color:white">
                    Cadastro de Clientes
                    <strong>{{
                      item.id_cliente > 0 ? " - Código: " + item.id_cliente : ""
                    }}</strong>
                  </h4>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Nome</label>
                        <md-input
                          v-model="item.nome"
                          @input="item.nome = item.nome.toUpperCase()"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-15">
                      <md-field>
                        <label for="tppessoa">Sexo</label>
                        <md-select v-model="item.sexo" name="sexo" id="sexo">
                          <md-option value="M">Masculino</md-option>
                          <md-option value="F">Feminino</md-option>
                        </md-select>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-55">
                      <md-field>
                        <label>E-Mail</label>
                        <md-input
                          v-model="item.email"
                          @input="item.email = item.email.toLowerCase()"
                        ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-30">
                      <md-field>
                        <label>CPF</label>
                        <md-input
                          v-model="item.cpf"
                          v-mask="['###.###.###-##']"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-25">
                      <md-field>
                        <label>Celular</label>
                        <md-input
                          v-model="item.celular"
                          v-mask="['(##)####-####', '(##)#####-####']"
                        ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25">
                      <md-field>
                        <label>Telefone</label>
                        <md-input
                          v-model="item.telefone"
                          v-mask="['(##)####-####', '(##)#####-####']"
                        ></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-25">
                      
                  
                    <ejs-datepicker
                      :placeholder="'Data de Nascimento'"
                      v-model=dtNasc
                      :format="dd/mm/yyyy"
                      floatLabelType="Auto"
                      @change="ChangeDataNasc()"
                    ></ejs-datepicker>
                  
                      <!-- <md-field>
                        <label>Data de Nascimento</label>
                        <md-input
                          :placeholder="'Data de Nascimento'"
                          type="date"
                          v-model="dtNasc"
                          @change="ChangeDataNasc()"
                        ></md-input>
                      </md-field> -->
                    </div>
                    <div class="md-layout-item md-size-25">
                      <md-field>
                        <label for="estadocivil">Estado Civil</label>
                        <md-select
                          v-model="item.estado_civil"
                          name="estadocivil"
                          id="estadocivil"
                        >
                          <md-option value="0">Casado(a)</md-option>
                          <md-option value="1">Solteiro(a)</md-option>
                        </md-select>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Profissão</label>
                        <md-input
                          v-model="item.profisscao"
                          @input="
                            item.profisscao = item.profisscao.toUpperCase()
                          "
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <br />
                      <span slot="header" class="badge badge-warning"
                        >Endereço do Cliente</span
                      >
                      <md-field class="md-size-10"></md-field>
                    </div>

                    <div class="md-layout-item md-size-50">
                      <md-field class="md-size-10">
                        <label>Logradouro</label>
                        <md-input
                          @input="
                            item.logradouro = item.logradouro.toUpperCase()
                          "
                          maxlength="100"
                          md-counter="false"
                          v-model="item.logradouro"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-10">
                      <md-field class="md-size-15">
                        <label>Número</label>
                        <md-input
                          type="text"
                          pattern="\d*"
                          maxlength="5"
                          md-counter="false"
                          v-model="item.numero"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-40">
                      <md-field class="md-size-10">
                        <label>Complemento</label>
                        <md-input
                          @input="
                            item.complemento = item.complemento.toUpperCase()
                          "
                          maxlength="100"
                          md-counter="false"
                          v-model="item.complemento"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-40">
                      <md-field class="md-size-10 my-input-upper">
                        <label>Bairro</label>
                        <md-input
                          maxlength="100"
                          md-counter="false"
                          v-model="item.bairro"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-10">
                      <md-field class="md-size-10 my-input-upper">
                        <label>UF</label>
                        <md-input
                          @input="item.uf = item.uf.toUpperCase()"
                          maxlength="2"
                          md-counter="false"
                          v-model="item.uf"
                          @change="
                            item.id_cidade = 0;
                            carregaComboMunicipios(item.uf);
                          "
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-50">
                      <md-field class="md-size-10">
                        <label>Cidade</label>
                        <md-select
                          v-model="item.id_cidade"
                          name="idcidade"
                          id="idcidade"
                        >
                          <md-option
                            v-for="(itemMuni, indexmun) in lstMunicipios"
                            v-bind:key="indexmun"
                            :value="itemMuni.id_cidade"
                            >{{ itemMuni.cidade }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    
                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label for="categoriasCliente">Categorias Cliente</label>
                        <md-select v-model="categoriasClienteSelecionados" name="categoriasCliente" id="categoria" multiple>
                          <md-option v-for="categoria in categoriasCliente" :key="categoria.id" :value="categoria.id">
                            {{ categoria.nome_categoria }}
                          </md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button
                    class="md-success md-sm md-round"
                    @click="salvaRegistro"
                    >Salvar</md-button
                  >&nbsp;
                  <md-button
                    class="md-danger md-sm md-round"
                    @click="closeModal(0, 0)"
                    >Cancelar</md-button
                  >
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Swal from "sweetalert2";
// import { Money } from "v-money";
import api from "@/routes/api"
import VueNumeric from "vue-numeric";

export default {
  name: "modal",
  data() {
    return {
      valor: 0.0,
      dtNasc: "",
      lstMunicipios: [],
      categoriasCliente: [],
      categoriasClienteSelecionados: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      itemCliente: {
        data_hora_cadastro: "",
        nome: "",
        email: "",
        cpf: "",
        foto: "",
        sexo: "",
        nascimento: "",
        estado_civil: 1,
        profisscao: "",
        cep: "",
        logradouro: "",
        numero: 0,
        complemento: "",
        bairro: "",
        celular: "",
        telefone: "",
        excluido: false,
        obs: "",
        id_cidade: 0,
        id_cliente: 0,
        uf: "",
        categoria_cliente: ""
      }
    };
  },
  mounted() {
    this.dtNasc = this.item.nascimento;
    this.carregaComboMunicipios(this.item.uf);
    this.itemCliente = this.item;
    this.carregaCategoriasCliente();
    this.formataCategoriasCliente();
    // this.categoriasClienteSelecionados.filter(item => item !== 0);

  },
  computed: {
    dtNascFormatado() {
      const date = new Date(this.dtNasc);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    }
  },
  
  methods: {
    closeModal: function(pagina = 0, atualiza = 0, idClienteRet = 0) {
      this.$emit("closeModal", pagina, atualiza, idClienteRet);
    },
    formataCategoriasCliente(){
      if(this.item.categoria_cliente.length == 0){
        this.categoriasClienteSelecionados = [];
      } else {
        this.categoriasClienteSelecionados = this.item.categoria_cliente.split(",").map(Number);
      }
    },
    atualizaCategorias(){
      let vItens = this.categoriasClienteSelecionados.join(',');
      this.item.categoria_cliente = vItens;
    },
    carregaCategoriasCliente(){
      api
          .get(`categorias-cliente/lista-categorias-cliente`)
          .then(resp => {            
            const data = resp.data.Data;
            this.categoriasCliente = data.map(vrCategoriasCliente => ({
              id: vrCategoriasCliente.id,
              nome_categoria: vrCategoriasCliente.nome_categoria
            }));
          })
          .catch(error => {
            this.$emit("load", false);
            alert(error);
          });      
    },
    ChangeDataNasc() {
      this.item.nascimento = this.dtNasc;
    },
    carregaComboMunicipios(vrUf) {
      let urlCbxMunicipios =
        "cidades/lista-cidades/1/9999?uf=" + vrUf;

      if (vrUf.length < 2) {
        return;
      } 
      this.carregando = true;
      api.get(urlCbxMunicipios).then(resp => {
        this.lstMunicipios = resp.data.Data;
        this.carregando = false;
      });
      
    },
    salvaRegistro() {
      if (this.item.nome.length <= 5) {
        this.$notify({
          timeout: 2500,
          message: "Nome do cliente inválido!!!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        return;
      }

      this.atualizaCategorias();
      //console.log(this.item.categoria_cliente)

      var itemNew = JSON.parse(JSON.stringify(this.item));
      
      //LIMPA MASCARAS
      itemNew.cpf = this.$options.filters["UnMask"](itemNew.cpf);

      if (itemNew.sexo === "") {
        itemNew.sexo = "";
      }

      //SE FOR INCLUSÃO
      if (this.item.id_cliente == 0) {
        this.$emit("load", true);
        let urlApi = "clientes/incluir-cliente";
        api
          .put(urlApi, itemNew, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {            
            this.$emit("load", false);
            this.closeModal(1, 1, resp.data.id_cliente);
          })
          .catch(error => {
            this.$emit("load", false);
            alert(error);
          });
      } else {
        this.$emit("load", true);
        let urlApi = "clientes/alterar-cliente";
        api
          .post(urlApi, itemNew, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(resp => {
            this.$emit("load", false);
            this.closeModal(0);
          })
          .catch(error => {
            //alert(error);
            this.$emit("load", false);
          });
      }
    }
  },
  props: ["item", "pagina", "termo", "perPage", "load", "agendando"]
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 10;
}
.modal-container {
  max-width: 900px;
}
.md-menu-content {
  z-index: 99999 !important;
}
.my-input-upper input {
  text-transform: uppercase;
}
.my-input-lower input {
  text-transform: lowercase;
}
</style>
