<template>
  <transition name="modal">
    <!-- <loading
      :active.sync="load"
      :can-cancel="true"
      :is-full-page="fullpage"
    ></loading>-->
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header
                  class="md-tabs-navigation md-card-header-warning"
                >
                  <h4 class="title" style="color: white">
                    Lançamento de Vale
                    <strong>{{
                      item.id_vale > 0 ? " - Código: " + item.id_vale : ""
                    }}</strong>
                  </h4>
                </md-card-header>
                <div class="md-layout" style="padding: 20px 15px">
                  <div class="md-layout-item md-size-25">
                    <ejs-autocomplete
                      v-model="itemVmodelFuncionario"
                      floatLabelType="Auto"
                      :select="recebeValorAutoFuncionario"
                      :autofill="autofill"
                      :dataSource="listaFuncionarios"
                      :fields="{ text: 'nome', value: 'nome' }"
                      placeholder="Selecione o Profissional"
                    ></ejs-autocomplete>
                  </div>
                  <div class="md-layout-item md-size-15">
                    <ejs-datepicker
                      :disabled="true"
                      v-bind:value=" item.data != null && item.data != '' ? moment(item.data).format('DD/MM/YYYY') : null "
                      :placeholder="'Data'"
                      floatLabelType="Auto"
                      :format="maskData"
                      :enableMask="true"
                      :change="alteraData"
                    ></ejs-datepicker>
                  </div>

                  <div class="md-layout-item md-size-15">
                    <md-field class="md-size-10 md-has-value">
                      <label>Valor</label>
                      <vue-numeric
                        class="md-input"
                        currency="R$"
                        separator="."
                        v-bind:precision="2"
                        v-model="item.valor"
                      ></vue-numeric>
                    </md-field>
                  </div>

                  <div style="margin: 0 0 0 0">
                    Quitado
                    <div>
                      <md-switch
                        style="margin: 0 0 0 0"
                        v-model="item.quitado"
                      ></md-switch>
                    </div>
                  </div>

                  <div class="md-layout-item md-size-100">
                    <md-field>
                      <label>Observação</label>
                      <md-textarea v-model="item.observacao"></md-textarea>
                    </md-field>
                  </div>
                </div>
                <md-card-content>
                  <div class="md-layout"></div>
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button
                    class="md-success md-sm md-round"
                    @click="salvaRegistro"
                    >Salvar</md-button
                  >&nbsp;
                  <md-button
                    class="md-danger md-sm md-round"
                    @click="closeModal(0, 0)"
                    >Cancelar</md-button
                  >
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from "@/routes/api";
import VueNumeric from "vue-numeric";

export default {
  components: { VueNumeric },
  data() {
    return {
      valor: 0.0,
      statusCode: 0,
      itemVmodelFuncionario: null,
      itemFuncionarioCombo: null,
      listaFuncionarios: [],
      autofill: true,
      fields: { value: "name" },
      maskData: "dd/MM/yyyy hh:mm:ss",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      dataClassificacao: [
        { id: "Retirada", classificacao: "Retirada" },
        { id: "Pagamento de conta", classificacao: "Pagamento de conta" },
      ],
      fieldsClassificacao: { text: "classificacao", value: "id" },
    };
  },
  methods: {
    closeModal: function (pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    salvaRegistro() {
      let UserLogado = JSON.parse(localStorage.getItem("usuario_salao"));

      if (this.item.data == null) {
        this.item.data = "";
      }

      this.item.id_usuario = UserLogado.id_usuario;

      //SE FOR INCLUSÃO
      if (this.item.id_vale == 0) {
        this.$emit("load", true);
        api
          .put(`vales/incluir-vale`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.item.id_funcionario = 0
            this.$emit("load", false);
            this.closeModal(1, 1);
          })
          .catch((error) => {
            
            this.$emit("load", false);

            return;
          });
      } else {
        this.$emit("load", true);
        api
          .post(`vales/alterar-vale`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);
            this.closeModal(1, 1);
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Saldo em caixa insuficiente!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            this.$emit("load", false);
            return;
          });
      }
    },
    alteraData(args) {
      
      this.item.data = args.value
    },
    CarregaProfissionaisCombo: function () {
      this.load = true;
      api
        .get(`profissionais/lista-profissionais-combo/0`)
        .then((resp) => {
          this.listaFuncionarios = resp.data;

          

          this.load = false;
        })
        .catch((error) => {
          this.load = false;
        });
    },
    recebeValorAutoFuncionario(args) {
      this.itemFuncionarioCombo = args;
      this.item.id_funcionario = args.itemData.id_funcionario;
    },
  },
  props: ["item", "pagina", "termo", "perPage", "load"],
  mounted() {
    // this.verificaCaixaAberto();
    this.itemVmodelFuncionario = this.item.nome
  },
  created: async function () {
    await this.CarregaProfissionaisCombo();
  },
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 10;
}

.modal-container {
  max-width: 800px;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
