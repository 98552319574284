<template>
  <transition name="modal">
    <!-- <loading
      :active.sync="load"
      :can-cancel="true"
      :is-full-page="fullpage"
    ></loading>-->
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class>
            <div class>
              <md-card>
                <md-card-header
                  class="md-tabs-navigation md-card-header-warning"
                >
                  <h4 class="title" style="color: white">
                    Lançamento de Sangrias
                    <strong>{{
                      item.id_sangria > 0 ? " - Código: " + item.id_sangria : ""
                    }}</strong>
                  </h4>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-10">
                      <md-field>
                        <label>Caixa</label>
                        <md-input
                          v-model="item.id_caixa"
                          @input="item.id_caixa = item.id_caixa.toUpperCase()"
                          :disabled="true"
                        ></md-input>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-20">
                      <ejs-datepicker
                        id="dtSangria"
                        v-model="item.data_sangria"
                        :disabled="true"
                        v-bind:value="
                          item.data_sangria != null && item.data_sangria != ''
                            ? moment(item.data_sangria).format('DD/MM/YYYY')
                            : null
                        "
                        :placeholder="'Data da Sangria'"
                        floatLabelType="Auto"
                        :format="maskData"
                        :enableMask="true"
                      ></ejs-datepicker>
                    </div>

                    <div class="md-layout-item md-size-20">
                      <ejs-dropdownlist
                        floatLabelType="Auto"
                        id="dropdownlist"
                        :dataSource="dataClassificacao"
                        v-model="item.classificacao"
                        :fields="fieldsClassificacao"
                        :select="selectTipo"
                        placeholder="Classificação"
                      ></ejs-dropdownlist>
                    </div>

                    <div class="md-layout-item md-size-15">
                      <md-field class="md-size-10 md-has-value">
                        <label>Valor</label>
                        <vue-numeric
                          class="md-input"
                          currency="R$"
                          separator="."
                          v-bind:precision="2"
                          v-model="item.valor"
                        ></vue-numeric>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Observação</label>
                        <md-textarea v-model="item.observacao"></md-textarea>
                      </md-field>
                    </div>
                  </div>
                </md-card-content>

                <div class="footer mx-auto">
                  <md-button
                    class="md-success md-sm md-round"
                    @click="salvaRegistro"
                    :disabled="item.id_sangria > 0"
                    >Salvar</md-button
                  >&nbsp;
                  <md-button
                    class="md-danger md-sm md-round"
                    @click="closeModal(0, 0)"
                    >Cancelar</md-button
                  >
                </div>
                <br />
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from "@/routes/api";
import VueNumeric from "vue-numeric";

export default {
  components: { VueNumeric },
  name: "modal",
  data() {
    return {
      valor: 0.0,
      statusCode: 0,
      maskData: "dd/MM/yyyy hh:mm:ss",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      dataClassificacao: [
        { id: "Retirada", classificacao: "Retirada" },
        { id: "Pagamento de conta", classificacao: "Pagamento de conta" },
      ],
      fieldsClassificacao: { text: "classificacao", value: "id" },
    };
  },
  methods: {
    closeModal: function (pagina = 0, atualiza = 0) {
      this.$emit("closeModal", pagina, atualiza);
    },
    salvaRegistro() {
      if (this.item.data_sangria == null) {
        this.item.data_sangria = "";
      }

      //SE FOR INCLUSÃO
      if (this.item.id_sangria == 0) {
        this.$emit("load", true);
        api
          .put(`sangrias/inclui-sangria`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);
            this.closeModal(1, 1);
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Saldo em caixa insuficiente!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });

            this.$emit("load", false);

            return;
          });
      } else {
        this.$emit("load", true);
        api
          .post(`sangrias/altera-sangria`, this.item)
          .then((resp) => {
            this.statusCode = resp.statusCode;
            this.$emit("load", false);
            this.closeModal(0);
          })
          .catch((error) => {
            this.$notify({
              timeout: 2500,
              message: "Saldo em caixa insuficiente!!!",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            this.$emit("load", false);
            return;
          });
      }
    },
    verificaCaixaAberto() {
      let UserLogado = JSON.parse(localStorage.getItem("usuario_salao"));

      api
        .get(`caixa/verifica-caixa-aberto/${UserLogado.id_usuario}`)
        .then((resp) => {
          this.item.id_caixa = resp.data.ID_Caixa;
        });
    },
  },
  props: ["item", "pagina", "termo", "perPage", "load"],
  mounted() {
    this.verificaCaixaAberto();
  },
};
</script>

<style lang="scss">
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 10;
}

.modal-container {
  max-width: 800px;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
